import React, { useRef } from 'react';

import ExpandGroup from '../../../../Components/ExpandGroup/ExpandGroup';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import LeftSideBar, { LeftSideBarHandleType } from '../../../../shared-front/LeftSideBar/LeftSideBar';
import { selectorCurrentGroups, setCurrentUnit } from '../../../../store/commandSlice';
import { DomainOrProgramGroupType } from '../../../../types/types';
import classes from './EmbeddingSection.module.css';
import GroupSection from './GroupSection/GroupSection';
import TeamProgramsFormComponent from './TeamProgramForm/TeamProgramsFormComponent';
import TeamUnitsFormComponent from './TeamUnitsForm/TeamUnitsFormComponent';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconEdit } from '@consta/uikit/IconEdit';
import { Text } from '@consta/uikit/Text';
import { selectorIsAdminTeam } from '../../../../store/appSlice';
import TeamDomainFormComponent from './TeamDomainForm/TeamDomainFormComponent';
import TeamDomainGroupsFormComponent from './TeamDomainGroupsForm/TeamDomainGroupsFormComponent';

type PropsType = {
  groups: DomainOrProgramGroupType[];
  type: 'programs' | 'domains' | 'units';
};

const EmbeddingSection: React.FC<PropsType> = ({ groups, type }) => {
  const dispatch = useAppDispatch();
  const groupsByDomain: any[] = useAppSelector(selectorCurrentGroups);
  // console.log(groupsByDomain);
  const refGroupsSidebar = useRef<LeftSideBarHandleType>(null);
  const refGroupsDomainSidebar = useRef<LeftSideBarHandleType>(null);
  const openGroupsSidebarClick = () => {
    refGroupsSidebar.current?.closeSidebar();
    refGroupsSidebar.current?.openSidebar();
    refGroupsDomainSidebar.current?.closeSidebar();
  };
  const openDomainGroupsSidebarClick = () => {
    refGroupsSidebar.current?.closeSidebar();
    refGroupsDomainSidebar.current?.closeSidebar();
    refGroupsDomainSidebar.current?.openSidebar();
  };

  const isTeamAdmin = useAppSelector(selectorIsAdminTeam);
  const openSidebar = (domainName: string, domainUuid: string) => {
    openGroupsSidebarClick();

    if (type === 'units' || type === 'programs') {
      dispatch(
        setCurrentUnit({
          domain_uuid: domainUuid,
          domain_name: domainName,
          users: [],
          confluence: '',
          isHeadValue: {
            name: 'Да',
          },
          unitName: '',
          unitUuid: '',
        })
      );
    } else {
      let groups = [];
      const tmp = groupsByDomain.find((el: any) => el.uuid_block === domainUuid).team_groups;

      if (tmp) {
        groups = tmp.map((el: any) => {
          return {
            id: el._uuid,
            label: el.name,
          };
        });
      }
      dispatch(
        setCurrentUnit({
          domain_uuid: domainUuid,
          domain_name: domainName,
          users: [],
          confluence: '',
          isHeadValue: {
            name: 'Да',
          },
          unitName: '',
          unitUuid: '',
          groups: groups,
        })
      );
    }
  };
  const openGroupSidebar = (domainName: string, domainUuid: string) => {
    openDomainGroupsSidebarClick();
    let groups = [];
    const tmp = groupsByDomain.find((el: any) => el.uuid_block === domainUuid).team_groups;

    if (tmp) {
      groups = tmp.map((el: any) => {
        return {
          id: el._uuid,
          label: el.name,
        };
      });
    }
    dispatch(
      setCurrentUnit({
        domain_uuid: domainUuid,
        domain_name: domainName,
        users: [],
        confluence: '',
        isHeadValue: {
          name: 'Да',
        },
        unitName: '',
        unitUuid: '',
        groups: groups,
      })
    );
  };
  return (
    <div className={classes.container}>
      {groups.map((group, ind) => {
        return (
          <ExpandGroup
            key={ind}
            groupTitle={group.domainOrProgramGroupTile}
            groupColor={group.domainOrProgramGroupColor}
          >
            <div className={classes.groupsList}>
              {group.domainOrProgramGroupSections?.map((groupSection, ind) => {
                return (
                  <div key={ind}>
                    {groupSection?.[0]?.domainOrProgramSectionTitle && (
                      <Text className={classes.sectionTitle}>{groupSection?.[0]?.domainOrProgramSectionTitle}</Text>
                    )}
                    <div className={classes.sectionWrapper}>
                      <GroupSection
                        key={ind}
                        domainGroupSection={groupSection}
                        domainName={group.domainOrProgramGroupTile}
                        domainUuid={group._uuid}
                        groupDomainUUid={group.domainUuid}
                        type={type}
                        openSideBar={openGroupsSidebarClick}
                        isLastSection={group.domainOrProgramGroupSections.length === ind + 1}
                      />
                    </div>
                  </div>
                );
              })}
              {isTeamAdmin && (
                <div className={classes.editBlock}>
                  <Button
                    size={'xs'}
                    label={'Добавить карточку'}
                    view={'secondary'}
                    iconRight={IconAdd}
                    onClick={() => openSidebar(group.domainOrProgramGroupTile, group._uuid || group.domainUuid)}
                  />
                  {type === 'domains' && (
                    <div>
                      Действия с группами:{' '}
                      <Button
                        size={'xs'}
                        view={'ghost'}
                        label={'Добавить'}
                        iconRight={IconAdd}
                        onClick={() =>
                          openGroupSidebar(group.domainOrProgramGroupTile, group._uuid || group.domainUuid)
                        }
                      />
                      &nbsp;
                      <Button
                        size={'xs'}
                        view={'ghost'}
                        label={'Изменить'}
                        iconRight={IconEdit}
                        onClick={() =>
                          openGroupSidebar(group.domainOrProgramGroupTile, group._uuid || group.domainUuid)
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </ExpandGroup>
        );
      })}
      <LeftSideBar ref={refGroupsSidebar}>
        {type === 'units' && <TeamUnitsFormComponent closeSideBar={() => refGroupsSidebar.current?.closeSidebar()} />}
        {type === 'programs' && (
          <TeamProgramsFormComponent closeSideBar={() => refGroupsSidebar.current?.closeSidebar()} />
        )}
        {type === 'domains' && (
          <TeamDomainFormComponent closeSideBar={() => refGroupsSidebar.current?.closeSidebar()} />
        )}
      </LeftSideBar>
      <LeftSideBar ref={refGroupsDomainSidebar}>
        <TeamDomainGroupsFormComponent closeSideBar={() => refGroupsDomainSidebar.current?.closeSidebar()} />
      </LeftSideBar>
    </div>
  );
};

export default EmbeddingSection;
