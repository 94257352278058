import React from 'react';
import { useSearchParams } from 'react-router-dom';

import './constaFix.css';

import { MethodologyDocumentServerType, MethodologyProcessServerType } from '../../../../../api/serverResponse';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorMethodologyProcesses } from '../../../../../store/methodologySlice';
import { formatDateToDDMMYYYY } from '../../../../../utils/formatDateTimeUtils';
import {
  getBadgeStatus,
  getDocumentAuthorNameAndPosition,
  getDocumentTags,
  getSelectedProcess,
} from '../../../../../utils/methodology';
import DocumentTagsList from '../../RightColum/DocumentsCardsList/MethodologyDocument/DocumentTagsList/DocumentTagsList';
import classes from './DocumentsCardsModal.module.css';
import { Badge } from '@consta/uikit/Badge';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';
import { IconConnection } from '@consta/uikit/IconConnection';
import { IconDocBlank } from '@consta/uikit/IconDocBlank';
import { IconThumbUp } from '@consta/uikit/IconThumbUp';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

const getParentProcess = (
  uuid_process_parent: string | null | undefined,
  processes: MethodologyProcessServerType[]
) => {
  return processes.find((process) => process._uuid === uuid_process_parent);
};

const getToolTipTile = (document: MethodologyDocumentServerType, processes: MethodologyProcessServerType[]) => {
  let levels: string[] = [];
  let uuid_process_parent = document.v_dct_processes?.uuid_process_parent;
  while (uuid_process_parent) {
    const parentProcess = getParentProcess(uuid_process_parent, processes);
    if (parentProcess?.name) {
      levels.push(`${parentProcess.code} ${parentProcess.name}`);
    }
    uuid_process_parent = parentProcess?.uuid_process_parent;
  }
  const items: { label: string }[] = [];
  let title = '';
  levels = levels.sort();
  levels.forEach((level) => {
    title += `/ ${level}\n`;
    items.push({ label: level });
  });
  title += `/ ${document.v_dct_processes?.code} ${document.v_dct_processes?.name}\n`;
  items.push({ label: `${document.v_dct_processes?.code} ${document.v_dct_processes?.name}` });
  return items;
};

type PropsType = {
  document: MethodologyDocumentServerType;
};

const DocumentsCardsModal: React.FC<PropsType> = ({ document }) => {
  const [searchParams] = useSearchParams();
  const processes = useAppSelector(selectorMethodologyProcesses);
  const documentTags = getDocumentTags(document);
  const items: { label: string }[] = getToolTipTile(document, processes);

  const onCopyLink = () => {
    const link = window.location.href;
    navigator.clipboard.writeText(link).then(() => {});
  };

  return (
    <div className={classes.container}>
      <Breadcrumbs size={'s'} items={items} className={classes.breadCrumbs} />

      <Text size="xl" className={classes.documentTitle} weight={'bold'}>
        {document.title}
      </Text>
      <div className={classes.row}>
        <Badge view="stroked" label={document.type ? document.type : ''} status={getBadgeStatus(document.type)} />
        <div className={classes.element}>
          <IconThumbUp size={'s'} view={'secondary'} className={classes.icoStatus} />
          <Text size="s" className={classes.titleStatus} view="secondary">
            {document.status}
          </Text>
          <Text size="s" className={classes.dateStatus} view="secondary">
            {formatDateToDDMMYYYY(document.status_date)}
          </Text>
        </div>
        <div className={classes.element}>
          <IconDocBlank size={'s'} view={'secondary'} className={classes.icoFormat} />
          <Text size="s" className={classes.titleStatus} view="secondary">
            {document.format}
          </Text>
        </div>
        <div className={classes.element}>
          <Text size="s" className={classes.titleStatus} view="secondary">
            {`Версия ${document.version}`}
          </Text>
        </div>

        <div className={classes.elementCopyLink}>
          <IconConnection size={'s'} view={'link'} className={classes.icoConnection} />
          <Text size="s" className={classes.titleCopyLink} onClick={onCopyLink} view="secondary">
            {`Копировать ссылку`}
          </Text>
        </div>
      </div>
      {documentTags.length > 0 && (
        <div className={classes.tagsListWrapper}>
          <DocumentTagsList documentTags={documentTags} />
        </div>
      )}
      <div className={classes.delimiter} />
      <div className={classes.businessDescription}>
        <Text size="s" className={classes.subSectionTitle} weight={'bold'}>
          {`Бизнес-процесс`}
        </Text>
        <Text size="s" className={classNames(classes.subSectionDescription, classes.marginBottom)}>
          {`- ${document.v_dct_processes?.code} ${document.v_dct_processes?.name}`}
        </Text>
        <Text size="s" className={classNames(classes.subSectionDescription, classes.paddingLeft)}>
          {document.v_dct_processes?.description}
        </Text>
      </div>
      <div className={classes.rowInfoOne}>
        <div className={classes.subSection}>
          <Text size="s" className={classes.subSectionTitle} weight={'bold'}>
            {`Автор документа`}
          </Text>
          <Text size="s" className={classes.subSectionDescription}>
            {getDocumentAuthorNameAndPosition(document)}
          </Text>
        </div>
        <div className={classes.subSection}>
          <Text size="s" className={classes.subSectionTitle} weight={'bold'}>
            {`Обозначение документа`}
          </Text>
          <Text size="s" className={classes.subSectionDescription}>
            {document.number}
          </Text>
        </div>
        <div className={classes.subSection}>
          <Text size="s" className={classes.subSectionTitle} weight={'bold'}>
            {`Организация-владелец`}
          </Text>
          <Text size="s" className={classes.subSectionDescription}>
            {document.owner_organization}
          </Text>
        </div>
      </div>
      <div className={classes.delimiter} />
    </div>
  );
};

export default DocumentsCardsModal;
