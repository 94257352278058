import { BlogItemsType, BlogItemType, ViewSelectorItemType } from '../types/types';
import instanceAxios from './instanceAxios';

export const adminBlogApi = {
  async getBlogItems(params?: string) {
    const response = await instanceAxios.get<BlogItemsType>(`/admin/blog${params ? params : ''}`);
    return response.data;
  },
  async getBlogCsv(params?: string) {
    const response = await instanceAxios.get(`/admin/blog/export/csv`);
    return response.data;
  },
  async getSubscriptionCsv(params?: string) {
    const response = await instanceAxios.get(`/admin/newsletter/export/csv`);
    return response.data;
  },
  async getBlogSubscriptions(params?: string) {
    const response = await instanceAxios.get<BlogItemsType>(`/admin/newsletter${params ? params : ''}`);
    return response.data;
  },
  async getBlogDigest(params?: string) {
    const response = await instanceAxios.get<any>(`/admin/digest${params ? params : ''}`);
    return response.data;
  },
  async getBlogSuggestion(params?: string) {
    const response = await instanceAxios.get<any>(`/admin/suggestions${params ? params : ''}`);
    return response.data;
  },
  async getAdminBlogItem(id: string) {
    const response = await instanceAxios.get<any>(`/admin/blog/${id}`);
    return response.data;
  },
  async publishBlogItem(id: string) {
    const response = await instanceAxios.get<BlogItemType>(`/admin/blog/${id}/publish`);
    return response.data;
  },
  async getBlogNewsTypes() {
    const response = await instanceAxios.get<ViewSelectorItemType[]>(`/admin/blog/types`);
    return response.data;
  },
  async getBlogNewsTags() {
    const response = await instanceAxios.get(`/admin/blog/tags`);
    return response.data.data;
  },
  async getDigestStatuses() {
    const response = await instanceAxios.get(`/admin/digest/statuses`);
    return response.data.data;
  },
  async getBlogStatuses() {
    const response = await instanceAxios.get(`/admin/blog/statuses`);
    return response.data.data;
  },
  async getBlogTypes() {
    const response = await instanceAxios.get(`/admin/blog/types`);
    return response.data;
  },
  async getSuggestionTypes() {
    const response = await instanceAxios.get(`/admin/suggestions/types`);
    return response.data;
  },
  async getSuggestionStatuses() {
    const response = await instanceAxios.get(`/admin/suggestions/statuses`);
    return response.data.data;
  },
  async postNews(newsBody: any) {
    const response = await instanceAxios.post('/admin/blog', newsBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async postDigest(digestBody: any) {
    console.log(digestBody);
    const response = await instanceAxios.post('/admin/digest', digestBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async updateDigest(digestBody: any) {
    console.log(digestBody);
    const response = await instanceAxios.post(`/admin/digest/${digestBody.uuid}`, digestBody.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async updateNews(newsBody: any, uuid: string) {
    const response = await instanceAxios.post(`/admin/blog/${uuid}`, newsBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async publishNews(uuid: string) {
    const response = await instanceAxios.get(`/admin/blog/${uuid}/publish`);
    return response.data;
  },
  async archiveNews(uuid: string) {
    const response = await instanceAxios.get(`/admin/blog/${uuid}/to-archive`);
    return response.data;
  },
  async archiveDigest(uuid: string) {
    const response = await instanceAxios.get(`/admin/digests/${uuid}/to-archive`);
    return response.data;
  },
  async archiveSuggestion(uuid: string) {
    const response = await instanceAxios.get(`/admin/suggestions/${uuid}/to-archive`);
    return response.data;
  },
  async updateSuggestion(params: any) {
    const response = await instanceAxios.put(`/admin/suggestions/${params.uuid}`, params.data);
    return response.data;
  },
  async deleteNews(uuid: string) {
    const response = await instanceAxios.delete(`/admin/blog/${uuid}`);
    return response.data;
  },
  async deleteSubscription(uuid: string) {
    const response = await instanceAxios.delete(`/admin/newsletter/${uuid}`);
    return response.data;
  },
  async deleteDigest(uuid: string) {
    const response = await instanceAxios.delete(`/admin/digest/${uuid}`);
    return response.data;
  },
  async deleteSuggestion(uuid: string) {
    const response = await instanceAxios.delete(`/admin/suggestions/${uuid}`);
    return response.data;
  },
  async addSubscription(uuid: string) {
    const response = await instanceAxios.post(`/admin/newsletter`, { _uuid: uuid });
    return response.data;
  },
  async getBlogSpeakerRoles() {
    const response = await instanceAxios.get(`/admin/blog/roles`);
    return response.data.data.map((el: any) => {
      return {
        label: el.name,
        id: el._uuid,
      };
    });
  },
};
