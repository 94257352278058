import React from 'react';

import { MenuItemType } from '../types/types';
import dataOfficeIco from './assets/ico_data_office.svg';
import datalabIco from './assets/ico_datalab.svg';
import dqIco from './assets/ico_dq.svg';
import metaIco from './assets/ico_meta.svg';
// import schoolIco from './assets/ico_school.svg';

export enum SharedExternalRoutesEnum {
  Laboratory_Main = 'https://datalab.gazprom-neft.local/main',
  Laboratory_KnowledgeBase = 'https://datalab.gazprom-neft.local/knowledge',
  Laboratory_Tools = 'https://datalab.gazprom-neft.local/tools',
  Laboratory_Shop = 'https://datamarket.gazprom-neft.local/',
  Laboratory_Education = 'https://dataoffice.gazprom-neft.local/learning',
  Laboratory_Portfolio = 'https://datalab.gazprom-neft.local/portfolio',
  Laboratory_Faq = 'https://datalab.gazprom-neft.local/faq',
  Quality_Registers_Requirements = 'https://dq-d.gazprom-neft.local/quality/registry/requirements',
  Quality_Registers_Rules = 'https://dq-d.gazprom-neft.local/quality/registry/rules?page=0&limit=10',
  Quality_Registers_Agreements = 'https://dq-d.gazprom-neft.local/quality/registry/agreements?page=0&limit=10',
  Quality_Registers_Responsibility = 'https://dq-d.gazprom-neft.local/quality/registry/responsibility-matrices?page=0&limit=10',
  Quality_Dictionary_Bus_Tasks = 'https://dq-d.gazprom-neft.local/quality/dictionary/bus-tasks',
  Quality_Dictionary_Private_Data_Elements = 'https://dq-d.gazprom-neft.local/quality/dictionary/private-data-elements',
  Quality_Dictionary_Additional_Analytics = 'https://dq-d.gazprom-neft.local/quality/dictionary/additional-analytics',
  Quality_Dictionary_Organizations = 'https://dq-d.gazprom-neft.local/quality/dictionary/organizations',
  Quality_Dictionary_Bus_Systems = 'https://dq-d.gazprom-neft.local/quality/dictionary/bus-systems',
  Quality_Dictionary_Solutions = 'https://dq-d.gazprom-neft.local/quality/dictionary/solutions',
  Quality_Dictionary_Projects = 'https://dq-d.gazprom-neft.local/quality/dictionary/projects',
  Quality_Dictionary_ZNI = 'https://dq-d.gazprom-neft.local/quality/dictionary/zni',
  Quality_Dictionary_Domains = 'https://dq-d.gazprom-neft.local/quality/dictionary/domains',
  Quality_Dictionary_Mail_Templates = 'https://dq-d.gazprom-neft.local/quality/dictionary/mail-templates?page=0&limit=10',
  DataOffice_Main = 'https://dataoffice.gazprom-neft.local/main',
  DataOffice_Methodology = 'https://dataoffice.gazprom-neft.local/methodology',
  DataOffice_Learning = 'https://dataoffice.gazprom-neft.local/learning',
  DataOffice_Platform = 'https://dataoffice.gazprom-neft.local//data-platform',
  DataOffice_Storage = 'https://dataoffice.gazprom-neft.local/data-platform/dwh',
  DataOffice_Quality = 'https://dataoffice.gazprom-neft.local/data-platform/dq',
  DataOffice_Standards = 'https://dataoffice.gazprom-neft.local/data-platform/nsi',
  DataOffice_Catalog = 'https://dataoffice.gazprom-neft.local/data-platform/catalog',
  DataOffice_Analytics = 'https://dataoffice.gazprom-neft.local/data-platform/bi',
  DataOffice_Laboratory = 'https://dataoffice.gazprom-neft.local/data-platform/laboratory',
  DataOffice_Command = 'https://dataoffice.gazprom-neft.local/command',
  DataOffice_Reports = 'https://dataoffice.gazprom-neft.local/reports',
  DataOffice_Search_Catalogs = 'https://dataoffice.gazprom-neft.local/search/catalogs',
  DataOffice_Search_BusinessMeta = 'http://spb99pkl-alxc01.gazprom-neft.local/search?query=&comeFrom=suggest',
  DataOffice_Search_TechMeta = 'https://dataoffice.gazprom-neft.local/search/tech-meta',
  DataOffice_Search = 'https://dataoffice.gazprom-neft.local/search',
  DataOffice_ProjectsAndServices = 'https://dataoffice.gazprom-neft.local/projects-and-services',
  DataOffice_Confluence_UPD_BRD = 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=16948120',
  DataOffice_Confluence_UPD_BLPS = 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=178098834',
  DataOffice_Confluence_UPD_DRP = 'https://confluence.gazprom-neft.local/display/ASCPublic/Analytics+Solution+Center+Public+Home',
  DqMain = 'https://dq.gazprom-neft.local',
}

export enum PortalsEnum {
  DataOffice = 'data-office',
  Laboratory = 'laboratory',
  Quality = 'quality',
  Meta = 'meta',
  School = 'school',
  Market = 'market',
}

export const sharedSideBarMenuItems: MenuItemType[] = [
  {
    label: 'Data Office',
    href: PortalsEnum.DataOffice,
    icon: <img src={dataOfficeIco} alt="data-office" />,
    children: [
      { label: 'Главная', extUrl: SharedExternalRoutesEnum.DataOffice_Main },
      {
        label: 'Поиск по данным',
        href: SharedExternalRoutesEnum.DataOffice_Search,
        children: [
          { label: 'Поиск по каталогам', href: SharedExternalRoutesEnum.DataOffice_Search_Catalogs, disabled: true },
          {
            label: 'Поиск по бизнес-метаданным',
            extUrl: SharedExternalRoutesEnum.DataOffice_Search_BusinessMeta,
          },
          {
            label: 'Поиск по техническим метаданным',
            href: SharedExternalRoutesEnum.DataOffice_Search_TechMeta,
            disabled: true,
          },
        ],
      },
      { label: 'Методология', extUrl: SharedExternalRoutesEnum.DataOffice_Methodology, disabled: true },
      { label: 'Обучение', extUrl: SharedExternalRoutesEnum.DataOffice_Learning, disabled: true },
      {
        label: 'Дата-платформа',
        href: `${PortalsEnum.DataOffice}/instruments`,
        children: [
          { label: 'Дата-платформа', extUrl: SharedExternalRoutesEnum.DataOffice_Platform },
          { label: 'Хранилище и доступ', extUrl: SharedExternalRoutesEnum.DataOffice_Storage },
          { label: 'Качество', extUrl: SharedExternalRoutesEnum.DataOffice_Quality },
          { label: 'НСИ', extUrl: SharedExternalRoutesEnum.DataOffice_Standards },
          { label: 'Каталог', extUrl: SharedExternalRoutesEnum.DataOffice_Catalog },
          { label: 'Бизнес-аналитика', extUrl: SharedExternalRoutesEnum.DataOffice_Analytics },
          { label: 'Лаборатория', extUrl: SharedExternalRoutesEnum.DataOffice_Laboratory },
        ],
      },
      {
        label: 'Проекты и сервисы',
        extUrl: SharedExternalRoutesEnum.DataOffice_ProjectsAndServices,
        children: [
          { label: 'Confluence УПД БРД', extUrl: SharedExternalRoutesEnum.DataOffice_Confluence_UPD_BRD },
          { label: 'Confluence УПД БЛПС', extUrl: SharedExternalRoutesEnum.DataOffice_Confluence_UPD_BLPS },
          { label: 'Confluence УПД ДРП', extUrl: SharedExternalRoutesEnum.DataOffice_Confluence_UPD_DRP },
        ],
      },
      { label: 'Отчеты', extUrl: SharedExternalRoutesEnum.DataOffice_Reports },
      { label: 'Команда', extUrl: SharedExternalRoutesEnum.DataOffice_Command },
    ],
  },
  {
    label: 'Лаборатория данных',
    href: PortalsEnum.Laboratory,
    icon: <img src={datalabIco} alt="lab" />,
    children: [
      { label: 'Главная', extUrl: SharedExternalRoutesEnum.Laboratory_Main },
      { label: 'Магазин данных', extUrl: SharedExternalRoutesEnum.Laboratory_Shop },
      { label: 'Инструменты', extUrl: SharedExternalRoutesEnum.Laboratory_Tools },
      /*{ label: 'Обучение', extUrl: SharedExternalRoutesEnum.Laboratory_Education, disabled: true },*/
      { label: 'База Знаний', extUrl: SharedExternalRoutesEnum.Laboratory_KnowledgeBase },
      { label: 'Портфолио', extUrl: SharedExternalRoutesEnum.Laboratory_Portfolio },
      { label: 'FAQ', extUrl: SharedExternalRoutesEnum.Laboratory_Faq },
    ],
  },
  {
    label: 'Качество данных',
    href: PortalsEnum.Quality,
    icon: <img src={dqIco} alt="dq" />,
    children: [
      { label: 'Мониторинг', extUrl: `${SharedExternalRoutesEnum.DqMain}/monitoring` },

      {
        label: 'Реестры',
        extUrl: `${SharedExternalRoutesEnum.DqMain}/registry`,
        children: [
          {
            label: 'Бизнес-требования',
            extUrl: `${SharedExternalRoutesEnum.DqMain}registry/requirements`,
          },
          { label: 'Бизнес-правила', extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/rules` },
          {
            label: 'Соглашения о качестве',
            extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/agreements`,
          },
          {
            label: 'Матрица ответственности',
            extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/responsibility-matrices`,
          },
          {
            label: 'Инциденты',
            extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/incidents`,
          },
          {
            label: 'Проблемы',
            extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/problems`,
          },
          {
            label: 'Мероприятия',
            extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/actions`,
          },
          {
            label: 'Настройка КСУИТ',
            extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/ksuit-incidents`,
          },
        ],
      },
      {
        label: 'Справочники',
        extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/actions`,
        children: [
          {
            label: 'Внутренние',
            extUrl: `${SharedExternalRoutesEnum.DqMain}/registry/actions`,
            children: [
              { label: 'Бизнес-задачи', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/bus-tasks` },
              { label: 'ПЭД', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/private-data-elements` },
              { label: 'Шаблоны рассылок', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/mail-templates` },
              {
                label: 'Типы ответственности',
                extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/responsible-types`,
              },
              {
                label: 'Группы ответственности',
                extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/responsibility-groups`,
              },
              { label: 'Причины проблем', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/problem-issues` },
              { label: 'Виды мероприятий', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/action-types` },
              { label: 'Группы КСУИТ', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/ksuit-groups` },
            ],
          },

          {
            label: 'Внешние',
            extUrl: SharedExternalRoutesEnum.Quality_Dictionary_Private_Data_Elements,
            children: [
              { label: 'Организации', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/organizations` },
              { label: 'Бизнес-системы', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/bus-systems` },
              { label: 'Бизнес-решения', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/solutions` },
              { label: 'Проекты', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/projects` },
              { label: 'ЗНИ', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/zni` },
              { label: 'Домены', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/domains` },
              { label: 'Предметные области', extUrl: `${SharedExternalRoutesEnum.DqMain}/dictionary/subject-areas` },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Технические метаданные',
    href: PortalsEnum.Meta,
    icon: <img src={metaIco} alt="meta" />,
    children: [
      { label: 'Поиск метаданных', disabled: true },
      { label: 'Настройка подключений', disabled: true },
    ],
  },
  // {
  //   label: 'Школа аналитики',
  //   href: PortalsEnum.School,
  //   icon: <img src={schoolIco} alt="school" />,
  //   children: [
  //     { label: 'Главная', disabled: true },
  //     { label: 'Программы', disabled: true },
  //     { label: 'Новости', disabled: true },
  //   ],
  // },
];
