import { serverApi } from '../api/serverApi';
import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ViewSelectorItemType } from '../types/types';

export const ADMIN_METHODOLOGY_PAGE_VIEW_SELECTOR_ITEMS: ViewSelectorItemType[] = [
  {
    name: 'Процессы',
    slug: 'process',
  },
  {
    name: 'Документы',
    slug: 'documents',
  },
];

type MethodologyAdminSliceType = {
  viewSelectorActiveItem: ViewSelectorItemType | null;
  activeSortingId: number;
  searchValue: string | undefined;
  inArchive: boolean;
  processes: any | undefined;
  isLoading: boolean;
  documentStatuses: any[];
  documentTypes: any[];
  organizations: any[];
  filter: string;
  staticData: any;
};

const initialState: MethodologyAdminSliceType = {
  viewSelectorActiveItem: ADMIN_METHODOLOGY_PAGE_VIEW_SELECTOR_ITEMS[0],
  searchValue: undefined,
  activeSortingId: 1,
  inArchive: false,
  processes: {},
  isLoading: false,
  documentStatuses: [],
  documentTypes: [],
  organizations: [],
  filter: '',
  staticData: null,
};

export const getAdminMethodologyProcessesThunk = createAsyncThunk<any, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminMethodologyProcessesThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getAdminMethodologyProcesses(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения процессов');
    }
  }
);

export const getAdminMethodologyDocumentsThunk = createAsyncThunk<any, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminMethodologyDocumentsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getAdminMethodologyDocuments(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения документов');
    }
  }
);

export const getAdminMethodologyDocumentsStatusesThunk = createAsyncThunk<
  any,
  string | undefined,
  { rejectValue: string }
>('adminBlogPageSlice/getAdminMethodologyDocumentsStatusesThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getAdminDocumentStatuses();
  } catch (e) {
    return rejectWithValue('Ошибка получения статусов документов');
  }
});

export const getAdminMethodologyDocumentsTypesThunk = createAsyncThunk<
  any,
  string | undefined,
  { rejectValue: string }
>('adminBlogPageSlice/getAdminMethodologyDocumentsTypesThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getAdminDocumentTypes();
  } catch (e) {
    return rejectWithValue('Ошибка получения статусов документов');
  }
});

export const getAdminMethodologyStaticData = createAsyncThunk<any, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminMethodologyStaticData',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getAdminMethodologyStaticData();
    } catch (e) {
      return rejectWithValue('Ошибка получения данных для кнопок');
    }
  }
);

export const getAdminMethodologyDocumentsOrganizationThunk = createAsyncThunk<
  any,
  string | undefined,
  { rejectValue: string }
>('adminBlogPageSlice/getAdminMethodologyDocumentsOrganizationThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getAdminDocumentOrganizations();
  } catch (e) {
    return rejectWithValue('Ошибка получения организаций');
  }
});

export const postProcessThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postProcessThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.postProcess(params);
    } catch (e) {
      return rejectWithValue('Ошибка добавления процесса');
    }
  }
);

export const deleteProcessThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteProcessThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteProcess(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления процесса');
    }
  }
);

export const archiveProcessThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/archiveProcessThunk',
  async (uuid: any, { rejectWithValue }) => {
    try {
      return await serverApi.archiveProcess(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка архивирования процесса');
    }
  }
);

export const updateProcessThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/updateProcessThunk',
  async (params: any, { rejectWithValue }) => {
    try {
      return await serverApi.updateProcess(params.data, params.uuid);
    } catch (e) {
      return rejectWithValue('Ошибка обновления процесса');
    }
  }
);

export const updateDocumentsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/updateDocumentsThunk',
  async (params: any, { rejectWithValue }) => {
    try {
      return await serverApi.updateDocument(params.data, params.uuid);
    } catch (e) {
      return rejectWithValue('Ошибка обновления  документа');
    }
  }
);

export const postDocumentThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postDocumentThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.postDocument(params);
    } catch (e) {
      return rejectWithValue('Ошибка добавления документа');
    }
  }
);

export const postStaticStepsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postDocumentThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.postStaticSteps(params);
    } catch (e) {
      return rejectWithValue('Ошибка обновления информации новому сотруднику');
    }
  }
);

export const postStaticConfluenceThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postStaticConfluenceThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.postStaticConfluence(params);
    } catch (e) {
      return rejectWithValue('Ошибка обновления ссылки на confluence');
    }
  }
);
export const postStaticBacklogThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postStaticBacklogThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.postStaticBacklog(params);
    } catch (e) {
      return rejectWithValue('Ошибка обновления файла бэклога');
    }
  }
);

export const deleteDocumentThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteDocumentThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteDocument(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления документа');
    }
  }
);

export const deleteMethodologyBacklog = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteMethodologyBacklog',
  async (any, { rejectWithValue }) => {
    try {
      return await serverApi.deleteMethodologyBacklog();
    } catch (e) {
      return rejectWithValue('Ошибка удаления документа');
    }
  }
);

export const archiveDocumentThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/archiveDocumentThunk',
  async (uuid: any, { rejectWithValue }) => {
    try {
      return await serverApi.archiveDocument(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка архивирования документа');
    }
  }
);

export const adminMethodologySlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setAdminMethodologyActiveItem: (state, action) => {
      state.viewSelectorActiveItem = action.payload;
    },
    setAdminMethodologySearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setAdminMethodologyInArchive: (state, action) => {
      state.inArchive = action.payload;
    },
    setAdminMethodologyActiveSortingId: (state, action) => {
      state.activeSortingId = action.payload;
    },
    setAdminMethodologyFilter: (state, action) => {
      state.activeSortingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminMethodologyProcessesThunk.pending, (state) => {
        state.processes = undefined;
        state.isLoading = true;
      })
      .addCase(getAdminMethodologyDocumentsThunk.pending, (state) => {
        state.processes = undefined;
        state.isLoading = true;
      })
      .addCase(getAdminMethodologyProcessesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.processes = action.payload;
      })
      .addCase(getAdminMethodologyDocumentsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.processes = action.payload;
      })
      .addCase(getAdminMethodologyDocumentsStatusesThunk.fulfilled, (state, action) => {
        state.documentStatuses = action.payload;
      })
      .addCase(getAdminMethodologyDocumentsTypesThunk.fulfilled, (state, action) => {
        state.documentTypes = action.payload;
      })
      .addCase(getAdminMethodologyStaticData.fulfilled, (state, action) => {
        state.staticData = action.payload;
      })
      .addCase(getAdminMethodologyDocumentsOrganizationThunk.fulfilled, (state, action) => {
        state.organizations = action.payload;
      });
  },
});

export const {
  setAdminMethodologyInArchive,
  setAdminMethodologySearchValue,
  setAdminMethodologyActiveItem,
  setAdminMethodologyActiveSortingId,
  setAdminMethodologyFilter,
} = adminMethodologySlice.actions;

export const selectorMethodologyViewSelectorActiveItem = (state: RootState) =>
  state.adminMethodology.viewSelectorActiveItem;
export const selectorMethodologyViewSelectorSearchValue = (state: RootState) => state.adminMethodology.searchValue;
export const selectorMethodologyViewSelectorInArchive = (state: RootState) => state.adminMethodology.inArchive;
export const selectorMethodologyViewSelectorActiveSortingId = (state: RootState) =>
  state.adminMethodology.activeSortingId;
export const selectorAdminMethodologyIsLoading = (state: RootState) => state.adminMethodology.isLoading;
export const selectorAdminMethodologyProcessItems = (state: RootState) => state.adminMethodology.processes;
export const selectorAdminMethodoFilter = (state: RootState) => state.adminMethodology.filter;
export const selectorAdminMethodologyStatuses = (state: RootState) => state.adminMethodology.documentStatuses;
export const selectorAdminMethodologyTypes = (state: RootState) => state.adminMethodology.documentTypes;
export const selectorAdminMethodologyOrganizations = (state: RootState) => state.adminMethodology.organizations;
export const selectorAdminMethodologyStaticData = (state: RootState) => state.adminMethodology.staticData;

export default adminMethodologySlice.reducer;
