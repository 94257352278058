import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';

import classes from './DocumentForm.module.css';

import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { TextField } from '@consta/uikit/TextField';
import UserSelect from '../../../Components/ContactsForm/UserSelect/UserSelect';
import { CustomEditor } from '../../../Components/CustomEditor/CustomEditor';
import { DragNDropField } from '@consta/uikit/DragNDropField';
import { IconPhoto } from '@consta/uikit/IconPhoto';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { Attachment } from '@consta/uikit/Attach';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import {
  getAdminMethodologyDocumentsOrganizationThunk,
  getAdminMethodologyDocumentsStatusesThunk,
  getAdminMethodologyDocumentsTypesThunk,
  getAdminMethodologyProcessesThunk,
  postDocumentThunk,
  postProcessThunk,
  selectorAdminMethodologyOrganizations,
  selectorAdminMethodologyProcessItems,
  selectorAdminMethodologyStatuses,
  selectorAdminMethodologyTypes,
  updateDocumentsThunk,
} from '../../../store/adminMethodologySlice';
import { pushSnackBarItemReports } from '../../../store/appSlice';
import { BlogItemTagType } from '../../../types/types';
import { getBlogNewsTagsThunk, selectorViewSelectorNewsTags } from '../../../store/adminBlogSlice';
import { useGetDocument } from '../../../hooks/useGetDocument';

interface IAdminBlogPageProps {}
interface UsersType {
  id: string;
  label: string;
}

const DocumentForm: React.FC<IAdminBlogPageProps> = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const [item, isLoading] = useGetDocument(id);
  const navigate = useNavigate();
  const [name, setName] = useState<string | null>('');
  const [code, setCode] = useState<string | null>('');
  const [version, setVersion] = useState<string | null>('');
  const [picture, setPictureValue] = useState<File | null>();
  const [process, setProcess] = useState<any | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const processList = useAppSelector(selectorAdminMethodologyProcessItems);
  const tags: BlogItemTagType[] = useAppSelector(selectorViewSelectorNewsTags);
  const [tagList, setTagList] = useState(tags);
  const statuses = useAppSelector(selectorAdminMethodologyStatuses);
  const types = useAppSelector(selectorAdminMethodologyTypes);
  const organizations = useAppSelector(selectorAdminMethodologyOrganizations);
  const [tagsValue, setTagsValue] = useState<BlogItemTagType[] | null>();
  const [status, setStatus] = useState<any | null>();
  const [type, setType] = useState<any | null>();
  const [organization, setOrganization] = useState<any | null>();
  const [author, setAuthor] = useState<any | null>();

  useEffect(() => {
    setTagList(tags);
  }, [tags]);

  useEffect(() => {
    dispatch(getAdminMethodologyProcessesThunk());
    dispatch(getAdminMethodologyDocumentsTypesThunk());
    dispatch(getAdminMethodologyDocumentsStatusesThunk());
    dispatch(getBlogNewsTagsThunk());
    dispatch(getAdminMethodologyDocumentsOrganizationThunk());
  }, [dispatch]);

  useEffect(() => {
    if (item) {
      setVersion(item.version);
      setName(item.title);
      setCode(item.number);
      setOrganization({ title: item.owner_organization, id: item.owner_organization });
      setProcess(item.process);
      setStatus({ title: item.status, id: item.id });
      setType({ title: item.type, id: item.type });
      setTagsValue(item.tags);
      if (item.owner) {
        setAuthor({
          label: item.owner.last_name + ' ' + item.owner.first_name + ' ' + item.owner.middle_name,
          id: item.owner._uuid,
        });
      }
    }
  }, [item]);

  const setSearch = useCallback((searchString: string) => {
    setSearchValue(searchString);
    dispatch(getAdminMethodologyProcessesThunk(`?filter[search]=${searchString}`));
  }, []);

  const addDocument = useCallback(() => {
    const tmp: any = {
      title: name,
      number: code,
      status: status.title,
      type: type.title,
      uuid_process: process?._uuid,
    };

    if (organization) {
      tmp.owner_organization = organization.title;
    }
    if (version) {
      tmp.version = version;
    }
    if (author) {
      tmp.uuid_person = author.id;
    }
    if (tagsValue) {
      tmp['tags'] = tagsValue.map((el) => el.uuid || el.name);
    }

    if (picture || item?.document) {
      tmp.file = picture || item?.document?._uuid;
    }

    if (!item) {
      dispatch(postDocumentThunk(tmp)).then(() => {
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `Документ добавлен`,
            status: 'success',
            autoClose: 5,
          })
        );
        navigate('/admin/methodology');
      });
    } else {
      tmp._method = 'PUT';
      dispatch(updateDocumentsThunk({ data: tmp, uuid: item?._uuid })).then(() => {
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `Документ обновлен`,
            status: 'success',
            autoClose: 5,
          })
        );
        navigate('/admin/methodology');
      });
    }
  }, [name, process, picture, item, status, type, organization, version, author, tagsValue]);
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.breadcrumbs}>
          <div className={classes.breadcrumbsLink} onClick={() => navigate('/admin/methodology')}>
            Администрирование методологии
          </div>
          <IconArrowRight size="s" view="ghost" className={classes.breadcrumbsArrow} />
          <div className={classes.breadcrumbsCurrent}>{id ? 'Редактирование' : 'Добавление'} документа</div>
        </div>

        {item && (
          <div className={classes.processTitle}>
            {item.number} {item.title}
          </div>
        )}
        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Обозначение документа</div>
            <div>{code}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <TextField
            onChange={(val) => setCode(val.value)}
            label="Обозначение документа"
            value={code}
            type="text"
            placeholder="Введите обозначение документа, например, СК-22.08.01"
            className={classes.fullWidth}
            required
          />
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Название</div>
            <div>{name}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <TextField
            onChange={(val) => setName(val.value)}
            label="Название"
            value={name}
            type="text"
            placeholder="Введите название"
            className={classes.fullWidth}
            required
          />
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Тип</div>
            <div>{item.type?.title}</div>
          </div>
        )}

        {!item?.is_readonly && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Тип"
            items={types || []}
            value={type ? type : undefined}
            onChange={(value: any) => setType(value)}
            getItemLabel={(el: any) => el.title}
            getItemKey={(el: any) => el.title}
            className={classes.fullWidth}
            placeholder={'Выберите  тип'}
            required
          />
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Статус</div>
            <div>{item.status?.title}</div>
          </div>
        )}

        {!item?.is_readonly && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Статус"
            items={statuses || []}
            value={status ? status : undefined}
            onChange={(value: any) => setStatus(value)}
            getItemLabel={(el: any) => el.title}
            getItemKey={(el: any) => el.title}
            className={classes.fullWidth}
            placeholder={'Выберите статус'}
            required
          />
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Процесс</div>
            <div>
              {process?.code} {process?.name}
            </div>
          </div>
        )}

        {!item?.is_readonly && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Процесс"
            items={processList?.data || []}
            value={process ? process : undefined}
            onChange={(value: any) => setProcess(value)}
            onSearchValueChange={setSearch}
            getItemLabel={(el: any) => el.code + ' ' + el.name}
            getItemKey={(el: any) => el.code + ' ' + el.name}
            searchValue={searchValue}
            className={classes.fullWidth}
            placeholder={'Выберите  процесс'}
            required
          />
        )}

        <Combobox
          style={{ zIndex: 1 }}
          label="Тэги"
          items={tagList}
          value={tagsValue}
          onChange={(value) => setTagsValue(value)}
          getItemLabel={(el) => el.name}
          getItemKey={(el) => el.name}
          multiple
          placeholder="Выберите тэги"
          className={classes.fullWidth}
          onCreate={(label) => {
            setTagList([{ name: label, uuid: '' }, ...tagList]);
            setTagsValue([{ name: label, uuid: '' }, ...(tagsValue as BlogItemTagType[])]);
          }}
        />
        {!item?.is_readonly && (
          <>
            <div className={classes.formItemTitle}>Файл</div>
            <DragNDropField onDropFiles={(files) => setPictureValue(files[0])} className={classes.fullWidth}>
              {({ openFileDialog }) => (
                <>
                  {/*<IconPhoto size={'l'} view={'ghost'} className={classes.photo} />*/}
                  <Text>
                    Перетащите файл или{' '}
                    <a className={classes.link} onClick={openFileDialog}>
                      загрузите
                    </a>
                  </Text>
                  <Text view="ghost" font="mono">
                    Поддерживаемые форматы: DOC, XLS, PDF
                  </Text>
                </>
              )}
            </DragNDropField>
            {picture && (
              <div className={classNames([classes.attachment, classes.fullWidth])}>
                <Attachment
                  key={picture.name}
                  fileName={picture.name}
                  fileExtension={picture.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                  fileDescription={picture.type}
                />
                <IconTrash view={'ghost'} onClick={() => setPictureValue(null)} />
              </div>
            )}
          </>
        )}
        {!picture && item?.document && (
          <div className={classNames([classes.attachment, classes.fullWidth])}>
            <Attachment
              key={item.document.name}
              fileName={item.document.name}
              fileExtension={item.document.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
              fileDescription={item.document.description}
            />
          </div>
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Организация владелец</div>
            <div>{item.owner_organization?.title}</div>
          </div>
        )}

        {!item?.is_readonly && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Организация владелец"
            items={organizations || []}
            value={organization ? organization : undefined}
            onChange={(value: any) => setOrganization(value)}
            getItemLabel={(el: any) => el.title}
            getItemKey={(el: any) => el.title}
            className={classes.fullWidth}
            placeholder={'Выберите организацию'}
          />
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Автор документа</div>
            <div>{author?.label}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <div className={classes.fullWidth}>
            <UserSelect
              initValue={author}
              onChangeInit={(value) => {
                setAuthor(value);
              }}
              label={'Автор документа'}
            />
          </div>
        )}
        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Версия документа</div>
            <div>{version}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <TextField
            onChange={(val) => setVersion(val.value)}
            label="Версия документа"
            value={version}
            type="text"
            placeholder="Введите версию"
            className={classes.fullWidth}
          />
        )}
        <div className={classNames([classes.newsAction, classes.fullWidth])}>
          <Button view={'ghost'} label={'Отмена'} size={'m'} onClick={() => navigate('/admin/methodology')} />
          <div>
            <Button
              view={'primary'}
              label={id ? 'Сохранить' : 'Опубликовать'}
              size={'m'}
              disabled={!(process && name && code && type && status)}
              onClick={addDocument}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentForm;
