import { MethodologyDocumentServerType, MethodologyProcessServerType } from '../api/serverResponse';
import { MethodologySearchEnum, PersonRolesType } from '../types/types';

export const getDocumentGroup = (documentType: string | null) => {
  if (documentType?.includes('тандарт')) {
    return 1;
  } else if (documentType?.includes('уководство')) {
    return 2;
  } else if (documentType?.includes('аблон')) {
    return 3;
  } else if (documentType?.includes('етодическ')) {
    return 4;
  }
  return undefined;
};

export const getBadgeStatus = (documentType: string | null) => {
  if (documentType?.includes('тандарт')) {
    return 'normal';
  } else if (documentType?.includes('уководство')) {
    return 'system';
  } else if (documentType?.includes('аблон')) {
    return 'warning';
  } else if (documentType?.includes('етодическ')) {
    return 'success';
  }
  return undefined;
};

export const getDocumentTags = (document: MethodologyDocumentServerType) => {
  if (document.v_dgp_tags && document.v_dgp_tags.length > 0) {
    return document.v_dgp_tags.map((v_dgp_tag) => {
      return v_dgp_tag.name;
    });
  }
  return [];
};

export const getSelectedProcess = (searchParams: URLSearchParams, processes: MethodologyProcessServerType[]) => {
  const selectedProcessUuid = searchParams.get(MethodologySearchEnum.processUuid);
  if (selectedProcessUuid) {
    const selectedProcess = processes.filter((process) => process._uuid === selectedProcessUuid);
    if (selectedProcess.length > 0) {
      return selectedProcess[0];
    }
  }
  return;
};

export const getDocumentAuthorNameAndPosition = (document: MethodologyDocumentServerType) => {
  let documentAuthor = '';
  if (document.v_dct_persons?.[0]?.last_name) {
    documentAuthor += document.v_dct_persons?.[0]?.last_name;
  }
  if (document.v_dct_persons?.[0]?.first_name) {
    documentAuthor += ' ';
    documentAuthor += document.v_dct_persons?.[0]?.first_name;
  }

  if (document.v_dct_persons?.[0]?.middle_name) {
    documentAuthor += ' ';
    documentAuthor += document.v_dct_persons?.[0]?.middle_name;
  }
  if (document.v_dct_persons?.[0]?.v_dct_positions?.[0]?.name) {
    documentAuthor += ' - ';
    documentAuthor += document.v_dct_persons?.[0]?.v_dct_positions?.[0]?.name;
  }
  return documentAuthor;
};

export const getParentProcessName = (
  parentProcessUuid: string | null | undefined,
  processes: MethodologyProcessServerType[]
) => {
  return processes.filter((process) => process._uuid === parentProcessUuid)?.[0]?.name;
};

export const getPersonRoles = (process: MethodologyProcessServerType | undefined) => {
  const personRoles: PersonRolesType[] = [];
  process?.v_dct_person_roles?.forEach((person_role) => {
    const role = person_role.v_dct_role?.name;
    let name = person_role.v_dct_person?.first_name ? person_role.v_dct_person?.first_name : '';
    name += person_role.v_dct_person?.last_name ? ` ${person_role.v_dct_person?.last_name}` : '';
    name += ' ' + person_role.v_dct_person?.middle_name ? ` ${person_role.v_dct_person?.middle_name}` : '';
    const email = person_role.v_dct_person?.email;
    const position = person_role.v_dct_person?.v_dct_positions?.[0].name;
    personRoles.push({ role, name, position, email });
  });
  return personRoles;
};
