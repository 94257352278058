import React, { useEffect, useRef } from 'react';

import classes from './DigestsBlock.module.css';
import { Text } from '@consta/uikit/Text';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { getDigestsThunk, selectorDigests } from '../../../../../store/blogSlice';

const DigestsBlock: React.FC = () => {
  const dispatch = useAppDispatch();
  const sliderRef = useRef<Slider>(null);
  const digests = useAppSelector(selectorDigests);
  const next = () => {
    sliderRef.current?.slickNext();
  };
  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    arrows: false,
  };
  useEffect(() => {
    dispatch(getDigestsThunk());
  }, [dispatch]);
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Дайджесты функции УпД`}</Text>
      {digests.length > 0 && (
        <Slider ref={sliderRef} {...settings}>
          {digests.map((el: any) => (
            <div className={classes.item} onClick={() => window.open(el.file, '_blank')}>
              <div className={classes.imgContainer}>
                <img src={el.cover} alt="digest" />
              </div>
              <Text className={classes.subTitle}>{el.name}</Text>
            </div>
          ))}
        </Slider>
      )}
      <div className={classes.bottom}>
        <div className={classes.btnSelector}>
          <button className={classes.button} onClick={previous}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                fill="#00395C"
                fillOpacity="0.8"
              />
            </svg>
          </button>
          &nbsp;&nbsp;
          <button className={classes.button} onClick={next}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                fill="#00395C"
                fillOpacity="0.8"
              />
            </svg>
          </button>
        </div>

        {/*<Button label={`Читать дальше`} view="clear" className={classes.btnReadMore} size={'s'} disabled />*/}
      </div>
    </div>
  );
};

export default DigestsBlock;
