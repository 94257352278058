import { BlogEventsServerResponseType, BlogEventType, BlogTagsDictionaryResponseType } from '../api/blogServerResponse';
import { serverApi } from '../api/serverApi';
import { MetaServerType, TagServerType } from '../api/serverResponse';
import { BlogContentType } from '../Pages/BlogPage/ViewControl/ViewControl';
import { getRejectedValue } from './rejectedValueHelper';
import { RootState } from './store';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

export const blogContentTypes: BlogContentType[] = [
  { name: 'Все' },
  { name: 'Новости' },
  { name: 'Анонсы' },
  // { name: 'Кейсы' },
];

type BlogSliceType = {
  activeBlogContentType: BlogContentType;
  blogEvents: BlogEventType[];
  blogEventsMeta: MetaServerType | undefined;
  blogSelectedTags: TagServerType[];
  blogTagsDictionary: TagServerType[];
  isOpenBlogModalSuggestion: boolean;
  isSuccessSuggestion: boolean;
  blogSearchString: string;
  isLoading: boolean;
  isError?: string;
  digests: [];
};

const initialState: BlogSliceType = {
  activeBlogContentType: blogContentTypes[0],
  blogEvents: [],
  blogEventsMeta: undefined,
  blogSelectedTags: [],
  blogSearchString: '',
  blogTagsDictionary: [],
  isOpenBlogModalSuggestion: false,
  isSuccessSuggestion: false,
  isLoading: false,
  isError: undefined,
  digests: [],
};

export const getAllBlogEventsThunk = createAsyncThunk<BlogEventsServerResponseType, string, { rejectValue: string }>(
  'blogSlice/getBlogEventsThunk',
  async (query, { rejectWithValue }) => {
    try {
      return await serverApi.getAllBlogEvents(query);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения событий для Блога', e));
    }
  }
);

export const getBlogTagsDictionary = createAsyncThunk<
  BlogTagsDictionaryResponseType,
  undefined,
  { rejectValue: string }
>('blogSlice/getBlogTagsDictionary', async (_, { rejectWithValue }) => {
  try {
    return await serverApi.getBlogTagsDictionary();
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения словаря тагов для Блога', e));
  }
});

export const getDigestsThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'blogSlice/getDigestsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getDigests();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения дайджеста', e));
    }
  }
);

export const getAllBlogAddEventsThunk = createAsyncThunk<BlogEventsServerResponseType, string, { rejectValue: string }>(
  'blogSlice/getBlogAddEventsThunk',
  async (query, { rejectWithValue }) => {
    try {
      return await serverApi.getAllBlogEvents(query);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения новых событий для Блога', e));
    }
  }
);

export const blogSlice = createSlice({
  name: 'blogSlice',
  initialState,
  reducers: {
    setActiveBlogContentType: (state, action) => {
      state.activeBlogContentType = action.payload;
    },
    addToSelectedTags: (state, action) => {
      state.blogSelectedTags.push(action.payload);
    },
    setBlogSearchString: (state, action) => {
      state.blogSearchString = action.payload;
    },
    clearAllSelectedTags: (state) => {
      state.blogSelectedTags = [];
    },
    deleteFromSelectedTags: (state, action) => {
      state.blogSelectedTags = state.blogSelectedTags.filter((tag) => tag._uuid !== action.payload._uuid);
    },
    setIsOpenBlogModalSuggestion: (state, action) => {
      state.isOpenBlogModalSuggestion = action.payload;
      if (action.payload === false) {
        state.isSuccessSuggestion = false;
      }
    },
    setIsSuccessSuggestion: (state, action) => {
      state.isSuccessSuggestion = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogEventsThunk.fulfilled, (state, action) => {
        state.blogEvents = action.payload.data;
        state.blogEventsMeta = action.payload.meta;
        state.isLoading = false;
      })
      .addCase(getAllBlogAddEventsThunk.fulfilled, (state, action) => {
        state.blogEvents = state.blogEvents.concat(...action.payload.data);
        state.blogEventsMeta = action.payload.meta;
      })
      .addCase(getDigestsThunk.fulfilled, (state, action) => {
        state.digests = action.payload.data;
      })
      .addCase(getBlogTagsDictionary.fulfilled, (state, action) => {
        state.blogTagsDictionary = action.payload.data;
      })
      .addMatcher(isAnyOf(getAllBlogEventsThunk.pending), (state) => {
        state.isLoading = true;
      })
      .addMatcher(
        isAnyOf(getAllBlogEventsThunk.rejected, getAllBlogAddEventsThunk.rejected, getBlogTagsDictionary.rejected),
        (state, action) => {
          state.isLoading = false;
          state.isError = action.payload ? action.payload : 'Неизвестная ошибка - blogSlice';
        }
      );
  },
});

export const {
  setActiveBlogContentType,
  addToSelectedTags,
  clearAllSelectedTags,
  deleteFromSelectedTags,
  setIsOpenBlogModalSuggestion,
  setIsSuccessSuggestion,
  setBlogSearchString,
} = blogSlice.actions;

export const selectorActiveBlogContentType = (state: RootState) => state.blog.activeBlogContentType;
export const selectorBlogEvents = (state: RootState) => state.blog.blogEvents;
export const selectorDigests = (state: RootState) => state.blog.digests;
export const selectorBlogEventsMeta = (state: RootState) => state.blog.blogEventsMeta;
export const selectorBlogIsLoading = (state: RootState) => state.blog.isLoading;
export const selectorBlogTagsDictionary = (state: RootState) => state.blog.blogTagsDictionary;
export const selectorBlogSelectedTags = (state: RootState) => state.blog.blogSelectedTags;
export const selectorBlogSearchString = (state: RootState) => state.blog.blogSearchString;
export const selectorIsOpenBlogModalSuggestion = (state: RootState) => state.blog.isOpenBlogModalSuggestion;
export const selectorIsSuccessSuggestion = (state: RootState) => state.blog.isSuccessSuggestion;

export default blogSlice.reducer;
