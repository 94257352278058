import React from 'react';

import { useAppSelector } from '../../../../hooks/hooks';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import classes from './AdminMethodologyPageContent.module.css';
import AdminMethodologyTable from './AdminMethodologyTable/AdminMethodologyTable';
import { Loader } from '@consta/uikit/Loader';
import {
  selectorAdminMethodologyIsLoading,
  selectorMethodologyViewSelectorActiveItem,
} from '../../../../store/adminMethodologySlice';
import AdminMethodologyDocumentsTable from './AdminMethodologyDocumentsTable/AdminMethodologyDocumentsTable';

interface IAdminBlogPageContentProps {}

const AdminMethodologyPageContent: React.FC<IAdminBlogPageContentProps> = () => {
  const isLoadingItems = useAppSelector(selectorAdminMethodologyIsLoading);
  const view = useAppSelector(selectorMethodologyViewSelectorActiveItem);

  return (
    <div className={classes.container}>
      <Delimiter />
      {view?.slug == 'process' && (
        <div className={classes.content}>{isLoadingItems ? <Loader /> : <AdminMethodologyTable />}</div>
      )}
      {view?.slug == 'documents' && (
        <div className={classes.content}>{isLoadingItems ? <Loader /> : <AdminMethodologyDocumentsTable />}</div>
      )}

      <Delimiter />
    </div>
  );
};

export default AdminMethodologyPageContent;
