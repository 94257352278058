import { ReactNode } from 'react';

import { createTableColumn } from '../../../../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../../../../types/types';

export type AdminBlogTableDataType = {
  statusBadge?: ReactNode | null;
  coverImg?: ReactNode | null;
  name?: string | null;
  type?: ReactNode | null;
  dataTime?: ReactNode | null;
  tags?: ReactNode | null;
  actions?: ReactNode | null;
};

export const ADMIN_BLOG_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'statusBadge',
    title: 'Статус',
    isShow: true,
    size: 145,
  }),
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'coverImg',
    title: 'Обложка',
    isShow: true,
    size: 160,
  }),
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'name',
    title: 'Название',
    textLinesNumber: 3,
    isShow: true,
    size: 270,
  }),
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'type',
    title: 'Тип',
    isShow: true,
    size: 148,
  }),
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'dataTime',
    title: 'Дата и время',
    isShow: true,
    size: 148,
  }),
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'tags',
    title: 'Теги',
    isShow: true,
    size: 240,
  }),
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'actions',
    title: 'Доступные действия',
    textLinesNumber: 2,
    isShow: true,
  }),
];
