import React from 'react';

import TableWrapper from '../../../../../Components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../../../hooks/hooks';
import { Table } from '../../../../../shared-front/Table/Table';
import { selectorViewSelectorBlogItems } from '../../../../../store/adminBlogSlice';
import { formatDateHHMMDDMMYYYY } from '../../../../../utils/formatDateHHMMDDMMYYYY';
import classes from './AdminBlogTable.module.css';
import AdminBlogTableActions from './AdminBlogTableActions/AdminBlogTableActions';
import AdminBlogTableBadge from './AdminBlogTableBadge/AdminBlogTableBadge';
import { ADMIN_BLOG_TABLE_COLUMNS, AdminBlogTableDataType } from './adminBlogTableColumns';
import AdminBlogTableCoverImg from './AdminBlogTableCoverImg/AdminBlogTableCoverImg';
import AdminBlogTableItemType from './AdminBlogTableItemType/AdminBlogTableItemType';
import AdminBlogTableTags from './AdminBlogTableTags/AdminBlogTableTags';

interface IAdminBlogTableProps {}

const AdminBlogTable: React.FC<IAdminBlogTableProps> = () => {
  const blogItems = useAppSelector(selectorViewSelectorBlogItems)?.data;

  const tableData: AdminBlogTableDataType[] =
    blogItems?.map((item) => {
      return {
        statusBadge: <AdminBlogTableBadge status={item.status} />,
        coverImg: <AdminBlogTableCoverImg imgUrl={item.cover?.path} />,
        name: item.name ?? '',
        type: <AdminBlogTableItemType itemType={item.type} />,
        dataTime: (
          <div className={classes.dataTime}>
            {item._alter_ts ? formatDateHHMMDDMMYYYY(new Date(item._alter_ts)) : ''}
          </div>
        ),
        tags: <AdminBlogTableTags tags={item.tags} />,
        actions: <AdminBlogTableActions item={item} />,
      };
    }) || [];

  return (
    <div className={classes.container}>
      <TableWrapper height={'100%'}>
        <Table data={tableData} columns={ADMIN_BLOG_TABLE_COLUMNS} isColumnsResizable={true} />
      </TableWrapper>
    </div>
  );
};

export default AdminBlogTable;
