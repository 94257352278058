import { ReactNode } from 'react';

import { createTableColumn } from '../../../../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../../../../types/types';

export type AdminBlogDigestDataType = {
  status?: ReactNode | null;
  cover?: ReactNode | null;
  name?: ReactNode | null;
  file?: ReactNode | null;
  date?: ReactNode | null;
  actions?: ReactNode | null;
};

export const ADMIN_BLOG_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<AdminBlogDigestDataType>({
    accessorKey: 'status',
    title: 'Статус',
    isShow: true,
    size: 145,
  }),
  createTableColumn<AdminBlogDigestDataType>({
    accessorKey: 'value',
    title: 'Предложение',
    isShow: true,
    size: 480,
  }),
  createTableColumn<AdminBlogDigestDataType>({
    accessorKey: 'type',
    title: 'Тип',
    textLinesNumber: 2,
    isShow: true,
    size: 145,
  }),
  createTableColumn<AdminBlogDigestDataType>({
    accessorKey: 'author',
    title: 'Автор',
    isShow: true,
    size: 200,
  }),
  createTableColumn<AdminBlogDigestDataType>({
    accessorKey: 'date',
    title: 'Дата и время',
    isShow: true,
    size: 145,
  }),
  createTableColumn<AdminBlogDigestDataType>({
    accessorKey: 'actions',
    title: 'Доступные действия',
    isShow: true,
  }),
];
