import React from 'react';

import { BlogPersonType } from '../../../../../../api/blogServerResponse';
import classes from './AnnounceSpeakers.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/Text';

interface IAnnounceSpeakersProps {
  speakers?: BlogPersonType[];
}

const AnnounceSpeakers: React.FC<IAnnounceSpeakersProps> = ({ speakers }) => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Спикеры`}</Text>
      <div className={classes.speakersContainer}>
        {speakers?.map((speaker, ind) => {
          return (
            <div key={ind} className={classes.row}>
              <Avatar
                className={classes.avatar}
                url={undefined}
                name={`${speaker.first_name} ${speaker.last_name}`}
                size={'l'}
              />
              <div className={classes.textContainer}>
                <Text className={classes.name}>{`${speaker.first_name} ${speaker.last_name}`}</Text>
                <Text className={classes.position}>{speaker.display_role}</Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnnounceSpeakers;
