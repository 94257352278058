import React from 'react';

import SearchContainer from '../../../../Components/SearchContainer/SearchContainer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import iconArrowFirst from '../../../../img/methodologyPage/iconArrowFirst.svg';
import {
  selectorIsOpenLeftColumn,
  selectorMethodologyProcesses,
  selectorMethodologyProcessesSearchValue,
  setIsOpenLeftColumn,
  setProcessesSearchValue,
} from '../../../../store/methodologySlice';
import classes from './LeftColum.module.css';
import OpenCloseContainer from './OpenCloseContainer/OpenCloseContainer';
import ProcessList from './OpenCloseContainer/ProcessList/ProcessList';
import { Text } from '@consta/uikit/Text';

const LeftColum: React.FC = () => {
  const dispatch = useAppDispatch();
  const processes = useAppSelector(selectorMethodologyProcesses);
  const isOpenLeftColumn = useAppSelector(selectorIsOpenLeftColumn);
  const processesSearchValue = useAppSelector(selectorMethodologyProcessesSearchValue);

  const openCloseClick = () => {
    if (isOpenLeftColumn) {
      dispatch(setIsOpenLeftColumn(false));
    } else {
      dispatch(setIsOpenLeftColumn(true));
    }
  };

  const onClickShowAll = () => {
    dispatch(setProcessesSearchValue(''));
  };

  const onSearchValue = (value: string) => {
    console.log(value);
    dispatch(setProcessesSearchValue(value || ''));
  };

  return (
    <div className={classes.container}>
      <OpenCloseContainer>
        <div className={classes.contentContainer}>
          <div className={classes.topRow}>
            <div className={classes.titleBlock}>
              <Text className={classes.title}> {'Процессы'}</Text>
              {processesSearchValue ? (
                <Text className={classes.titleShowAll} onClick={onClickShowAll}>
                  {' '}
                  {`Показать все (${processes.length})`}
                </Text>
              ) : (
                <Text className={classes.titleShowAllGray}> {`${processes.length}`}</Text>
              )}
            </div>

            <button className={classes.button}>
              <img src={iconArrowFirst} alt="arrowFirst" onClick={openCloseClick} />
            </button>
          </div>
          <div className={classes.searchContainer}>
            <SearchContainer
              value={processesSearchValue}
              placeHolder={'Введите название процесса'}
              onSearch={onSearchValue}
            />
          </div>
          <div style={{ paddingRight: '8px' }}>
            <div className={classes.delimiter} />
          </div>
          <ProcessList searchValue={processesSearchValue} />
        </div>
      </OpenCloseContainer>
    </div>
  );
};

export default LeftColum;
