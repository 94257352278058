import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';

import classes from './ProcessForm.module.css';

import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { TextField } from '@consta/uikit/TextField';
import UserSelect from '../../../Components/ContactsForm/UserSelect/UserSelect';
import { CustomEditor } from '../../../Components/CustomEditor/CustomEditor';
import { DragNDropField } from '@consta/uikit/DragNDropField';
import { IconPhoto } from '@consta/uikit/IconPhoto';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { Attachment } from '@consta/uikit/Attach';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import {
  getAdminMethodologyProcessesThunk,
  postProcessThunk,
  selectorAdminMethodologyProcessItems,
  updateProcessThunk,
} from '../../../store/adminMethodologySlice';
import { pushSnackBarItemReports } from '../../../store/appSlice';
import { useGetProcess } from '../../../hooks/useGetProcess';
import parse from 'html-react-parser';

interface IAdminBlogPageProps {}
interface UsersType {
  id: string;
  label: string;
}

const ProcessForm: React.FC<IAdminBlogPageProps> = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const [item, isLoading] = useGetProcess(id);
  const navigate = useNavigate();
  const [name, setName] = useState<string | null>('');
  const [code, setCode] = useState<string | null>('');
  const [descriptionValue, setDescriptionValue] = useState<string | null>('');
  const [stepsValue, setStepsValue] = useState<string | null>('');
  const [metricValue, setMetricValue] = useState<string | null>('');
  const [picture, setPictureValue] = useState<File | null>();
  const [process, setProcess] = useState<any | undefined>(undefined);
  const [owner, setOwner] = useState<any | undefined>();
  const [center, setCenter] = useState<any | undefined>();
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const processList = useAppSelector(selectorAdminMethodologyProcessItems);

  useEffect(() => {
    dispatch(getAdminMethodologyProcessesThunk());
  }, [dispatch]);

  useEffect(() => {
    if (item) {
      console.log(item);
      setName(item.name);
      setCode(item.code);
      setDescriptionValue(item.description);
      setStepsValue(item.major_stages);
      setMetricValue(item.metrics);
      setProcess(item.parent_process);
      if (item.process_owner) {
        setOwner({
          label:
            item.process_owner.last_name + ' ' + item.process_owner.first_name + ' ' + item.process_owner.middle_name,
          id: item.process_owner._uuid,
        });
      }

      if (item.competence_center) {
        setCenter({
          label:
            item.competence_center.last_name +
            ' ' +
            item.competence_center.first_name +
            ' ' +
            item.process_owner.middle_name,
          id: item.competence_center._uuid,
        });
      }
    }
  }, [item]);

  const setSearch = useCallback((searchString: string) => {
    setSearchValue(searchString);
    dispatch(getAdminMethodologyProcessesThunk(`?filter[search]=${searchString}`));
  }, []);

  const addProcess = useCallback(() => {
    const tmp: any = {
      name: name,
      code: code,
      uuid_process_parent: process?._uuid,
      process_owner: owner.id,
    };
    if (descriptionValue) {
      tmp.description = descriptionValue || '';
    }
    if (stepsValue) {
      tmp.major_stages = stepsValue || '';
    }
    if (metricValue) {
      tmp.metrics = metricValue || '';
    }
    if (center) {
      tmp.competence_center = center?.id || '';
    }
    if (picture) {
      tmp.picture = picture || item?.picture?.url;
    }
    if (!item) {
      dispatch(postProcessThunk(tmp)).then(() => {
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `Процесс добавлен`,
            status: 'success',
            autoClose: 5,
          })
        );
        navigate('/admin/methodology');
      });
    } else {
      tmp._method = 'PUT';
      dispatch(updateProcessThunk({ data: tmp, uuid: item?._uuid })).then(() => {
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `Процесс обновлен`,
            status: 'success',
            autoClose: 5,
          })
        );
        navigate('/admin/methodology');
      });
    }
  }, [name, code, process, descriptionValue, stepsValue, metricValue, owner, center, picture, item]);
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.breadcrumbs}>
          <div className={classes.breadcrumbsLink} onClick={() => navigate('/admin/methodology')}>
            Администрирование методологии
          </div>
          <IconArrowRight size="s" view="ghost" className={classes.breadcrumbsArrow} />
          <div className={classes.breadcrumbsCurrent}>{id ? 'Редактирование' : 'Добавление'} процесса</div>
        </div>

        {item && (
          <div className={classes.processTitle}>
            {item.code} {item.name}
          </div>
        )}
        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Вышестоящий процесс</div>
            <div>
              {process?.code} {process?.name}
            </div>
          </div>
        )}

        {!item?.is_readonly && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Вышестоящий процесс"
            items={processList?.data || []}
            value={process ? process : undefined}
            onChange={(value: any) => setProcess(value)}
            onSearchValueChange={setSearch}
            getItemLabel={(el: any) => el.code + ' ' + el.name}
            getItemKey={(el: any) => el.code + ' ' + el.name}
            searchValue={searchValue}
            className={classes.fullWidth}
            placeholder={'Выберите вышестоящий процесс'}
            required
          />
        )}
        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Название</div>
            <div>{name}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <TextField
            onChange={(val) => setName(val.value)}
            label="Название"
            value={name}
            type="text"
            placeholder="Введите название"
            className={classes.fullWidth}
            required
          />
        )}
        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Код</div>
            <div>{code}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <TextField
            onChange={(val) => setCode(val.value)}
            label="Код"
            value={code}
            type="text"
            placeholder="Введите код"
            className={classes.fullWidth}
            required
          />
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Владелец процесса</div>
            <div>{owner?.label}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <div className={classes.fullWidth}>
            <UserSelect
              initValue={owner}
              onChangeInit={(value) => {
                setOwner(value);
              }}
              label={'Владелец процесса'}
              required={true}
            />
          </div>
        )}
        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Центр компетенций по стандартизации</div>
            <div>{center?.label}</div>
          </div>
        )}

        {!item?.is_readonly && (
          <div className={classes.fullWidth}>
            <UserSelect
              initValue={center}
              onChangeInit={(value) => {
                setCenter(value);
              }}
              label={'Центр компетенций по стандартизации'}
            />
          </div>
        )}
        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Описание</div>
            <div>{descriptionValue ? parse(descriptionValue) : ''}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Описание</div>
            <CustomEditor
              placeholder={'Введите описание '}
              disabled={item?.is_readonly}
              value={descriptionValue}
              onChange={(...event: any[]) => setDescriptionValue(event[0])}
            />
          </div>
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Основные этапы</div>
            <div>{stepsValue ? parse(stepsValue) : ''}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Основные этапы</div>
            <CustomEditor
              disabled={item?.is_readonly}
              placeholder={'Заполните основные этапы '}
              value={stepsValue}
              onChange={(...event: any[]) => setStepsValue(event[0])}
            />
          </div>
        )}

        {item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Метрики</div>
            <div>{metricValue ? parse(metricValue) : ''}</div>
          </div>
        )}
        {!item?.is_readonly && (
          <div className={classes.fullWidth}>
            <div className={classes.formItemTitle}>Метрики</div>
            <CustomEditor
              disabled={item?.is_readonly}
              placeholder={'Заполните метрики '}
              value={metricValue}
              onChange={(...event: any[]) => setMetricValue(event[0])}
            />
          </div>
        )}
        <div className={classes.formItemTitle}>Инфографика</div>
        <DragNDropField onDropFiles={(files) => setPictureValue(files[0])} className={classes.fullWidth}>
          {({ openFileDialog }) => (
            <>
              <IconPhoto size={'l'} view={'ghost'} className={classes.photo} />
              <Text>
                Перетащите изображение или{' '}
                <a className={classes.link} onClick={openFileDialog}>
                  загрузите
                </a>
              </Text>
              <Text view="ghost" font="mono">
                Поддерживаемые форматы: PNG, TIFF, JPG
              </Text>
            </>
          )}
        </DragNDropField>
        {picture && (
          <div className={classNames([classes.attachment, classes.fullWidth])}>
            <Attachment
              key={picture.name}
              fileName={picture.name}
              fileExtension={picture.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
              fileDescription={picture.type}
            />
            <IconTrash view={'ghost'} onClick={() => setPictureValue(null)} />
          </div>
        )}
        {!picture && item?.picture && (
          <div className={classNames([classes.attachment, classes.fullWidth])}>
            <img src={item?.picture?.url} alt={item?.name} onClick={() => window.open(item?.picture?.url)} />
          </div>
        )}
        <div className={classNames([classes.newsAction, classes.fullWidth])}>
          <Button view={'ghost'} label={'Отмена'} size={'m'} onClick={() => navigate('/admin/blog')} />
          <div>
            <Button
              view={'primary'}
              label={id ? 'Сохранить' : 'Опубликовать'}
              size={'m'}
              disabled={!(process && name && owner && code && center)}
              onClick={addProcess}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessForm;
