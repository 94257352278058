import React, { useCallback, useEffect, useState } from 'react';

import classes from './DocumentsFilterFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { useGetProcesses } from '../../../../../../hooks/useGetProcesses';
import UserSelect from '../../../../../../Components/ContactsForm/UserSelect/UserSelect';
import { getBlogTagsDictionary, selectorBlogTagsDictionary } from '../../../../../../store/blogSlice';

import {
  getAdminMethodologyDocumentsStatusesThunk,
  getAdminMethodologyDocumentsTypesThunk,
  selectorAdminMethodologyStatuses,
  selectorAdminMethodologyTypes,
  setAdminMethodologyFilter,
} from '../../../../../../store/adminMethodologySlice';
import { useGetDocuments } from '../../../../../../hooks/useGetDocuments';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';

type Item = {
  label: string;
  id: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const DocumentsFilterFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const getDocuments = useGetDocuments();
  const tags = useAppSelector(selectorBlogTagsDictionary);
  const statuses = useAppSelector(selectorAdminMethodologyStatuses);
  const types = useAppSelector(selectorAdminMethodologyTypes);
  const [tagsValue, setTagsValue] = useState<any[] | null>();
  const [status, setStatus] = useState<any[] | null>();
  const [type, setType] = useState<any | null>();

  const setFilter = useCallback(() => {
    const tmp: string[] = [];
    if (type && type.length) {
      type.map((el: any) => {
        tmp.push(`filter[type][]=${el.id}`);
      });
    }
    if (status && status.length) {
      status.map((el: any) => {
        tmp.push(`filter[status][]=${el.id}`);
      });
    }

    if (tagsValue && tagsValue.length) {
      tagsValue.map((el: any) => {
        // console.log(el);
        tmp.push(`filter[tags][]=${el._uuid}`);
      });
    }

    dispatch(setAdminMethodologyFilter(tmp.join('&')));
    getDocuments({ filterValue: tmp.join('&') });
  }, [tagsValue, status, type]);

  useEffect(() => {
    dispatch(getAdminMethodologyDocumentsStatusesThunk());
    dispatch(getAdminMethodologyDocumentsTypesThunk());
    dispatch(getBlogTagsDictionary());
  }, [dispatch]);
  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Фильтры</div>
        <Button
          view={'ghost'}
          label={'Сбросить фильтры'}
          size={'s'}
          onClick={() => {
            setStatus(undefined);
            setType(undefined);
            setTagsValue(undefined);
          }}
        />
      </div>

      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Статус согласования{' '}
          <span className={classes.clear} onClick={() => setStatus(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={status}
            items={statuses}
            getItemLabel={(item: any) => item.title}
            onChange={(value) => {
              setStatus(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Тип документа{' '}
          <span className={classes.clear} onClick={() => setType(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={type}
            items={types}
            getItemLabel={(item: any) => item.title}
            onChange={(value) => {
              setType(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Тэги{' '}
          <span className={classes.clear} onClick={() => setTagsValue(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={tagsValue}
            items={tags}
            getItemLabel={(item: any) => item.name}
            onChange={(value) => {
              setTagsValue(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>
      <div className={classes.bottomButtons}>
        <div></div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button size="m" view="primary" form={'default'} label={'Применить'} onClick={setFilter} />
        </div>
      </div>
    </div>
  );
};

export default DocumentsFilterFormComponent;
