import React, { useCallback, useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import LeftSideBar, { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import {
  getCommandTeamThunk,
  selectorCommandTeam,
  setCurrentConfluence,
  setCurrentLeader,
  setCurrentTeamEmployee,
} from '../../../store/commandSlice';
import { getGroupsTitles, getGroupsUsers } from '../../../utils/functions';
import SectionDelimiter from './SectionDelimiter/SectionDelimiter';
import SectionGroups from './SectionGroups/SectionGroups';
import classes from './TeamComponent.module.css';
import TeamConfluenceFormComponent from './TeamConfluenceForm/TeamConfluenceFormComponent';
import TeamEmplyeeFormComponent from './TeamEmployeeForm/TeamEmplyeeFormComponent';
import TeamGroupsFormComponent from './TeamGroupsForm/TeamGroupsFormComponent';
import TeamHeadersSection from './TeamHeadersSection/TeamHeadersSection';
import TeamLeaderFormComponent from './TeamLeaderForm/TeamLeaderFormComponent';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { selectorIsAdminTeam } from '../../../store/appSlice';
import { IconAdd } from '@consta/uikit/IconAdd';

const TeamComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const team = useAppSelector(selectorCommandTeam);
  const isAdminTeam = useAppSelector(selectorIsAdminTeam);
  const getTeam = useCallback(() => {
    dispatch(getCommandTeamThunk());
  }, [dispatch]);

  useEffect(() => {
    getTeam();
  }, [getTeam]);

  const refSidebar = useRef<LeftSideBarHandleType>(null);
  const refEmployeeSidebar = useRef<LeftSideBarHandleType>(null);
  const refConfluenceSidebar = useRef<LeftSideBarHandleType>(null);
  const refGroupsSidebar = useRef<LeftSideBarHandleType>(null);

  const openSidebarClick = () => {
    refSidebar.current?.closeSidebar();
    refSidebar.current?.openSidebar();
  };

  const openConfluenceSidebar = () => {
    refConfluenceSidebar.current?.closeSidebar();
    refConfluenceSidebar.current?.openSidebar();
  };
  const openEmployeeSidebarClick = () => {
    refEmployeeSidebar.current?.closeSidebar();
    refEmployeeSidebar.current?.openSidebar();
  };
  const openGroupsSidebarClick = () => {
    refGroupsSidebar.current?.closeSidebar();
    refGroupsSidebar.current?.openSidebar();
  };

  const verticalUsers = team.map((vertical: any) => vertical.verticalUsers);
  const verticalDomains = team.map((vertical: any) => {
    return {
      id: vertical._uuid,
      label: vertical.verticalTitle,
    };
  });
  const verticalHeads = team.map((vertical) => ({
    title: vertical.verticalTitle,
    _uuid: vertical._uuid,
    color: vertical.verticalColor,
    head: vertical.verticalHead,
  }));
  const colors = team.map((el) => el.verticalColor);
  const groupsTitles = getGroupsTitles(verticalUsers);

  const groupsUsers = getGroupsUsers(groupsTitles, verticalUsers);
  const groupsAmount = groupsUsers[0]?.length;

  return (
    <div className={classes.container}>
      <div className={classes.stickyHeaders}>
        <TeamHeadersSection verticalHeads={verticalHeads} openSidebarClick={openSidebarClick} />
        <SectionDelimiter groupsAmount={groupsAmount} />
      </div>
      <div className={classes.scrollPart}>
        <SectionGroups
          verticalUsers={verticalUsers}
          colors={colors}
          verticalDomains={verticalDomains}
          openSidebarClick={openEmployeeSidebarClick}
          openConfluenceSidebar={openConfluenceSidebar}
        />
      </div>
      <LeftSideBar ref={refSidebar}>
        <TeamLeaderFormComponent
          closeSideBar={() => {
            dispatch(setCurrentLeader({}));
            refSidebar.current?.closeSidebar();
          }}
        />
      </LeftSideBar>
      <LeftSideBar ref={refEmployeeSidebar}>
        <TeamEmplyeeFormComponent
          closeSideBar={() => {
            dispatch(setCurrentTeamEmployee({}));
            refEmployeeSidebar.current?.closeSidebar();
          }}
        />
      </LeftSideBar>
      <LeftSideBar ref={refConfluenceSidebar}>
        <TeamConfluenceFormComponent
          closeSideBar={() => {
            dispatch(setCurrentConfluence({}));
            refConfluenceSidebar.current?.closeSidebar();
          }}
        />
      </LeftSideBar>
      {isAdminTeam && (
        <div className={classes.manageGroups}>
          <div>
            <Button
              view={'secondary'}
              form={'default'}
              label={'Добавить подразделение'}
              size={'xs'}
              iconRight={IconAdd}
              onClick={openGroupsSidebarClick}
            />
          </div>
          <div>
            Действия с подразделениями:
            <Button
              size={'xs'}
              label={'Изменить наименования и порядок'}
              view={'ghost'}
              iconRight={IconEdit}
              onClick={openGroupsSidebarClick}
            />
          </div>
        </div>
      )}
      <LeftSideBar ref={refGroupsSidebar}>
        <TeamGroupsFormComponent
          closeSideBar={() => {
            refGroupsSidebar.current?.closeSidebar();
          }}
        />
      </LeftSideBar>
    </div>
  );
};

export default TeamComponent;
