import React, { useCallback, useState } from 'react';

import { BlogCommentType } from '../../../../../../api/blogServerResponse';
import CommentItem from './CommentItem/CommentItem';
import classes from './ContentComments.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { appApi } from '../../../../../../api/appApi';
import { serverApi } from '../../../../../../api/serverApi';

interface IContentCommentsProps {
  comments: BlogCommentType[] | undefined;
  updateNews: () => void;
  newsId: string | undefined;
}

const ContentComments: React.FC<IContentCommentsProps> = ({ comments, updateNews, newsId }) => {
  const [value, setValue] = useState<string | null>(null);
  const handleChange = ({ value }: { value: string | null }) => setValue(value);

  const addComment = useCallback(() => {
    if (value && newsId) {
      serverApi.postBlogComment(value, newsId).then(() => {
        updateNews();
      });
    }
  }, [value]);
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Комментарии`}</Text>
      {/*<Text className={classes.userName}>{`Сергеев П.А.`}</Text>*/}
      <div className={classes.textField}>
        <TextField
          onChange={handleChange}
          value={value}
          placeholder="Оставьте комментарий..."
          type="textarea"
          rows={5}
          style={{ width: '100%' }}
        />
      </div>
      <div className={classes.btnContainer}>
        <Button label="Опубликовать" size={'s'} onClick={addComment} />
      </div>
      {comments?.map((comment, ind) => (
        <CommentItem updateNews={updateNews} key={ind} newsId={newsId} comment={comment} />
      ))}
      {/*<Button label={`Показать ещё`} view={'ghost'} />*/}
    </div>
  );
};

export default ContentComments;
