import React, { useState } from 'react';

import bannerImg from '../../../img/blogPage/bannerImg.svg';
import classes from './BlogPageBanner.module.css';
import { Button } from '@consta/uikit/Button';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { useAppDispatch } from '../../../hooks/hooks';
import { setBlogSearchString } from '../../../store/blogSlice';

const BlogPageBanner: React.FC = () => {
  const [search, setSearch] = useState<string | null>();
  const dispatch = useAppDispatch();
  const onChangeHandler = (value: string | null) => {
    setSearch(value);
    dispatch(setBlogSearchString(value || ''));
  };
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.leftColumn}>
          <Text className={classes.title}>{`Блог`}</Text>
          <Text
            className={classes.description}
          >{`Мы собрали лучшие кейсы и другие материалы, чтобы вы\nвсегда оставались в центре происходящего`}</Text>
          <FieldGroup size="m">
            <TextField
              placeholder={'Название  материала...'}
              style={{ width: '657px' }}
              value={search}
              onChange={(value) => onChangeHandler(value.value)}
            />
            <Button label="Button" onlyIcon={true} iconRight={IconSearch} />
          </FieldGroup>
        </div>
        <div className={classes.rightColumn}>
          <img src={bannerImg} alt="bannerImg" className={classes.bannerImg} />
        </div>
      </div>
    </div>
  );
};

export default BlogPageBanner;
