import { serverApi } from '../api/serverApi';
import { BlogItemsType, BlogItemTagType, ViewSelectorItemType } from '../types/types';
import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS: ViewSelectorItemType[] = [
  {
    name: 'Опубликованные материалы',
    slug: 'news',
  },
  {
    name: 'Предложения читателей',
    slug: 'suggestion',
  },
  {
    name: 'Управление рассылкой',
    slug: 'subscription',
    //disabled: true,
  },
  {
    name: 'Дайджесты',
    slug: 'digest',
  },
];

type AdminBlogPageSliceType = {
  viewSelectorActiveItem: ViewSelectorItemType | null;
  searchValue: string | undefined;
  blogFilterValue: string;
  suggestionFilterValue: string;
  digestFilterValue: string;
  inArchive: boolean;
  activeSortingId: number;
  blogItems: BlogItemsType | undefined;
  isLoadingBlogItems: boolean;
  errorAdminBlogPage: string | undefined;
  newsTypes: ViewSelectorItemType[];
  newsTags: BlogItemTagType[];
  digestStatuses: [];
  suggestionsStatuses: [];
  blogStatuses: [];
  blogTypes: [];
  suggestionTypes: [];
  blogRoles: [];
  csvData: any;
};

const initialState: AdminBlogPageSliceType = {
  viewSelectorActiveItem: ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS[0],
  searchValue: undefined,
  activeSortingId: 1,
  blogFilterValue: '',
  suggestionFilterValue: '',
  digestFilterValue: '',
  blogItems: undefined,
  inArchive: false,
  isLoadingBlogItems: false,
  newsTypes: [],
  newsTags: [],
  errorAdminBlogPage: undefined,
  digestStatuses: [],
  suggestionsStatuses: [],
  blogStatuses: [],
  blogTypes: [],
  suggestionTypes: [],
  blogRoles: [],
  csvData: '',
};

export const getAdminBlogItemsThunk = createAsyncThunk<BlogItemsType, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminBlogItemsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogItems(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения элементов блога');
    }
  }
);

export const getAdminBlogCSVThunk = createAsyncThunk<BlogItemsType, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminBlogCSVThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogCsv(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения элементов блога');
    }
  }
);

export const getAdminBlogSubscriptionThunk = createAsyncThunk<
  BlogItemsType,
  string | undefined,
  { rejectValue: string }
>('adminBlogPageSlice/getAdminBlogSubscriptionThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getBlogSubscriptions(params);
  } catch (e) {
    return rejectWithValue('Ошибка получения элементов блога');
  }
});

export const getAdminBlogDigestThunk = createAsyncThunk<BlogItemsType, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminBlogDigestThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogDigest(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения дайджестов');
    }
  }
);

export const getAdminBlogSuggestionThunk = createAsyncThunk<BlogItemsType, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminBlogSuggestionThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogSuggestion(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения предложений');
    }
  }
);

export const getAdminBlogRolesThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminBlogRolesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogSpeakerRoles();
    } catch (e) {
      return rejectWithValue('Ошибка получения ролей блога');
    }
  }
);
export const getDigestStatusesThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getDigestStatusesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getDigestStatuses();
    } catch (e) {
      return rejectWithValue('Ошибка получения статусов дайджеста');
    }
  }
);

export const getBlogStatusesThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getBlogStatusesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogStatuses();
    } catch (e) {
      return rejectWithValue('Ошибка получения статусов блога');
    }
  }
);

export const getBlogTypesThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getBlogTypesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogTypes();
    } catch (e) {
      return rejectWithValue('Ошибка получения статусов блога');
    }
  }
);

export const getSuggestionTypesThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getSuggestionTypesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getSuggestionTypes();
    } catch (e) {
      return rejectWithValue('Ошибка получения статусов блога');
    }
  }
);

export const getSuggestionStatusesThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getSuggestionStatusesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getSuggestionStatuses();
    } catch (e) {
      return rejectWithValue('Ошибка получения статусов дайджеста');
    }
  }
);

export const getBlogNewsTypesThunk = createAsyncThunk<
  ViewSelectorItemType[],
  string | undefined,
  { rejectValue: string }
>('adminBlogPageSlice/getBlogNewsTypesThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getBlogNewsTypes();
  } catch (e) {
    return rejectWithValue('Ошибка получения типов новостей');
  }
});

export const getBlogNewsTagsThunk = createAsyncThunk<BlogItemTagType[], string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getBlogNewsTagsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogNewsTags();
    } catch (e) {
      return rejectWithValue('Ошибка получения тэгов');
    }
  }
);

export const postBlogNewsThunk = createAsyncThunk<any, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postBlogNewsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.postNews(params);
    } catch (e) {
      return rejectWithValue('Ошибка добавления новости');
    }
  }
);

export const updateSuggestionThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/updateSuggestionThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.updateSuggestion(params);
    } catch (e) {
      return rejectWithValue('Ошибка обновления предложения');
    }
  }
);

export const postDigestThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postDigestThunk',
  async (params: any, { rejectWithValue }) => {
    try {
      return await serverApi.postDigest(params);
    } catch (e) {
      return rejectWithValue('Ошибка добавления новости');
    }
  }
);

export const updateDigestThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/updateDigestThunk',
  async (params: any, { rejectWithValue }) => {
    try {
      return await serverApi.updateDigest(params);
    } catch (e) {
      return rejectWithValue('Ошибка обновления дайджеста');
    }
  }
);

export const updateBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/updateBlogNewsThunk',
  async (params: any, { rejectWithValue }) => {
    try {
      return await serverApi.updateNews(params.data, params.uuid);
    } catch (e) {
      return rejectWithValue('Ошибка добавления новости');
    }
  }
);

export const publishBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/publishBlogNewsThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.publishNews(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка публикации новости');
    }
  }
);

export const archiveBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/archiveBlogNewsThunk',
  async (uuid: any, { rejectWithValue }) => {
    try {
      return await serverApi.archiveNews(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка архивирования новости');
    }
  }
);

export const archiveDigestThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/archiveDigestThunk',
  async (uuid: any, { rejectWithValue }) => {
    try {
      return await serverApi.archiveDigest(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка архивирования дайджеста');
    }
  }
);
export const archiveSuggestionThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/archiveSuggestionThunk',
  async (uuid: any, { rejectWithValue }) => {
    try {
      return await serverApi.archiveSuggestion(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка архивирования предложения');
    }
  }
);

export const deleteBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteBlogNewsThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteNews(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления новости');
    }
  }
);
export const deleteBlogSubscriptionThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteBlogSubscriptionThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteSubscription(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления подписки');
    }
  }
);
export const deleteDigestThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteDigest',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteDigest(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления дайджеста');
    }
  }
);
export const deleteSuggestionThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteSuggestionThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteSuggestion(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления предложения');
    }
  }
);

export const addBlogSubscriptionThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/addBlogSubscriptionThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.addSubscription(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка добавления подписчика');
    }
  }
);

export const adminBlogSlice = createSlice({
  name: 'adminBlogPageSlice',
  initialState,
  reducers: {
    setViewSelectorActiveItem: (state, action) => {
      state.viewSelectorActiveItem = action.payload;
    },
    setAdminBlogSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setAdminBlogInArchive: (state, action) => {
      state.inArchive = action.payload;
    },
    setAdminBlogActiveSortingId: (state, action) => {
      state.activeSortingId = action.payload;
    },
    setBlogFilterValue: (state, action) => {
      state.blogFilterValue = action.payload;
    },
    setSuggestionFilterValue: (state, action) => {
      state.suggestionFilterValue = action.payload;
    },
    setDigestFilterValue: (state, action) => {
      state.digestFilterValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminBlogItemsThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogItems = action.payload;
      })
      .addCase(getAdminBlogCSVThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.csvData = action.payload;
      })
      .addCase(getAdminBlogSubscriptionThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogItems = action.payload;
      })
      .addCase(getAdminBlogDigestThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogItems = action.payload;
      })
      .addCase(getAdminBlogSuggestionThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogItems = action.payload;
      })
      .addCase(getAdminBlogRolesThunk.fulfilled, (state, action) => {
        state.blogRoles = action.payload;
      })
      .addCase(getBlogNewsTypesThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.newsTypes = action.payload;
      })
      .addCase(getBlogNewsTagsThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.newsTags = action.payload;
      })
      .addCase(getDigestStatusesThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.digestStatuses = action.payload;
      })
      .addCase(getBlogStatusesThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogStatuses = action.payload;
      })
      .addCase(getBlogTypesThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogTypes = action.payload;
      })
      .addCase(getSuggestionTypesThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.suggestionTypes = action.payload;
      })
      .addCase(getSuggestionStatusesThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.suggestionsStatuses = action.payload;
      })
      .addCase(getAdminBlogItemsThunk.pending, (state) => {
        state.blogItems = undefined;
        state.isLoadingBlogItems = true;
        state.errorAdminBlogPage = undefined;
      })
      .addCase(getAdminBlogDigestThunk.pending, (state) => {
        state.blogItems = undefined;
        state.isLoadingBlogItems = true;
        state.errorAdminBlogPage = undefined;
      })
      .addCase(getAdminBlogSuggestionThunk.pending, (state) => {
        state.blogItems = undefined;
        state.isLoadingBlogItems = true;
        state.errorAdminBlogPage = undefined;
      })
      .addCase(getAdminBlogSubscriptionThunk.pending, (state) => {
        state.blogItems = undefined;
        state.isLoadingBlogItems = true;
        state.errorAdminBlogPage = undefined;
      })

      .addCase(getAdminBlogItemsThunk.rejected, (state, action) => {
        state.isLoadingBlogItems = false;
        state.errorAdminBlogPage = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - adminBlogPageSlice';
      })
      .addCase(getBlogNewsTypesThunk.rejected, (state, action) => {
        state.isLoadingBlogItems = false;
        state.errorAdminBlogPage = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - adminBlogPageSlice';
      });
  },
});

export const {
  setViewSelectorActiveItem,
  setAdminBlogSearchValue,
  setAdminBlogInArchive,
  setAdminBlogActiveSortingId,
  setBlogFilterValue,
  setSuggestionFilterValue,
  setDigestFilterValue,
} = adminBlogSlice.actions;

export const selectorViewSelectorActiveItem = (state: RootState) => state.adminBlog.viewSelectorActiveItem;
export const selectorViewSelectorSearchValue = (state: RootState) => state.adminBlog.searchValue;
export const selectorViewSelectorInArchive = (state: RootState) => state.adminBlog.inArchive;
export const selectorViewSelectorActiveSortingId = (state: RootState) => state.adminBlog.activeSortingId;
export const selectorBlogFilter = (state: RootState) => state.adminBlog.blogFilterValue;
export const selectorSuggestionFilter = (state: RootState) => state.adminBlog.suggestionFilterValue;
export const selectorDigestFilter = (state: RootState) => state.adminBlog.digestFilterValue;
export const selectorViewSelectorBlogItems = (state: RootState) => state.adminBlog.blogItems;
export const selectorViewBlogCSV = (state: RootState) => state.adminBlog.csvData;
export const selectorViewSelectorNewsTypes = (state: RootState) => state.adminBlog.newsTypes;
export const selectorViewSelectorNewsTags = (state: RootState) => state.adminBlog.newsTags;
export const selectorDigestStatuses = (state: RootState) => state.adminBlog.digestStatuses;
export const selectorBlogStatuses = (state: RootState) => state.adminBlog.blogStatuses;
export const selectorBlogTypes = (state: RootState) => state.adminBlog.blogTypes;
export const selectorSuggestionTypes = (state: RootState) => state.adminBlog.suggestionTypes;
export const selectorSuggestionStatuses = (state: RootState) => state.adminBlog.suggestionsStatuses;
export const selectorBlogRoles = (state: RootState) => state.adminBlog.blogRoles;
export const selectorViewSelectorIsLoadingBlogItems = (state: RootState) => state.adminBlog.isLoadingBlogItems;

export const selectorErrorAdminBlogPage = (state: RootState) => state.adminBlog.errorAdminBlogPage;

export default adminBlogSlice.reducer;
