import React from 'react';

import TableWrapper from '../../../../../Components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../../../hooks/hooks';
import { Table } from '../../../../../shared-front/Table/Table';

import classes from './AdminMethodologyDocumentsTable.module.css';
import AdminBlogTableActions from './AdminBlogTableActions/AdminBlogTableActions';
import { ADMIN_METHODOLOGY_TABLE_COLUMNS } from './adminMethodologyDocumentsTableColumns';
import { selectorAdminMethodologyProcessItems } from '../../../../../store/adminMethodologySlice';
import AdminBlogTableTags from '../../../AdminBlogPage/AdminBlogPageContent/AdminBlogTable/AdminBlogTableTags/AdminBlogTableTags';

interface IAdminBlogTableProps {}

const AdminMethodologyDocumentsTable: React.FC<IAdminBlogTableProps> = () => {
  const items = useAppSelector(selectorAdminMethodologyProcessItems)?.data;

  const tableData =
    items?.map((item: any) => {
      return {
        name: item.title ?? '',
        process: item.process?.name ?? '',
        type: item.type ?? '',
        status: item.status ?? '',
        organization: item.owner_organization ?? '',
        tags: <AdminBlogTableTags tags={item.tags} />,
        actions: <AdminBlogTableActions item={item} />,
      };
    }) || [];

  return (
    <div className={classes.container}>
      <TableWrapper height={'100%'}>
        <Table data={tableData} columns={ADMIN_METHODOLOGY_TABLE_COLUMNS} isColumnsResizable={true} />
      </TableWrapper>
    </div>
  );
};

export default AdminMethodologyDocumentsTable;
