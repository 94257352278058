import instanceAxios from './instanceAxios';
import {
  getDocumentServerType,
  getDocumentsServerType,
  getProcessByUuidServerType,
  getProcessServerType,
  UniversalServerType,
} from './serverResponse';

export const methodologyApi = {
  async getDocumentTags() {
    const response = await instanceAxios.get<UniversalServerType>('/dictionary/vdpgtag/name');
    return response.data;
  },

  async getDocumentTypes() {
    const response = await instanceAxios.get<UniversalServerType>('/dictionary/vdctdocument/type');
    return response.data;
  },

  async getDocumentFormats() {
    const response = await instanceAxios.get<UniversalServerType>('/dictionary/vdctdocument/format');
    return response.data;
  },

  async getDocumentStatuses() {
    const response = await instanceAxios.get<UniversalServerType>('/dictionary/vdctdocument/status');
    return response.data;
  },

  async getProcesses() {
    const response = await instanceAxios.get<getProcessServerType>('/pbs/dct-process');
    return response.data;
  },

  async getDocuments(queryString: string | undefined) {
    if (queryString) {
      const response = await instanceAxios.get<getDocumentsServerType>(`/pbs/dct-document${queryString}`);
      return response.data;
    }
    const response = await instanceAxios.get<getDocumentsServerType>('/pbs/dct-document');
    return response.data;
  },

  async getDocumentByUuid(documentUuid: string) {
    const response = await instanceAxios.get<getDocumentServerType>(`/pbs/dct-document/${documentUuid}`);
    return response.data;
  },

  async getProcessByUuid(processUuid: string) {
    const response = await instanceAxios.get<getProcessByUuidServerType>(`/pbs/dct-process/${processUuid}`);
    return response.data;
  },
  async getMethodologyStaticData() {
    const response = await instanceAxios.get<getProcessByUuidServerType>(`/metodology/static`);
    return response.data;
  },
};
