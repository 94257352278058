import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { useGetMethodologyDocuments } from '../../../../../../hooks/useGetMethodologyDocuments';
import {
  selectorDocumentStatuses,
  selectorDocumentTags,
  selectorDocumentTypes,
  selectorSelectedDocumentStatuses,
  selectorSelectedDocumentTags,
  selectorSelectedDocumentTypes,
  selectorViewType,
  setSelectedDocumentStatuses,
  setSelectedDocumentTags,
  setSelectedDocumentTypes,
} from '../../../../../../store/methodologySlice';
import { ComboboxItemType, TypeViewEnum } from '../../../../../../types/types';
import { getComboboxItems } from '../../../../../../utils/functions';
import classes from './ControlSelectorsRow.module.css';
import { Combobox } from '@consta/uikit/Combobox';

const ControlSelectorsRow: React.FC = () => {
  const dispatch = useAppDispatch();
  const viewType = useAppSelector(selectorViewType);
  const documentStatuses = useAppSelector(selectorDocumentStatuses);
  const documentTypes = useAppSelector(selectorDocumentTypes);
  const documentTags = useAppSelector(selectorDocumentTags);
  const selectedDocumentStatuses = useAppSelector(selectorSelectedDocumentStatuses);
  const selectedDocumentTypes = useAppSelector(selectorSelectedDocumentTypes);
  const selectedDocumentTags = useAppSelector(selectorSelectedDocumentTags);

  const getMethodologyDocuments = useGetMethodologyDocuments();

  const statusItems = getComboboxItems(documentStatuses);
  const onChangeStatus = ({ value }: { value: ComboboxItemType | null }) => {
    const isIncluded = selectedDocumentStatuses.find((status) => status.label === value?.label);
    if (!isIncluded && value) {
      const newSelectedDocumentStatuses = [...selectedDocumentStatuses, value];
      dispatch(setSelectedDocumentStatuses(newSelectedDocumentStatuses));
      getMethodologyDocuments({ selectedDocumentStatuses: newSelectedDocumentStatuses });
    }
  };

  const typeItems = getComboboxItems(documentTypes);
  const onChangeType = ({ value }: { value: ComboboxItemType | null }) => {
    const isIncluded = selectedDocumentTypes.find((type) => type.label === value?.label);
    if (!isIncluded && value) {
      const newSelectedDocumentTypes = [...selectedDocumentTypes, value];
      dispatch(setSelectedDocumentTypes(newSelectedDocumentTypes));
      getMethodologyDocuments({ selectedDocumentTypes: newSelectedDocumentTypes });
    }
  };

  const tagItems = getComboboxItems(documentTags);
  const onChangeTag = ({ value }: { value: ComboboxItemType | null }) => {
    const isIncluded = selectedDocumentTags.find((tag) => tag.label === value?.label);
    if (!isIncluded && value) {
      const newSelectedDocumentTags = [...selectedDocumentTags, value];
      dispatch(setSelectedDocumentTags(newSelectedDocumentTags));
      getMethodologyDocuments({ selectedDocumentTags: newSelectedDocumentTags });
    }
  };

  return (
    <div className={classes.container}>
      <>
        <Combobox
          items={statusItems}
          value={null}
          onChange={onChangeStatus}
          size={'s'}
          placeholder={'Статус документа'}
          className={classes.comboBox}
        />
        <Combobox
          items={typeItems}
          value={null}
          onChange={onChangeType}
          size={'s'}
          placeholder={'Тип документа'}
          className={classes.comboBox}
        />
        <Combobox
          items={tagItems}
          value={null}
          onChange={onChangeTag}
          size={'s'}
          placeholder={'Тэги'}
          className={classes.comboBox}
        />
      </>

      {/*{isTableView && (
        <div className={classes.buttonsGroupContainer}>
          <Button size="s" label="Фильтр" view="ghost" iconLeft={IconFunnel} onlyIcon />
          <Button size="s" label="Фильтр" view="ghost" iconLeft={IconSettings} onlyIcon />
        </div>
      )}*/}
    </div>
  );
};

export default ControlSelectorsRow;
