import { ReactNode } from 'react';

import { createTableColumn } from '../../../../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../../../../types/types';

export type AdminMethodologyTableDataType = {
  name?: ReactNode | null;
  parent_process?: ReactNode | null;
  owner?: ReactNode | null;
  center?: ReactNode | null;
  actions?: ReactNode | null;
};

export const ADMIN_METHODOLOGY_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'name',
    title: 'Название',
    textLinesNumber: 3,
    isShow: true,
    size: 355,
  }),
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'parent_process',
    title: 'Вышестоящий процесс',
    isShow: true,
    size: 355,
  }),

  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'owner',
    title: 'Владелец процесса',
    isShow: true,
    size: 270,
  }),
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'center',
    title: 'Центр компетенций по стандартизации',
    isShow: true,
    size: 270,
  }),
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'actions',
    title: 'Доступные действия',
    textLinesNumber: 2,
    isShow: true,
  }),
];
