import React from 'react';

import classes from './StepSection.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';
import { IconForward } from '@consta/uikit/IconForward';

type PropsType = {
  stepTitle: string;
  stepDescription: string;
  buttonLabel: string;
  onButtonClick: () => void;
  id: number;
};

const StepSection: React.FC<PropsType> = ({ stepTitle, stepDescription, buttonLabel, onButtonClick, id }) => {
  return (
    <div className={classes.container}>
      {id === 1 && (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="8" fill="#ECF1F4" />
          <path
            d="M29.2 35.4C35.3856 35.4 40.4 30.3856 40.4 24.2C40.4 18.0144 35.3856 13 29.2 13C23.0144 13 18 18.0144 18 24.2C18 30.3856 23.0144 35.4 29.2 35.4Z"
            fill="#00359B"
          />
          <path
            d="M29 8C30.1046 8 31 8.89543 31 10L31 38C31 39.1046 30.1046 40 29 40L10 40C8.89543 40 8 39.1046 8 38L8 10C8 8.89543 8.89543 8 10 8L29 8Z"
            fill="#0078D2"
          />
          <path
            d="M13.5 26.5C14.8807 26.5 16 25.3807 16 24C16 22.6193 14.8807 21.5 13.5 21.5C12.1193 21.5 11 22.6193 11 24C11 25.3807 12.1193 26.5 13.5 26.5Z"
            fill="white"
          />
          <path d="M28 23H18V25H28V23Z" fill="white" />
          <path
            d="M13.5 35C14.8807 35 16 33.8807 16 32.5C16 31.1193 14.8807 30 13.5 30C12.1193 30 11 31.1193 11 32.5C11 33.8807 12.1193 35 13.5 35Z"
            fill="white"
          />
          <path d="M28 31.5H18V33.5H28V31.5Z" fill="white" />
          <path
            d="M13.5 18C14.8807 18 16 16.8807 16 15.5C16 14.1193 14.8807 13 13.5 13C12.1193 13 11 14.1193 11 15.5C11 16.8807 12.1193 18 13.5 18Z"
            fill="white"
          />
          <path d="M28 14.5H18V16.5H28V14.5Z" fill="white" />
        </svg>
      )}
      {id === 2 && (
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.333008" width="48" height="48" rx="8" fill="#ECF1F4" />
          <path d="M15.333 34H33.333V37H15.333V34Z" fill="#0078D2" />
          <path d="M19.333 30H29.333V34H19.333V30Z" fill="#00359B" />
          <rect x="8.33301" y="11" width="32" height="19" rx="2" fill="#0078D2" />
          <circle cx="24.333" cy="17" r="4" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.333 28C31.333 24.134 28.199 21 24.333 21C20.467 21 17.333 24.134 17.333 28H31.333Z"
            fill="white"
          />
        </svg>
      )}
      {id === 3 && (
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.666992" width="48" height="48" rx="8" fill="#ECF1F4" />
          <path d="M15.667 34H33.667V37H15.667V34Z" fill="#0078D2" />
          <path d="M19.667 30H29.667V34H19.667V30Z" fill="#00359B" />
          <rect x="8.66699" y="11" width="32" height="19" rx="2" fill="#0078D2" />
          <path
            d="M27.667 20.5C27.667 22.1569 26.3238 23.5 24.667 23.5C23.0101 23.5 21.667 22.1569 21.667 20.5C21.667 18.8431 23.0101 17.5 24.667 17.5C26.3238 17.5 27.667 18.8431 27.667 20.5Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.8194 21.9595C18.4907 25.125 21.3015 27.5 24.667 27.5C25.5256 27.5 26.3481 27.3454 27.1082 27.0625C27.3795 27.3329 27.7537 27.5 28.167 27.5C28.9954 27.5 29.667 26.8284 29.667 26C29.667 25.8204 29.6354 25.6482 29.5776 25.4886C30.8673 24.2189 31.667 22.4528 31.667 20.5C31.667 18.5472 30.8673 16.7811 29.5776 15.5114C29.6354 15.3518 29.667 15.1796 29.667 15C29.667 14.1716 28.9954 13.5 28.167 13.5C27.7537 13.5 27.3795 13.6671 27.1082 13.9375C26.3481 13.6546 25.5256 13.5 24.667 13.5C21.3015 13.5 18.4907 15.875 17.8194 19.0405C17.1586 19.1973 16.667 19.7912 16.667 20.5C16.667 21.2088 17.1586 21.8027 17.8194 21.9595ZM24.667 26.5C25.3713 26.5 26.0472 26.3787 26.675 26.1558C26.6697 26.1046 26.667 26.0526 26.667 26C26.667 25.1716 27.3386 24.5 28.167 24.5C28.4492 24.5 28.7132 24.5779 28.9386 24.7134C30.0074 23.63 30.667 22.1421 30.667 20.5C30.667 18.8579 30.0074 17.37 28.9386 16.2866C28.7132 16.4221 28.4492 16.5 28.167 16.5C27.3386 16.5 26.667 15.8284 26.667 15C26.667 14.9474 26.6697 14.8954 26.675 14.8442C26.0472 14.6213 25.3713 14.5 24.667 14.5C21.8178 14.5 19.4325 16.4859 18.8197 19.1491C19.3212 19.3918 19.667 19.9055 19.667 20.5C19.667 21.0945 19.3212 21.6082 18.8197 21.8509C19.4325 24.5141 21.8178 26.5 24.667 26.5Z"
            fill="white"
          />
        </svg>
      )}
      <Text className={classes.title} size="m" weight={'bold'}>
        {stepTitle}
      </Text>
      <Text className={classes.description} size="m">
        {stepDescription}
      </Text>
      <div className={classes.buttonContainer}>
        <Button
          label={buttonLabel}
          view="secondary"
          onClick={onButtonClick}
          iconRight={IconForward}
          className={classes.button}
        />
      </div>
    </div>
  );
};

export default StepSection;
