import adminBlogReducer from './adminBlogSlice';
import adminReducer from './adminSlice';
import appReducer from './appSlice';
import blogReducer from './blogSlice';
import contactsReducer from './contactsSlice';
import catalogsSearchReducer from './catalogsSearchSlice';
import commandReducer from './commandSlice';
import learningReducer from './learningSlice';
import methodologyReducer from './methodologySlice';
import teamReducer from './teamSlice';
import adminMethodologySlice from './adminMethodologySlice';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    app: appReducer,
    methodology: methodologyReducer,
    learning: learningReducer,
    catalogsSearch: catalogsSearchReducer,
    blog: blogReducer,
    team: teamReducer,
    admin: adminReducer,
    command: commandReducer,
    adminBlog: adminBlogReducer,
    contacts: contactsReducer,
    adminMethodology: adminMethodologySlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
