import React, { useCallback, useState } from 'react';

import ViewControl from '../ViewControl/ViewControl';
import ControlConditionsRow from './ControlConditionsRow/ControlConditionsRow';
import ControlSelectorsRow from './ControlSelectorsRow/ControlSelectorsRow';
import classes from './DocumentsControl.module.css';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useGetMethodologyDocuments } from '../../../../../hooks/useGetMethodologyDocuments';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { setSearch } from '../../../../../store/methodologySlice';

const DocumentsControl: React.FC = () => {
  const [value, setValue] = useState<string | null>(null);
  const getMethodologyDocuments = useGetMethodologyDocuments();
  const dispatch = useAppDispatch();
  const handleChange = useCallback(({ value }: { value: string | null }) => {
    setValue(value);
    dispatch(setSearch(value));
    getMethodologyDocuments({ selectedSearch: value || '' });
  }, []);
  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', width: '100%' }}>
        <ViewControl />
        <ControlSelectorsRow />
      </div>
      <div className={classes.textField}>
        <TextField
          value={value}
          onChange={handleChange}
          style={{ flexGrow: '1', width: '360px', display: 'flex' }}
          leftSide={IconSearch}
          placeholder="Введите название документа"
          size={'s'}
          withClearButton={true}
        />
      </div>
      <ControlConditionsRow />
    </div>
  );
};

export default DocumentsControl;
