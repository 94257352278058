import React, { useCallback, useEffect, useState } from 'react';

import classes from './BLogFilterFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  getBlogStatusesThunk,
  getBlogTypesThunk,
  selectorBlogStatuses,
  selectorBlogTypes,
  setBlogFilterValue,
} from '../../../../../../store/adminBlogSlice';
import { getBlogTagsDictionary, selectorBlogTagsDictionary } from '../../../../../../store/blogSlice';
import { useGetBlogItems } from '../../../../../../hooks/useGetBlogItems';

type Item = {
  label: string;
  id: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const BLogFilterFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(selectorBlogStatuses);
  const tags = useAppSelector(selectorBlogTagsDictionary);
  const types = useAppSelector(selectorBlogTypes).filter((el: any) => el.name !== 'Кейс');
  const getBlogItems = useGetBlogItems();
  const [statusValue, setStatusValue] = useState<any[] | null>();
  const [typeValue, setTypeValue] = useState<any[] | null>();
  const [tagsValue, setTagsValue] = useState<any[] | null>();
  const setFilter = useCallback(() => {
    const tmp: string[] = [];
    if (statusValue && statusValue.length) {
      statusValue.map((el: any) => {
        tmp.push(`filter[status][]=${el.uuid}`);
      });
    }
    if (typeValue && typeValue.length) {
      typeValue.map((el: any) => {
        tmp.push(`filter[type][]=${el.slug}`);
      });
    }
    if (tagsValue && tagsValue.length) {
      tagsValue.map((el: any) => {
        tmp.push(`filter[tags][]=${el._uuid}`);
      });
    }
    dispatch(setBlogFilterValue(tmp.join('&')));

    getBlogItems({ filterValue: tmp.join('&') });
  }, [tagsValue, typeValue, statusValue]);
  useEffect(() => {
    dispatch(getBlogStatusesThunk());
    dispatch(getBlogTagsDictionary());
    dispatch(getBlogTypesThunk());
  }, []);
  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Фильтры</div>
        <Button
          view={'ghost'}
          label={'Сбросить фильтры'}
          size={'s'}
          onClick={() => {
            setStatusValue(null);
            setTypeValue(null);
            setTagsValue(null);
          }}
        />
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Статус{' '}
          <span className={classes.clear} onClick={() => setStatusValue(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={statusValue}
            items={statuses}
            getItemLabel={(item: any) => item.name}
            onChange={(value) => {
              setStatusValue(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>

      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Тип{' '}
          <span className={classes.clear} onClick={() => setTypeValue(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={typeValue}
            items={types}
            getItemLabel={(item: any) => item.name}
            onChange={(value) => {
              setTypeValue(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Тэги{' '}
          <span className={classes.clear} onClick={() => setTagsValue(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={tagsValue}
            items={tags}
            getItemLabel={(item: any) => item.name}
            onChange={(value) => {
              setTagsValue(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>

      <div className={classes.bottomButtons}>
        <div></div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button size="m" view="primary" form={'default'} label={'Применить'} onClick={setFilter} />
        </div>
      </div>
    </div>
  );
};

export default BLogFilterFormComponent;
