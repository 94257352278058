import { ReactNode } from 'react';

import { createTableColumn } from '../../../../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../../../../types/types';
import { AdminBlogTableDataType } from '../../../AdminBlogPage/AdminBlogPageContent/AdminBlogTable/adminBlogTableColumns';

export type AdminMethodologyTableDataType = {
  name?: ReactNode | null;
  parent_process?: ReactNode | null;
  owner?: ReactNode | null;
  center?: ReactNode | null;
  actions?: ReactNode | null;
};

export const ADMIN_METHODOLOGY_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'name',
    title: 'Название',
    textLinesNumber: 3,
    isShow: true,
    size: 355,
  }),
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'process',
    title: 'Процесс',
    isShow: true,
    size: 355,
  }),

  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'type',
    title: 'Тип',
    isShow: true,
    size: 270,
  }),
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'status',
    title: 'Статус',
    isShow: true,
    size: 270,
  }),
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'organization',
    title: 'Организация-владелец',
    isShow: true,
    size: 270,
  }),
  createTableColumn<AdminBlogTableDataType>({
    accessorKey: 'tags',
    title: 'Теги',
    isShow: true,
    size: 240,
  }),
  createTableColumn<AdminMethodologyTableDataType>({
    accessorKey: 'actions',
    title: 'Доступные действия',
    textLinesNumber: 2,
    isShow: true,
  }),
];
