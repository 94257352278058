import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BlockContactsType, StoreSnackBarItemType } from '../types/types';

type ContactsSliceType = {
  contacts: BlockContactsType | undefined;
  contactsPG: BlockContactsType | undefined;
  contactsWG: BlockContactsType | undefined;
  error: string | undefined;
  snackBarItems: StoreSnackBarItemType[];
  isLoading: boolean;
};

const initialState: ContactsSliceType = {
  contacts: undefined,
  contactsPG: undefined,
  contactsWG: undefined,
  snackBarItems: [],
  error: undefined,
  isLoading: false,
};

// getContactsByContentBlockSlug

export const getContactsByContentBlockSlug = createAsyncThunk<any, string, { rejectValue: string }>(
  'contactsSlice/getContactsByContentBlockSlug',
  async (slug, { rejectWithValue }) => {
    try {
      return await serverApi.getContactsByContentBlockSlug(slug);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения контактов', e));
    }
  }
);

export const getContactsWGByContentBlockSlug = createAsyncThunk<any, string, { rejectValue: string }>(
  'contactsSlice/getContactsWGByContentBlockSlug',
  async (slug, { rejectWithValue }) => {
    try {
      return await serverApi.getContactsByContentBlockSlug(slug);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения контактов', e));
    }
  }
);
export const getContactsPGByContentBlockSlug = createAsyncThunk<any, string, { rejectValue: string }>(
  'contactsSlice/getContactsPGByContentBlockSlug',
  async (slug, { rejectWithValue }) => {
    try {
      return await serverApi.getContactsByContentBlockSlug(slug);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения контактов', e));
    }
  }
);

export const batchUpdateContacts = createAsyncThunk<any, any, { rejectValue: string }>(
  'contactsSlice/batchUpdateContacts',
  async (params: any, { rejectWithValue }) => {
    try {
      return await serverApi.contactsBatchUpdate(params._uuid, { contacts: params.contacts });
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка редактирования контактов', e));
    }
  }
);

export const contactSlice = createSlice({
  name: 'contactsSlice',
  initialState,
  reducers: {
    setAppError: (state, action) => {
      state.error = action.payload;
    },
    resetAppError: (state) => {
      state.error = undefined;
    },
    clearSnackBarItemsReports: (state) => {
      state.snackBarItems = [];
    },
    deleteSnackBarItemReports: (state, action) => {
      state.snackBarItems = state.snackBarItems.filter((item) => item.key !== action.payload.key);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContactsByContentBlockSlug.fulfilled, (state, action) => {
      state.contacts = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getContactsWGByContentBlockSlug.fulfilled, (state, action) => {
      state.contactsWG = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getContactsPGByContentBlockSlug.fulfilled, (state, action) => {
      state.contactsPG = action.payload;
      state.isLoading = false;
    });
  },
});

// export const {} = contactSlice.actions;

export const selectorContactsByContentBlockSlug = (state: RootState) => state.contacts.contacts;
export const selectorContactsPGByContentBlockSlug = (state: RootState) => state.contacts.contactsPG;
export const selectorContactsWGByContentBlockSlug = (state: RootState) => state.contacts.contactsWG;

export default contactSlice.reducer;
