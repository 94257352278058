import React, { useCallback, useEffect, useState } from 'react';

import NewEmployeeModalContent from '../../../Components/NewEmployeeModalContent/NewEmployeeModalContent';
import classes from './PageHeader.module.css';
import { Button } from '@consta/uikit/Button';
import { IconDownload } from '@consta/uikit/IconDownload';
import { IconInfo } from '@consta/uikit/IconInfo';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getMethodologyStaticDataThunk, selectorMethodologyStaticDataPublic } from '../../../store/methodologySlice';

const PageHeader: React.FC = () => {
  const [isNewEmployeeModalOpen, setIsNewEmployeeModalOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const staticData = useAppSelector(selectorMethodologyStaticDataPublic);
  const [data, setData] = useState<any>();
  const newEmployeeClick = () => {
    setIsNewEmployeeModalOpen(true);
  };

  const openBackLogClick = useCallback(() => {
    const url = data?.find((el: any) => el.key === 'file_backlog')?.value;
    if (url) {
      window.open(url, '_blank', 'noreferrer');
    }
  }, [data]);

  const goToConfluenceClick = useCallback(() => {
    const url = data?.find((el: any) => el.key === 'confluence')?.value;
    console.log(url);
    if (url) {
      window.open(url, '_blank', 'noreferrer');
    }
  }, [data]);

  useEffect(() => {
    if (staticData) {
      setData(staticData);
    }
  }, [staticData]);
  useEffect(() => {
    dispatch(getMethodologyStaticDataThunk());
  }, [dispatch]);
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Методология управления данными`}</Text>
      <div className={classes.linksGroup}>
        <Button
          label={`Новому сотруднику`}
          view={'secondary'}
          size={'s'}
          iconRight={IconInfo}
          onClick={newEmployeeClick}
        />
        <Button
          label={`Открыть файл бэклога`}
          view={'secondary'}
          size={'s'}
          disabled={!data?.find((el: any) => el.key === 'file_backlog')?.value}
          iconRight={IconDownload}
          onClick={openBackLogClick}
        />
        <Button
          label={`Открыть базу знаний`}
          view={'secondary'}
          size={'s'}
          iconRight={IconOpenInNew}
          onClick={goToConfluenceClick}
        />
      </div>
      <Modal
        isOpen={isNewEmployeeModalOpen}
        onClickOutside={() => setIsNewEmployeeModalOpen(false)}
        onEsc={() => setIsNewEmployeeModalOpen(false)}
      >
        <NewEmployeeModalContent staticData={data} closeModal={() => setIsNewEmployeeModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default PageHeader;
