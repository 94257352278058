import React, { useEffect, useState } from 'react';

import { MethodologyProcessServerType } from '../../../../../../api/serverResponse';
import { useAppSelector } from '../../../../../../hooks/hooks';
import { selectorMethodologyProcesses } from '../../../../../../store/methodologySlice';
import { MethodologyProcessType } from '../../../../../../types/types';
import ProcessItem from './ProcessItem/ProcessItem';
import classes from './ProcessList.module.css';
import { uuid } from '@tinymce/tinymce-react/lib/es2015/main/ts/Utils';

const transformProcess = (serverProcess: MethodologyProcessServerType): MethodologyProcessType => {
  return {
    uuid: serverProcess._uuid,
    code: serverProcess.code || 'нет кода',
    name: serverProcess.name || 'нет названия',
    children: [],
  };
};

function buildTreeArray(flatArray: any) {
  // Store the root nodes of the tree

  const result: any = [];

  // Use reduce to create a nodeMap
  const nodeMap = flatArray.reduce((acc: any, item: any) => {
    const tmp = { ...item };
    tmp.uuid = item._uuid;
    acc[item._uuid] = { ...tmp, children: [] };
    return acc;
  }, {});

  // Iterate through flatArray to build the tree
  flatArray.forEach((item: any) => {
    if (item.uuid_process_parent === null) {
      result.push(nodeMap[item._uuid]);
    } else {
      nodeMap[item.uuid_process_parent].children.push(nodeMap[item._uuid]);
    }
  });

  return result;
}

interface IProcessListProps {
  searchValue: string;
}

const ProcessList: React.FC<IProcessListProps> = ({ searchValue }) => {
  const processes = useAppSelector(selectorMethodologyProcesses);
  const [processTree, setProcessTree] = useState<any[]>([]);

  useEffect(() => {
    if (processes.length > 0) {
      const processesTree = buildTreeArray(processes);
      setProcessTree(processesTree);
    }
  }, [processes]);

  useEffect(() => {
    if (searchValue) {
      const filteredProcesses = processes.filter(
        (process) =>
          process.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          process.code?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setProcessTree(
        filteredProcesses.map((el: any) => {
          return { ...el, uuid: el._uuid };
        })
      );
    } else {
      const processesTree = buildTreeArray(processes);
      setProcessTree(processesTree);
    }
  }, [searchValue, processes]);

  return (
    <div className={classes.container}>
      {processTree.map((process, ind) => (
        <ProcessItem key={ind} process={process} />
      ))}
      {!processTree.length && (
        <div className={classes.notFound}>
          По данному запросу не найдено процессов, попробуйте изменить поисковый запрос
        </div>
      )}
    </div>
  );
};

export default ProcessList;
