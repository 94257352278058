import React from 'react';

import './constaFix.css';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { SORTING_ITEMS } from './SORTING_ITEMS';
import { SORTING_ITEMS_DOCUMENTS } from './SORTING_ITEMS_DOCUMENTS';

import classes from './SortingSelector.module.css';
import { cn } from '@bem-react/classname';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import { useGetProcesses } from '../../../../../../hooks/useGetProcesses';
import {
  selectorAdminMethodologyProcessItems,
  selectorMethodologyViewSelectorActiveItem,
  selectorMethodologyViewSelectorActiveSortingId,
  setAdminMethodologyActiveSortingId,
} from '../../../../../../store/adminMethodologySlice';
import { useGetDocuments } from '../../../../../../hooks/useGetDocuments';

export type SortingItemType = {
  id: number;
  label: string;
  ico: React.ReactNode;
  icoItem: React.ReactNode;
  sortParams: string;
};

const cnComboboxExampleRenderItem = cn('ComboboxExampleRenderItem');

const SortingSelector: React.FC = () => {
  const dispatch = useAppDispatch();

  const getProcesses = useGetProcesses();
  const getDocuments = useGetDocuments();

  const activeSortingId = useAppSelector(selectorMethodologyViewSelectorActiveSortingId);
  const view = useAppSelector(selectorMethodologyViewSelectorActiveItem);
  let activeSorting;
  if (view?.slug === 'process') {
    activeSorting = SORTING_ITEMS.find((item) => item.id === activeSortingId);
  } else {
    activeSorting = SORTING_ITEMS_DOCUMENTS.find((item) => item.id === activeSortingId);
  }
  const pagination = useAppSelector(selectorAdminMethodologyProcessItems)?.meta;

  const changeHandler = (activeSortingItem: SortingItemType | null) => {
    if (activeSortingItem) {
      dispatch(setAdminMethodologyActiveSortingId(activeSortingItem.id));
      if (view?.slug === 'process') {
        getProcesses({
          sortingItem: activeSortingItem,
          queryPagination: `page[size]=${pagination?.per_page ? pagination?.per_page : 25}&page[number]=1`,
        });
      } else {
        getDocuments({
          sortingItem: activeSortingItem,
          queryPagination: `page[size]=${pagination?.per_page ? pagination?.per_page : 25}&page[number]=1`,
        });
      }
    }
  };

  return (
    <div className={classes.container}>
      <Combobox
        placeholder="Выберите вариант"
        items={view?.slug === 'process' ? SORTING_ITEMS : SORTING_ITEMS_DOCUMENTS}
        value={activeSorting}
        onChange={(item) => changeHandler(item)}
        size={'s'}
        renderItem={({ item, active, hovered, onClick, onMouseEnter, ref }) => (
          <div
            className={cnComboboxExampleRenderItem('Item', { active, hovered })}
            aria-selected={active}
            aria-hidden="true"
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            ref={ref}
            style={{ display: 'flex', paddingLeft: '8px', fontSize: '14px', margin: '6px 0' }}
          >
            {item.icoItem} {item.label}
          </div>
        )}
        renderValue={({ item }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.ico} {item.label}
          </div>
        )}
        className={`${classes.combobox} without-close`}
      />
    </div>
  );
};

export default SortingSelector;
