import React from 'react';

import { TeamGroupType } from '../../types/types';
import ProductTeamList from './ProductTeamList/ProductTeamList';
import classes from './SectionProductTeam.module.css';

type PropsType = {
  team?: TeamGroupType[];
  slug: string;
};

const SectionProductTeam: React.FC<PropsType> = ({ team, slug }) => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.title}>Команда продукта</div>
        <ProductTeamList team={team} slug={slug} />
      </div>
    </div>
  );
};

export default SectionProductTeam;
