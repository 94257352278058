import React from 'react';

import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import AdminBlogPageActionRow from './AdminBlogPageActionRow/AdminBlogPageActionRow';
import classes from './AdminMethodologyPageHeader.module.css';
import { Text } from '@consta/uikit/Text';
import AdminMethodologyPageViewSelector from './AdminMethodologyPageViewSelector/AdminMethodologyPageViewSelector';
interface IAdminBlogPageHeaderProps {}

const AdminMethodologyPageHeader: React.FC<IAdminBlogPageHeaderProps> = () => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Администрирование Методологии'}</Text>
      <AdminMethodologyPageViewSelector />
      <Delimiter />
      <AdminBlogPageActionRow />
    </div>
  );
};

export default AdminMethodologyPageHeader;
