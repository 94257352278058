import React from 'react';

import { SortingItemType } from './SortingSelector';
import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconSortUp } from '@consta/uikit/IconSortUp';

export const SORTING_ITEMS_DIGEST: SortingItemType[] = [
  {
    id: 1,
    label: 'Сначала новые',
    ico: <IconSortDown view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortDown size={'s'} style={{ marginRight: '10px' }} />,
    sortParams: 'sort=-_create_ts',
  },
  {
    id: 2,
    label: 'Сначала старые',
    ico: <IconSortUp view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortUp size={'s'} style={{ marginRight: '10px' }} />,
    sortParams: 'sort=_create_ts',
  },
  {
    id: 3,
    label: 'По алфавиту A - Z, А - Я',
    ico: <IconSortDown view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortDown size={'s'} style={{ marginRight: '10px' }} />,
    sortParams: 'sort=name',
  },
  {
    id: 4,
    label: 'По алфавиту Я - А, Z - A',
    ico: <IconSortUp view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortUp size={'s'} style={{ marginRight: '10px' }} />,
    sortParams: 'sort=-name',
  },
];
