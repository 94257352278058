import { SortingItemType } from '../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SortingSelector';
import {
  getAdminBlogDigestThunk,
  getAdminBlogSubscriptionThunk,
  getAdminBlogSuggestionThunk,
  selectorSuggestionFilter,
  selectorViewSelectorActiveSortingId,
  selectorViewSelectorBlogItems,
  selectorViewSelectorInArchive,
  selectorViewSelectorSearchValue,
} from '../store/adminBlogSlice';
import { getQueryPagination } from '../utils/adminBlog/getQueryPagination';
import { getQueryPaginationFromFirstPage } from '../utils/adminBlog/getQueryPaginationFromFirstPage';
import { getQuerySearchValue } from '../utils/adminBlog/getQuerySearchValue';
import { getQuerySortValue } from '../utils/adminBlog/getQuerySortValue';
import { combineQuery } from '../utils/combineQuery';
import { useAppDispatch, useAppSelector } from './hooks';
import { getQueryInArchive } from '../utils/adminBlog/getQueryInArchive';

interface useGetBlogItemsType {
  searchValue?: string | null | undefined;
  queryPagination?: string | undefined;
  sortingItem?: SortingItemType;
  inArchive?: boolean;
  filterValue?: string;
}

export const useGetSubscriptionItems = () => {
  const dispatch = useAppDispatch();
  const searchValueStore = useAppSelector(selectorViewSelectorSearchValue);
  const inArchiveValueStore = useAppSelector(selectorViewSelectorInArchive);
  const paginationValueStore = useAppSelector(selectorViewSelectorBlogItems)?.meta;
  const activeSortingIdStore = useAppSelector(selectorViewSelectorActiveSortingId);
  const filterValueStore = useAppSelector(selectorSuggestionFilter);

  return ({ searchValue, queryPagination, sortingItem, inArchive, filterValue }: useGetBlogItemsType) => {
    const querySearchValue =
      searchValue === null ? '' : getQuerySearchValue(searchValue ? searchValue : searchValueStore);

    const queryPaginationValue = queryPagination ? queryPagination : getQueryPagination(paginationValueStore);
    const querySortingValue = sortingItem
      ? getQuerySortValue(sortingItem.id, 'subscription')
      : getQuerySortValue(activeSortingIdStore, 'subscription');
    const filter = filterValue !== undefined ? filterValue : filterValueStore;
    const requestQuery = combineQuery(
      querySearchValue,
      querySortingValue,
      queryPaginationValue ? queryPaginationValue : getQueryPaginationFromFirstPage(paginationValueStore),
      filter
    );

    dispatch(getAdminBlogSubscriptionThunk(requestQuery));
  };
};
