import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { useGetBlogItems } from '../../../../../hooks/useGetBlogItems';
import TextSearchArea from '../../../../../shared-front/TextSearchArea/TextSearchArea';
import {
  addBlogSubscriptionThunk,
  getAdminBlogCSVThunk,
  getAdminBlogDigestThunk,
  getAdminBlogSubscriptionThunk,
  getDigestStatusesThunk,
  getSuggestionStatusesThunk,
  postBlogNewsThunk,
  postDigestThunk,
  selectorDigestStatuses,
  selectorViewBlogCSV,
  selectorViewSelectorActiveItem,
  selectorViewSelectorInArchive,
  selectorViewSelectorSearchValue,
  setAdminBlogSearchValue,
} from '../../../../../store/adminBlogSlice';
import classes from './AdminBlogPageActionRow.module.css';
import SortingSelector from './SortingSelector/SortingSelector';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconDownload } from '@consta/uikit/IconDownload';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { Modal } from '@consta/uikit/Modal';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import { getCommandUsersThunk, selectorCommandUsers } from '../../../../../store/commandSlice';
import { pushSnackBarItemReports } from '../../../../../store/appSlice';
import { TextField } from '@consta/uikit/TextField';
import { DragNDropField } from '@consta/uikit/DragNDropField';
import { IconPhoto } from '@consta/uikit/IconPhoto';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { Attachment } from '@consta/uikit/Attach';
import { IconTrash } from '@consta/uikit/IconTrash';
import { serverApi } from '../../../../../api/serverApi';
import TeamGroupsFormComponent from '../../../../TeamPage/TeamComponent/TeamGroupsForm/TeamGroupsFormComponent';
import LeftSideBar, { LeftSideBarHandleType } from '../../../../../shared-front/LeftSideBar/LeftSideBar';
import BLogFilterFormComponent from './BLogFilterForm/BLogFilterFormComponent';
import SuggestionFilterFormComponent from './SuggestionFilterForm/SuggestionFilterFormComponent';

interface IAdminBlogPageActionRowProps {}
interface UsersType {
  id: string;
  label: string;
}
const AdminBlogPageActionRow: React.FC<IAdminBlogPageActionRowProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getBlogItems = useGetBlogItems();
  const searchValue = useAppSelector(selectorViewSelectorSearchValue);
  const view = useAppSelector(selectorViewSelectorActiveItem);
  const digestStatuses = useAppSelector(selectorDigestStatuses);
  const [subscriptionModalIsOpen, setSubscriptionModalIsOpen] = useState<boolean>(false);
  const [digestModalIsOpen, setDigestModalIsOpen] = useState<boolean>(false);
  const [digestName, setDigestName] = useState<string | null>();
  const [cover, setCoverValue] = useState<File | null>();
  const [file, setFileValue] = useState<File | null>();
  const users = useAppSelector(selectorCommandUsers);
  const [user, setUser] = useState<UsersType | null>(null);
  const [userSearchValue, setUserSearchValue] = useState<string>('');
  const inArchiveStore = useAppSelector(selectorViewSelectorInArchive);
  const csvData = useAppSelector(selectorViewBlogCSV);
  const refSidebar = useRef<LeftSideBarHandleType>(null);

  const openGroupsSidebarClick = () => {
    refSidebar.current?.openSidebar();
  };

  const setSearchValueHandler = (value: string) => {
    dispatch(setAdminBlogSearchValue(value));
  };

  const setUserSearch = useCallback((searchString: string) => {
    setUserSearchValue(searchString);
    dispatch(getCommandUsersThunk(searchString)).then(() => {});
  }, []);
  const onSearch = (value: { searchValue?: string }) => {
    if (value.searchValue) {
      getBlogItems({ searchValue: value.searchValue });
    } else {
      getBlogItems({ searchValue: null });
    }
  };

  const addToSubscription = useCallback(() => {
    if (user) {
      dispatch(addBlogSubscriptionThunk(user?.id)).then((data) => {
        setSubscriptionModalIsOpen(false);
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `${user.label} добавлен в рассылку!`,
            status: 'success',
            autoClose: 5,
          })
        );
        dispatch(getAdminBlogSubscriptionThunk());
      });
    }
  }, [user, dispatch, setSubscriptionModalIsOpen]);

  const addDigest = useCallback(
    (status: string) => {
      const tmp: any = digestStatuses.find((el: any) => el.slug === status);
      if (tmp) {
        const digestData = {
          name: digestName,
          file: file,
          cover: cover,
          uuid_dict_status: tmp.uuid,
        };
        dispatch(postDigestThunk(digestData)).then(() => {
          dispatch(getAdminBlogDigestThunk());
          setDigestModalIsOpen(false);
          dispatch(
            pushSnackBarItemReports({
              key: Math.random(),
              message: `Дайджест добавлен!`,
              status: 'success',
              autoClose: 5,
            })
          );
        });
      }
    },
    [digestStatuses, file, cover, digestName]
  );

  const exportCSV = (csvString: string, fileName: string) => {
    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'download.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  // const publishDigest = useCallback(() => {}, []);
  const downloadCSV = useCallback(() => {
    if (view?.slug === 'news') {
      serverApi.getBlogCsv().then((data) => {
        exportCSV(data, 'blog.csv');
      });
    } else if (view?.slug === 'subscription') {
      serverApi.getSubscriptionCsv().then((data) => {
        exportCSV(data, 'subscription.csv');
      });
    }
  }, [view, csvData]);

  useEffect(() => {
    dispatch(getDigestStatusesThunk());
    dispatch(getSuggestionStatusesThunk());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        {view?.slug === 'news' && !inArchiveStore && (
          <Button label={'Добавить'} size={'s'} iconRight={IconAdd} onClick={() => navigate('/admin/blog/news/add')} />
        )}
        {view?.slug === 'subscription' && (
          <>
            <Button
              label={'Добавить'}
              size={'s'}
              iconRight={IconAdd}
              onClick={() => setSubscriptionModalIsOpen(true)}
            />
            <Modal
              isOpen={subscriptionModalIsOpen}
              className={classes.reportsModal}
              hasOverlay
              onClickOutside={() => setSubscriptionModalIsOpen(false)}
              onEsc={() => setSubscriptionModalIsOpen(false)}
            >
              <div className={classes.addSubscribers}>
                <div className={classes.formTitle}>Добавление в рассылку</div>
                <div className={classes.formContent}>
                  <Combobox
                    style={{ zIndex: 99999 }}
                    label="Сотрудник"
                    items={users}
                    value={user?.id ? user : null}
                    onChange={(value: any) => {
                      setUser(value);
                    }}
                    getItemLabel={(el: UsersType) => el.label}
                    getItemKey={(el: UsersType) => el.id}
                    onSearchValueChange={setUserSearch}
                    searchValue={userSearchValue}
                    className={classes.fullWidth}
                  />
                </div>
                <div className={classes.formFooter}>
                  <Button view={'primary'} size={'m'} label={'Добавить'} onClick={addToSubscription} />
                </div>
              </div>
            </Modal>
          </>
        )}

        {view?.slug === 'digest' && (
          <>
            <Button label={'Добавить'} size={'s'} iconRight={IconAdd} onClick={() => setDigestModalIsOpen(true)} />
            <Modal
              isOpen={digestModalIsOpen}
              className={classes.reportsModal}
              hasOverlay
              onClickOutside={() => setDigestModalIsOpen(false)}
              onEsc={() => setDigestModalIsOpen(false)}
            >
              <div className={classes.addSubscribers}>
                <div className={classes.formTitle}>Добавление дайджеста</div>
                <div className={classes.formContent}>
                  <TextField
                    onChange={(value) => setDigestName(value.value)}
                    label="Название"
                    value={digestName}
                    type="text"
                    placeholder="Введите название"
                    className={classes.fullWidth}
                  />

                  <div className={classes.formItemTitle}>Обложка</div>
                  <DragNDropField onDropFiles={(files) => setCoverValue(files[0])} className={classes.fullWidth}>
                    {({ openFileDialog }) => (
                      <>
                        <IconPhoto size={'l'} view={'ghost'} className={classes.photo} />
                        <Text>
                          Перетащите изображение или{' '}
                          <a className={classes.link} onClick={openFileDialog}>
                            загрузите
                          </a>
                        </Text>
                        <Text view="ghost" font="mono">
                          Поддерживаемые форматы: PNG, TIFF, JPG
                        </Text>
                      </>
                    )}
                  </DragNDropField>
                  {cover && (
                    <div className={classNames([classes.attachment, classes.fullWidth])}>
                      <Attachment
                        key={cover.name}
                        fileName={cover.name}
                        fileExtension={cover.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                        fileDescription={cover.type}
                      />
                      <IconTrash view={'ghost'} onClick={() => setCoverValue(null)} />
                    </div>
                  )}
                  <div className={classes.formItemTitle}>Файл</div>
                  <DragNDropField onDropFiles={(files) => setFileValue(files[0])} className={classes.fullWidth}>
                    {({ openFileDialog }) => (
                      <>
                        <IconPhoto size={'l'} view={'ghost'} className={classes.photo} />
                        <Text>
                          Перетащите изображение или{' '}
                          <a className={classes.link} onClick={openFileDialog}>
                            загрузите
                          </a>
                        </Text>
                        <Text view="ghost" font="mono">
                          Поддерживаемые форматы: DOC, XLS, PDF
                        </Text>
                      </>
                    )}
                  </DragNDropField>
                  {file && (
                    <div className={classNames([classes.attachment, classes.fullWidth])}>
                      <Attachment
                        key={file.name}
                        fileName={file.name}
                        fileExtension={file.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                        fileDescription={file.type}
                      />
                      <IconTrash view={'ghost'} onClick={() => setCoverValue(null)} />
                    </div>
                  )}
                </div>
                <div className={classes.formFooter}>
                  <Button view={'ghost'} size={'m'} label={'Сохранить'} onClick={() => addDigest('draft')} />
                  <Button view={'primary'} size={'m'} label={'Опубликовать'} onClick={() => addDigest('done')} />
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
      <div className={classes.rightSide}>
        {view?.slug === 'news' && (
          <>
            <TextSearchArea
              searchValue={searchValue ? searchValue : ''}
              setSearchValue={setSearchValueHandler}
              onSearch={onSearch}
              customStyleWidth={'140px'}
              placeholder={'Поиск'}
            />
            <div className={classes.divider} />
          </>
        )}
        {view?.slug !== 'digest' && view?.slug !== 'suggestion' && (
          <Button label={'CSV'} size={'s'} view={'primary'} iconLeft={IconDownload} onClick={downloadCSV} />
        )}
        <div className={classes.divider} />
        <SortingSelector />
        {view?.slug !== 'subscription' && view?.slug !== 'digest' && (
          <Button
            onlyIcon
            size={'s'}
            view={'ghost'}
            iconLeft={IconFunnel}
            style={{ marginLeft: '12px' }}
            onClick={openGroupsSidebarClick}
          />
        )}
        <LeftSideBar ref={refSidebar}>
          {view?.slug === 'news' && (
            <BLogFilterFormComponent closeSideBar={() => refSidebar?.current?.closeSidebar()} />
          )}
          {view?.slug === 'suggestion' && (
            <SuggestionFilterFormComponent closeSideBar={() => refSidebar?.current?.closeSidebar()} />
          )}
        </LeftSideBar>
      </div>
    </div>
  );
};

export default AdminBlogPageActionRow;
