import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import TextSearchArea from '../../../../../shared-front/TextSearchArea/TextSearchArea';

import classes from './AdminBlogPageActionRow.module.css';
import SortingSelector from './SortingSelector/SortingSelector';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import LeftSideBar, { LeftSideBarHandleType } from '../../../../../shared-front/LeftSideBar/LeftSideBar';
import {
  selectorMethodologyViewSelectorActiveItem,
  selectorMethodologyViewSelectorInArchive,
} from '../../../../../store/adminMethodologySlice';
import {
  selectorMethodologyProcessesSearchValue,
  setProcessesSearchValue,
} from '../../../../../store/methodologySlice';
import { useGetProcesses } from '../../../../../hooks/useGetProcesses';
import ProcessFilterFormComponent from './ProcessFilterForm/ProcessFilterFormComponent';
import DocumentsFilterFormComponent from './DocumentsFilterForm/DocumentsFilterFormComponent';
import { useGetDocuments } from '../../../../../hooks/useGetDocuments';

interface IAdminBlogPageActionRowProps {}

const AdminBlogPageActionRow: React.FC<IAdminBlogPageActionRowProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getProcesses = useGetProcesses();
  const getDocuments = useGetDocuments();
  const searchValue = useAppSelector(selectorMethodologyProcessesSearchValue);
  const view = useAppSelector(selectorMethodologyViewSelectorActiveItem);
  const inArchiveStore = useAppSelector(selectorMethodologyViewSelectorInArchive);
  const refSidebar = useRef<LeftSideBarHandleType>(null);
  const openGroupsSidebarClick = () => {
    refSidebar.current?.openSidebar();
  };

  const setSearchValueHandler = (value: string) => {
    dispatch(setProcessesSearchValue(value));
  };

  const onSearch = (value: { searchValue?: string }) => {
    if (view?.slug === 'process') {
      if (value.searchValue) {
        getProcesses({ searchValue: value.searchValue });
      } else {
        getProcesses({ searchValue: null });
      }
    } else {
      if (value.searchValue) {
        getDocuments({ searchValue: value.searchValue });
      } else {
        getDocuments({ searchValue: null });
      }
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        {view?.slug === 'process' && !inArchiveStore && (
          <Button label={'Добавить'} size={'s'} iconRight={IconAdd} onClick={() => navigate('/admin/process/add')} />
        )}
        {view?.slug === 'documents' && !inArchiveStore && (
          <Button label={'Добавить'} size={'s'} iconRight={IconAdd} onClick={() => navigate('/admin/document/add')} />
        )}
      </div>
      <div className={classes.rightSide}>
        <>
          <TextSearchArea
            searchValue={searchValue ? searchValue : ''}
            setSearchValue={setSearchValueHandler}
            onSearch={onSearch}
            customStyleWidth={'140px'}
            placeholder={'Поиск'}
          />
          <div className={classes.divider} />
        </>

        <div className={classes.divider} />
        <SortingSelector />
        {view?.slug !== 'subscription' && view?.slug !== 'digest' && (
          <Button
            onlyIcon
            size={'s'}
            view={'ghost'}
            iconLeft={IconFunnel}
            style={{ marginLeft: '12px' }}
            onClick={openGroupsSidebarClick}
          />
        )}
        <LeftSideBar ref={refSidebar}>
          {view?.slug === 'process' && (
            <ProcessFilterFormComponent closeSideBar={() => refSidebar?.current?.closeSidebar()} />
          )}
          {view?.slug === 'documents' && (
            <DocumentsFilterFormComponent closeSideBar={() => refSidebar?.current?.closeSidebar()} />
          )}
        </LeftSideBar>
      </div>
    </div>
  );
};

export default AdminBlogPageActionRow;
