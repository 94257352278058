import { useEffect, useState } from 'react';

import { serverApi } from '../api/serverApi';
import { setAppError } from '../store/appSlice';
import { useAppDispatch } from './hooks';

export const useGetProcess = (id: string | undefined): [any | undefined, boolean] => {
  const dispatch = useAppDispatch();
  const [process, setProcess] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      serverApi
        .getProcessItem(id)
        .then((res) => {
          if (res) {
            setProcess(res.data);
          }
        })
        .catch(() => {
          dispatch(setAppError('Ошибка получения процесса'));
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, dispatch]);

  return [process, isLoading];
};
