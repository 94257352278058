import React from 'react';

import TableWrapper from '../../../../../Components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../../../hooks/hooks';
import { Table } from '../../../../../shared-front/Table/Table';

import { formatDateHHMMDDMMYYYY } from '../../../../../utils/formatDateHHMMDDMMYYYY';
import classes from './AdminMethodologyTable.module.css';
import AdminBlogTableActions from './AdminBlogTableActions/AdminBlogTableActions';
import { AdminMethodologyTableDataType, ADMIN_METHODOLOGY_TABLE_COLUMNS } from './adminMethodologyTableColumns';
import AdminBlogTableTags from './AdminBlogTableTags/AdminBlogTableTags';
import { selectorAdminMethodologyProcessItems } from '../../../../../store/adminMethodologySlice';

interface IAdminBlogTableProps {}

const AdminMethodologyTable: React.FC<IAdminBlogTableProps> = () => {
  const items = useAppSelector(selectorAdminMethodologyProcessItems)?.data;

  const tableData =
    items?.map((item: any) => {
      return {
        name: item.code + ' ' + item.name ?? '',
        parent_process: item.parent_process?.name ?? '',
        owner:
          item.process_owner?.last_name + ' ' + item.process_owner?.first_name + ' ' + item.process_owner?.middle_name,
        center:
          item.competence_center?.last_name +
          ' ' +
          item.competence_center?.first_name +
          ' ' +
          item.competence_center?.middle_name,
        actions: <AdminBlogTableActions item={item} />,
      };
    }) || [];

  return (
    <div className={classes.container}>
      <TableWrapper height={'100%'}>
        <Table data={tableData} columns={ADMIN_METHODOLOGY_TABLE_COLUMNS} isColumnsResizable={true} />
      </TableWrapper>
    </div>
  );
};

export default AdminMethodologyTable;
