import React, { useCallback } from 'react';

import BlogShareBlock from '../../../../../Components/BlogShareBlock/BlogShareBlock';
import BlogSuggestionsBlock from '../../../../../Components/BlogSuggestionsBlock/BlogSuggestionsBlock';
import classes from './RightColumn.module.css';
import { Button } from '@consta/uikit/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { publishBlogNewsThunk } from '../../../../../store/adminBlogSlice';

interface IRightColumnProps {
  news: any;
}

const RightColumn: React.FC<IRightColumnProps> = ({ news }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const publish = useCallback(() => {
    dispatch(publishBlogNewsThunk(news._uuid)).then(() => {
      window.location.reload();
    });
  }, [news]);
  return (
    <div className={classes.container}>
      {news && (
        <div className={classes.buttons}>
          <Button view={'primary'} label={'Опубликовать'} onClick={publish} />
          <Button
            view={'secondary'}
            label={'Редактировать'}
            onClick={() => navigate(`/admin/blog/news/${news._uuid}`)}
          />
        </div>
      )}
      <BlogShareBlock title={`Знаете, кому будет интересен этот\nматериал? Отправьте ссылку на почту в\nодин клик`} />
      <BlogSuggestionsBlock />
    </div>
  );
};

export default RightColumn;
