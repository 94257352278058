import React from 'react';

import classes from './NewEmployeeModalContent.module.css';
import StepSection from './StepSection/StepSection';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  closeModal: () => void;
  staticData: any;
};

const NewEmployeeModalContent: React.FC<PropsType> = ({ closeModal, staticData }) => {
  const onButtonStepOneClick = () => {
    const url = staticData?.find((el: any) => el.key === 'new_employee_step_1')?.value;
    if (url) {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const onButtonStepTwoClick = () => {
    const url = staticData?.find((el: any) => el.key === 'new_employee_step_2')?.value;
    if (url) {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const onButtonStepThreeClick = () => {
    const url = staticData?.find((el: any) => el.key === 'new_employee_step_3')?.value;
    if (url) {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title} size="xl" weight={'bold'}>
        {`Инструкция по погружению в работу с данными`}
      </Text>
      <div className={classes.delimiter} />
      <Text className={classes.description}>
        {`Добро пожаловать на страницу методологии УПД. Пройдите три шага,\n для эффективной работы с разделом и погружения в управление данными.`}
      </Text>
      <div className={classes.sectionsGroup}>
        <StepSection
          id={1}
          stepTitle={'Шаг 1: Политика'}
          stepDescription={'Познакомьтесь с основополагающим\n документом Управления данными'}
          buttonLabel={'Смотреть документ'}
          onButtonClick={onButtonStepOneClick}
        />
        <StepSection
          id={2}
          stepTitle={'Шаг 2: Обучение'}
          stepDescription={'Пройдите короткий обзорный “Курс\n введения в управление данными”'}
          buttonLabel={'Пройти обучение'}
          onButtonClick={onButtonStepTwoClick}
        />
        <StepSection
          id={3}
          stepTitle={'Шаг 3: Процессы'}
          stepDescription={'Детальное ознакомьтесь \nс процессами управления данными'}
          buttonLabel={'Изучить процессы'}
          onButtonClick={onButtonStepThreeClick}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button label={'Начать работу'} view="primary" onClick={closeModal} />
      </div>
    </div>
  );
};

export default NewEmployeeModalContent;
