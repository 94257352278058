import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MethodologyDocumentServerType, MethodologyProcessServerType } from '../../../../../../api/serverResponse';
import { useAppSelector } from '../../../../../../hooks/hooks';
import IconTree from '../../../../../../img/methodologyPage/IconTree.svg';
import { selectorMethodologyProcesses } from '../../../../../../store/methodologySlice';
import { MethodologySearchEnum } from '../../../../../../types/types';
import { getBadgeStatus, getDocumentTags } from '../../../../../../utils/methodology';
import DocumentTagsList from './DocumentTagsList/DocumentTagsList';
import classes from './MethodologyDocument.module.css';
import { Badge } from '@consta/uikit/Badge';
import { Button } from '@consta/uikit/Button';
import { IconDownload } from '@consta/uikit/IconDownload';
import { Text } from '@consta/uikit/Text';
import { Tooltip } from '@consta/uikit/Tooltip';

const getParentProcess = (
  uuid_process_parent: string | null | undefined,
  processes: MethodologyProcessServerType[]
) => {
  return processes.find((process) => process._uuid === uuid_process_parent);
};

const getToolTipTile = (document: MethodologyDocumentServerType, processes: MethodologyProcessServerType[]) => {
  const levels: string[] = [];
  let uuid_process_parent = document.v_dct_processes?.uuid_process_parent;
  while (uuid_process_parent) {
    const parentProcess = getParentProcess(uuid_process_parent, processes);
    if (parentProcess?.name) {
      levels.push(`${parentProcess.code} ${parentProcess.name}`);
    }
    uuid_process_parent = parentProcess?.uuid_process_parent;
  }
  let title = '';
  levels.forEach((level) => {
    title += `/ ${level}\n`;
  });
  title += `/ ${document.v_dct_processes?.code} ${document.v_dct_processes?.name}\n`;
  return title;
};

type PropsType = {
  document: MethodologyDocumentServerType;
};

const MethodologyDocument: React.FC<PropsType> = ({ document }) => {
  const processes = useAppSelector(selectorMethodologyProcesses);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const onCardClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (document.type !== 'Паспорт процесса') {
      searchParams.append(MethodologySearchEnum.documentUuid, document._uuid);
      setSearchParams(searchParams);
    } else {
      searchParams.append('processId', document._uuid);
      setSearchParams(searchParams);
    }
  };

  const onClickDownload = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const onClickProcess = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (getToolTipTile(document, processes)) {
      setIsToolTipVisible((prevState) => !prevState);
    }
  };

  const onMouseLeaveProcesses = () => {
    setIsToolTipVisible(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Badge view="stroked" label={document.type ? document.type : ''} status={getBadgeStatus(document.type)} />
        {document.type !== 'Паспорт процесса' && (
          <Text className={classes.titleStatus} truncate>
            {document.status}
          </Text>
        )}
      </div>
      <Text className={classes.documentTitle} weight={'bold'}>
        {document.title}
      </Text>
      {/*<Text className={classes.organisationTitle}>{document.owner_organization}</Text>*/}
      <div className={classes.tagsListWrapper}>
        <DocumentTagsList documentTags={getDocumentTags(document)} />
      </div>
      <div className={classes.buttonRow}>
        <div className={classes.btnGroup}>
          {(document.link || document.cnmd_link) && (
            <a href={document.link || document.cnmd_link || ''} download target="_blank" rel="noreferrer">
              <Button
                className={classes.buttonDownLoad}
                size={'s'}
                label={document.cnmd_id ? 'Перейти в ЦНМД' : 'Скачать'}
                iconRight={document.cnmd_id ? undefined : IconDownload}
                onClick={onClickDownload}
                form={'round'}
              />
            </a>
          )}
          <Button view={'ghost'} size={'s'} label="Подробнее" form={'round'} onClick={onCardClick} />
        </div>
        {document.type !== 'Паспорт процесса' && (
          <div onMouseLeave={onMouseLeaveProcesses} onClick={onClickProcess}>
            <button ref={buttonRef} className={classes.btnInfo}>
              <img src={IconTree} alt="" />
            </button>
          </div>
        )}
      </div>
      {isToolTipVisible && (
        <Tooltip size="l" anchorRef={buttonRef} direction={'downRight'} className={classes.toolTip} placeholder={''}>
          {getToolTipTile(document, processes)}
        </Tooltip>
      )}
    </div>
  );
};

export default MethodologyDocument;
