import instanceAxios from './instanceAxios';

export const adminMethodologyApi = {
  async getAdminMethodologyProcesses(params?: string) {
    const response = await instanceAxios.get(`/admin/metodology/process${params ? params : ''}`);
    return response.data;
  },
  async getAdminMethodologyDocuments(params?: string) {
    const response = await instanceAxios.get(`/admin/metodology/documents${params ? params : ''}`);
    return response.data;
  },
  async postProcess(body: any) {
    const response = await instanceAxios.post('/admin/metodology/process', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async deleteProcess(uuid: string) {
    const response = await instanceAxios.delete(`/admin/metodology/process/${uuid}`);
    return response.data;
  },
  async archiveProcess(uuid: string) {
    const response = await instanceAxios.get(`/admin/metodology/process/${uuid}/to-archive`);
    return response.data;
  },
  async getProcessItem(id: string) {
    const response = await instanceAxios.get<any>(`/admin/metodology/process/${id}`);
    return response.data;
  },
  async updateProcess(newsBody: any, uuid: string) {
    const response = await instanceAxios.post(`/admin/metodology/process/${uuid}`, newsBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async postStaticSteps(body: any) {
    const response = await instanceAxios.post('/admin/metodology/static/steps', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async postStaticConfluence(body: any) {
    const response = await instanceAxios.post('/admin/metodology/static/confluence', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async postStaticBacklog(body: any) {
    const response = await instanceAxios.post('/admin/metodology/static/file_backlog', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async postDocument(body: any) {
    const response = await instanceAxios.post('/admin/metodology/documents', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async deleteDocument(uuid: string) {
    const response = await instanceAxios.delete(`/admin/metodology/documents/${uuid}`);
    return response.data;
  },
  async deleteMethodologyBacklog() {
    const response = await instanceAxios.delete(`/admin/metodology/static/file_backlog`);
    return response.data;
  },
  async archiveDocument(uuid: string) {
    const response = await instanceAxios.get(`/admin/metodology/documents/${uuid}/to-archive`);
    return response.data;
  },
  async getDocumentItem(id: string) {
    const response = await instanceAxios.get<any>(`/admin/metodology/documents/${id}`);
    return response.data;
  },
  async getAdminDocumentStatuses() {
    const response = await instanceAxios.get<any>(`/admin/metodology/documents/statuses`);
    return response.data;
  },
  async getAdminDocumentTypes() {
    const response = await instanceAxios.get<any>(`/admin/metodology/documents/types`);
    return response.data;
  },
  async getAdminDocumentOrganizations() {
    const response = await instanceAxios.get<any>(`/admin/metodology/documents/organization-owners`);
    return response.data;
  },
  async getAdminMethodologyStaticData() {
    const response = await instanceAxios.get<any>(`/admin/metodology/static/all`);
    return response.data;
  },
  async updateDocument(newsBody: any, uuid: string) {
    const response = await instanceAxios.post(`/admin/metodology/documents/${uuid}`, newsBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
};
