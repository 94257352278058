import React, { useState } from 'react';

import { BlogItemType } from '../../../../../../types/types';
import classes from './AdminBlogTableActions.module.css';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconStorage } from '@consta/uikit/IconStorage';
import { IconTrash } from '@consta/uikit/IconTrash';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  archiveBlogNewsThunk,
  deleteBlogNewsThunk,
  getAdminBlogItemsThunk,
  selectorViewSelectorInArchive,
} from '../../../../../../store/adminBlogSlice';
import BlogModalDelete from '../../../BlogModalDelete/BlogModalDelete';
import { useGetBlogItems } from '../../../../../../hooks/useGetBlogItems';
import { IconRevert } from '@consta/uikit/IconRevert';
import {
  archiveProcessThunk,
  deleteProcessThunk,
  getAdminMethodologyProcessesThunk,
  selectorMethodologyViewSelectorInArchive,
} from '../../../../../../store/adminMethodologySlice';
import { useGetProcesses } from '../../../../../../hooks/useGetProcesses';

interface IAdminBlogTableActionsProps {
  item: BlogItemType;
}

const AdminBlogTableActions: React.FC<IAdminBlogTableActionsProps> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const getItems = useGetProcesses();
  const inArchive = useAppSelector(selectorMethodologyViewSelectorInArchive);
  console.log(item);
  const onEdit = () => {
    navigate(`/admin/process/${item._uuid}`);
  };

  const onArchive = () => {
    dispatch(archiveProcessThunk(item._uuid)).then((data) => {
      getItems({});
    });
  };

  const onDelete = (uuid: string) => {
    dispatch(deleteProcessThunk(item._uuid)).then((data) => {
      setIsOpenDeleteModal(false);
      dispatch(getAdminMethodologyProcessesThunk()).then();
    });
  };

  return (
    <div className={classes.container}>
      <Button size="xs" view="ghost" iconLeft={IconEdit} onlyIcon onClick={onEdit} />
      {!inArchive && <Button size="xs" view="ghost" iconLeft={IconStorage} onlyIcon onClick={onArchive} />}
      {inArchive && <Button size="xs" view="ghost" iconLeft={IconRevert} onlyIcon onClick={onArchive} />}
      <Button size="xs" view="ghost" iconLeft={IconTrash} onlyIcon onClick={() => setIsOpenDeleteModal(true)} />
      <BlogModalDelete
        isModalOpen={isOpenDeleteModal}
        setIsModalOpen={setIsOpenDeleteModal}
        modalDeleteTitle={'Вы действительно хотите удалить  процесс?'}
        onConfirmDelete={() => onDelete(item._uuid)}
      />
    </div>
  );
};

export default AdminBlogTableActions;
