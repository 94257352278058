import React from 'react';

import { BlogItemStatusType } from '../../../../../../types/types';
import { Badge } from '@consta/uikit/Badge';
import { useAppSelector } from '../../../../../../hooks/hooks';
import { selectorViewSelectorInArchive } from '../../../../../../store/adminBlogSlice';
interface IAdminBlogTableBadgeProps {
  status: BlogItemStatusType | null;
}

const AdminBlogTableBadge: React.FC<IAdminBlogTableBadgeProps> = ({ status }) => {
  const inArchive = useAppSelector(selectorViewSelectorInArchive);
  if (!status) {
    return null;
  }

  const statusType = inArchive
    ? 'system'
    : status.slug === 'draft'
    ? 'warning'
    : status.slug === 'planned'
    ? 'normal'
    : status.slug === 'done'
    ? 'success'
    : status.slug === 'new'
    ? 'normal'
    : status.slug === 'in_progress'
    ? 'success'
    : 'error';

  return <Badge status={statusType} label={inArchive ? 'В архиве' : status.name} />;
};

export default AdminBlogTableBadge;
