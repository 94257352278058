import React from 'react';

import { useAppSelector } from '../../../../hooks/hooks';
import { useGetBlogItems } from '../../../../hooks/useGetBlogItems';
import Pagination from '../../../../shared-front/Pagination/Pagination';
import { selectorViewSelectorBlogItems } from '../../../../store/adminBlogSlice';
import classes from './AdminBlogPagePaginationRow.module.css';
import {
  selectorAdminMethodologyProcessItems,
  selectorMethodologyViewSelectorActiveItem,
} from '../../../../store/adminMethodologySlice';
import { useGetProcesses } from '../../../../hooks/useGetProcesses';
import { useGetDocuments } from '../../../../hooks/useGetDocuments';

interface IAdminBlogPagePaginationRowProps {}

const AdminBlogPagePaginationRow: React.FC<IAdminBlogPagePaginationRowProps> = () => {
  // const getBlogItems = useGetBlogItems();
  const getProcesses = useGetProcesses();
  const getDocuments = useGetDocuments();
  const pagination = useAppSelector(selectorAdminMethodologyProcessItems)?.meta;
  const view = useAppSelector(selectorMethodologyViewSelectorActiveItem);

  const onChangePagination = (queryPagination: string) => {
    if (view?.slug === 'process') {
      getProcesses({ queryPagination });
    } else {
      getDocuments({ queryPagination });
    }
  };

  return (
    <div className={classes.container}>
      <Pagination
        currentPageSize={pagination?.per_page}
        currentPage={pagination?.current_page}
        total={pagination?.total}
        className={classes.pagination}
        onChangePagination={onChangePagination}
        lastPage={pagination?.last_page}
      />
    </div>
  );
};

export default AdminBlogPagePaginationRow;
