import React from 'react';

import TableWrapper from '../../../../../Components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../../../hooks/hooks';
import { Table } from '../../../../../shared-front/Table/Table';
import { selectorViewSelectorBlogItems } from '../../../../../store/adminBlogSlice';
import { formatDateHHMMDDMMYYYY } from '../../../../../utils/formatDateHHMMDDMMYYYY';
import classes from './AdminSuggestionTable.module.css';
import AdminSuggestionTableActions from './AdminSuggestionTableActions/AdminSuggestionTableActions';

import { ADMIN_BLOG_TABLE_COLUMNS, AdminBlogDigestDataType } from './adminSuggestionTableColumns';
import AdminBlogTableBadge from '../AdminBlogTable/AdminBlogTableBadge/AdminBlogTableBadge';

interface IAdminBlogTableProps {}

const AdminSuggestionTable: React.FC<IAdminBlogTableProps> = () => {
  const blogItems = useAppSelector(selectorViewSelectorBlogItems)?.data;

  const tableData: AdminBlogDigestDataType[] =
    blogItems?.map((item: any) => {
      return {
        status: <AdminBlogTableBadge status={item.status} />,
        value: item.value,
        type: item.type === 'news' ? 'Новость' : 'Анонс',
        author: item?.author?.last_name + ' ' + item?.author?.first_name,
        date: <div className={classes.dataTime}>{formatDateHHMMDDMMYYYY(new Date(item._create_ts))}</div>,
        actions: <AdminSuggestionTableActions item={item} />,
      };
    }) || [];

  return (
    <div className={classes.container}>
      <TableWrapper height={'100%'}>
        <Table data={tableData} columns={ADMIN_BLOG_TABLE_COLUMNS} isColumnsResizable={true} />
      </TableWrapper>
    </div>
  );
};

export default AdminSuggestionTable;
