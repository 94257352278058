import React from 'react';

import { useAppSelector } from '../../../../hooks/hooks';
import { selectorDivisions } from '../../../../store/commandSlice';
import { UserType } from '../../../../types/types';
import { getGroupsUsers } from '../../../../utils/functions';
import Section from '../Section/Section';
import SectionDelimiter from '../SectionDelimiter/SectionDelimiter';
import classes from './SectionGroups.module.css';

type PropsType = {
  verticalUsers: UserType[][];
  openSidebarClick: () => void;
  openConfluenceSidebar: () => void;
  verticalDomains: { id: string; label: string }[];
  colors: string[];
};

const SectionGroups: React.FC<PropsType> = ({
  verticalUsers,
  openSidebarClick,
  openConfluenceSidebar,
  verticalDomains,
  colors,
}) => {
  const divisions = useAppSelector(selectorDivisions);
  const groupsTitles = divisions.map((el: any) => el.label);
  const groupsUsers = getGroupsUsers(groupsTitles, verticalUsers);
  const groupsAmount = groupsUsers[0]?.length;

  return (
    <div className={classes.container}>
      {groupsTitles.map((title, ind) => {
        return (
          <div key={ind}>
            <Section
              title={title}
              colors={colors}
              users={groupsUsers[ind]}
              domain={verticalDomains}
              openSidebarClick={openSidebarClick}
              division={divisions[ind]}
              openConfluenceSidebar={openConfluenceSidebar}
            />
            {ind - 2 <= groupsAmount && <SectionDelimiter groupsAmount={groupsAmount} />}
          </div>
        );
      })}
    </div>
  );
};

export default SectionGroups;
