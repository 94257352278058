import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { serverApi } from '../../../../api/serverApi';
import { MethodologyDocumentServerType, MethodologyProcessServerType } from '../../../../api/serverResponse';
import { MethodologySearchEnum } from '../../../../types/types';
import ProcessCardsModal from './ProcessCardsModal/ProcessCardsModal';
import classes from './ModalProcessCard.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Modal } from '@consta/uikit/Modal';

const ModalProcessCard: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDocument, setSelectedDocument] = useState<MethodologyProcessServerType>();

  const closeModal = () => {
    setSelectedDocument(undefined);
    searchParams.delete('processId');
    setSearchParams(searchParams);
  };

  const onClickOutside = (e: MouseEvent) => {
    e.stopPropagation();
    closeModal();
  };

  useEffect(() => {
    const documentUuid = searchParams.get('processId');
    if (documentUuid) {
      serverApi.getProcessByUuid(documentUuid).then((result) => {
        if (result.state === 'success') {
          setSelectedDocument(result.data);
        }
      });
    }
  }, [searchParams]);

  return (
    <Modal isOpen={!!selectedDocument} onClickOutside={onClickOutside} onEsc={closeModal}>
      {selectedDocument && (
        <div className={classes.container}>
          <Button
            className={classes.btnCornerClose}
            view="clear"
            iconLeft={IconClose}
            onlyIcon
            size={`s`}
            onClick={closeModal}
          />
          <ProcessCardsModal process={selectedDocument} />
          <div className={classes.footer}>
            <div className={classes.btnSubscribeContainer}>
              <Button
                className={classes.btnSubscribe}
                view="clear"
                disabled
                label={`Подписаться на обновления (E-mail)`}
                size={`m`}
              />
            </div>
            <div className={classes.btnGroup}>
              <Button className={classes.btnClose} view="ghost" label={`Закрыть`} size={`m`} onClick={closeModal} />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalProcessCard;
