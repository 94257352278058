// eslint-disable-next-line simple-import-sort/imports
import React from 'react';

import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce';

import './CustomEditor.css';
// We're manually importing theme, icons and plugins because otherwise TinyMCE tries to resolve
// them himself and load throught http, but this won't work with chrome extension
// Theme
import 'tinymce/themes/silver/theme.min';
// Toolbar icons
import 'tinymce/icons/default/icons.min';
// importing the plugin js.
// import "tinymce/plugins/link/plugin.min.js";
import 'tinymce/plugins/lists/plugin.min.js';
import 'tinymce/plugins/advlist/plugin.min.js';
// import "tinymce/plugins/anchor/plugin.min.js";
// import "tinymce/plugins/autolink/plugin.min.js";
// import "tinymce/plugins/charmap/plugin.min.js";
// import "tinymce/plugins/code/plugin.min.js";
// import "tinymce/plugins/codesample/plugin.min.js";
import 'tinymce/plugins/table/plugin.min.js';
// import "tinymce/plugins/wordcount/plugin.min.js";
// import "tinymce/plugins/image/plugin.min.js";
// import "tinymce/plugins/preview/plugin.min.js";
// import "tinymce/plugins/visualblocks/plugin.min";
// import "tinymce/plugins/searchreplace/plugin.min";
// import "tinymce/plugins/fullscreen/plugin.min";
// import "tinymce/plugins/insertdatetime/plugin.min";
// import "tinymce/plugins/media/plugin.min";
import 'tinymce/models/dom/model.min';
import 'tinymce-i18n/langs6/ru';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

(window as any)['tinymce'] = tinymce;
// TinyMCE wants to be in global scope, even if loaded from npm module
type CustomEditorProps = {
  onChange: () => void;
  value: any;
  placeholder?: string;
  disabled?: boolean;
};

export const CustomEditor = ({ onChange, value, placeholder = '', disabled }: CustomEditorProps) => {
  return (
    <div>
      <Editor
        licenseKey="gpl"
        disabled={disabled}
        onEditorChange={onChange}
        value={value}
        init={{
          height: 600,
          // menubar: "file edit insert view format table tools help",
          menubar: 'format table',
          language: 'ru',
          plugins: [
            'advlist',
            // "autolink",
            'lists',
            // "link",
            // "image",
            // "charmap",
            // "preview",
            // "anchor",
            // "searchreplace",
            // "visualblocks",
            // "code",
            // "fullscreen",
            // "insertdatetime",
            // "media",
            'table',
            // "code",
            // "wordcount",
            // "codesample",
          ],
          content_style: `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: rgba(0, 32, 51, .35) }`,
          placeholder: placeholder,
          toolbar: ` undo redo |   blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent `,
          // | removeformat | help`,
        }}
      />
    </div>
  );
};
