import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  ADMIN_METHODOLOGY_PAGE_VIEW_SELECTOR_ITEMS,
  deleteMethodologyBacklog,
  getAdminMethodologyStaticData,
  postStaticBacklogThunk,
  postStaticConfluenceThunk,
  postStaticStepsThunk,
  selectorAdminMethodologyStaticData,
  selectorMethodologyViewSelectorActiveItem,
  selectorMethodologyViewSelectorInArchive,
  setAdminMethodologyActiveItem,
  setAdminMethodologyInArchive,
} from '../../../../../store/adminMethodologySlice';
import { ViewSelectorItemType } from '../../../../../types/types';
import classes from './AdminMethodologyPageViewSelector.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Switch } from '@consta/uikit/Switch';
import { Text } from '@consta/uikit/Text';
import { useGetProcesses } from '../../../../../hooks/useGetProcesses';
import { useGetDocuments } from '../../../../../hooks/useGetDocuments';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { TextField } from '@consta/uikit/TextField';
import { DragNDropField } from '@consta/uikit/DragNDropField';
import { IconPhoto } from '@consta/uikit/IconPhoto';
import classNames from 'classnames';
import { Attachment } from '@consta/uikit/Attach';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Modal } from '@consta/uikit/Modal';
import { pushSnackBarItemReports } from '../../../../../store/appSlice';
interface IAdminBlogPageViewSelectorProps {}

const AdminMethodologyPageViewSelector: React.FC<IAdminBlogPageViewSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectorMethodologyViewSelectorActiveItem);
  const [inArchive, setInArchive] = useState<boolean>(false);
  const inArchiveStore = useAppSelector(selectorMethodologyViewSelectorInArchive);
  const staticData = useAppSelector(selectorAdminMethodologyStaticData);
  const [stepsModalIsOpen, setStepsModalIsOpen] = useState<boolean>(false);
  const [confluenceModalIsOpen, setConfluenceModalIsOpen] = useState<boolean>(false);
  const [backlogModalIsOpen, setBacklogModalIsOpen] = useState<boolean>(false);
  const [backlogUrl, setBacklogUrl] = useState<string | undefined>();
  const [step1, setStep1] = useState<string | null>();
  const [step2, setStep2] = useState<string | null>();
  const [step3, setStep3] = useState<string | null>();
  const [confluence, setConfluence] = useState<string | null>();
  const [backlog, setBackLog] = useState<File | null>();
  const onChangeViewSelector = ({ value }: { value: ViewSelectorItemType }) => {
    dispatch(setAdminMethodologyActiveItem(value));
  };

  const getProcesses = useGetProcesses();
  const getDocuments = useGetDocuments();

  const handleChange = useCallback(
    (checked: boolean) => {
      setInArchive(checked);
      dispatch(setAdminMethodologyInArchive(checked));

      if (view?.slug === 'process') {
        getProcesses({ inArchive: checked });
      }
      if (view?.slug === 'documents') {
        getDocuments({ inArchive: checked });
      }
    },
    [view]
  );
  useEffect(() => {
    dispatch(getAdminMethodologyStaticData());
  }, [dispatch]);

  useEffect(() => {
    if (staticData) {
      setStep1(staticData.find((el: any) => el.key === 'new_employee_step_1')?.value);
      setStep2(staticData.find((el: any) => el.key === 'new_employee_step_2')?.value);
      setStep3(staticData.find((el: any) => el.key === 'new_employee_step_3')?.value);
      setConfluence(staticData.find((el: any) => el.key === 'confluence')?.value);
      setBacklogUrl(staticData.find((el: any) => el.key === 'file_backlog')?.value);
    }
  }, [staticData]);

  const saveSteps = useCallback(() => {
    const tmp = {
      new_employee_step_1: step1,
      new_employee_step_2: step2,
      new_employee_step_3: step3,
    };
    dispatch(postStaticStepsThunk(tmp)).then(() => {
      dispatch(getAdminMethodologyStaticData());
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Информация новому сотруднику обновлена`,
          status: 'success',
          autoClose: 5,
        })
      );
      setStepsModalIsOpen(false);
    });
  }, [step3, step2, step1]);

  const saveConfluence = useCallback(() => {
    dispatch(postStaticConfluenceThunk({ confluence: confluence })).then(() => {
      dispatch(getAdminMethodologyStaticData());
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Ссылка на confluence обноновлена`,
          status: 'success',
          autoClose: 5,
        })
      );
      setConfluenceModalIsOpen(false);
    });
  }, [confluence]);

  const saveBacklog = useCallback(() => {
    dispatch(postStaticBacklogThunk({ file_backlog: backlog })).then(() => {
      dispatch(getAdminMethodologyStaticData());
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Ссылка на бэклог обновлена`,
          status: 'success',
          autoClose: 5,
        })
      );
      setBacklogModalIsOpen(false);
    });
  }, [backlog]);

  const removeBackLog = useCallback(() => {
    dispatch(deleteMethodologyBacklog(null)).then(() => {
      dispatch(getAdminMethodologyStaticData());
      setBacklogUrl(undefined);
      setBackLog(null);
      setBacklogModalIsOpen(false);
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Файл бэклога удален!`,
          status: 'success',
          autoClose: 5,
        })
      );
    });
  }, []);

  return (
    <div className={classes.container}>
      <div>
        <ChoiceGroup
          size={'s'}
          value={view}
          onChange={onChangeViewSelector}
          items={ADMIN_METHODOLOGY_PAGE_VIEW_SELECTOR_ITEMS}
          getItemLabel={(item: ViewSelectorItemType) => item.name}
          multiple={false}
          name={'adminMethodologyPageViewSelector'}
        />

        <div className={classes.switch}>
          {<Text className={`${classes.title} ${inArchiveStore ? classes.active : ''}`}>{'В архиве'}</Text>}
          <Switch view="ghost" checked={inArchive} onChange={({ checked }) => handleChange(checked)} />
        </div>
      </div>
      <div>
        <Button
          view={'secondary'}
          size={'s'}
          label={'Новому сотруднику'}
          iconRight={IconEdit}
          onClick={() => setStepsModalIsOpen(true)}
        />
        <Button
          view={'secondary'}
          size={'s'}
          label={'Открыть файл бэклога'}
          iconRight={IconEdit}
          onClick={() => setBacklogModalIsOpen(true)}
        />
        <Button
          view={'secondary'}
          size={'s'}
          label={'Открыть базу знаний'}
          iconRight={IconEdit}
          onClick={() => setConfluenceModalIsOpen(true)}
        />
      </div>
      <Modal
        isOpen={stepsModalIsOpen}
        className={classes.reportsModal}
        hasOverlay
        onClickOutside={() => setStepsModalIsOpen(false)}
        onEsc={() => setStepsModalIsOpen(false)}
      >
        <div className={classes.addSubscribers}>
          <div className={classes.formTitle}>Новому сотруднику</div>
          <div className={classes.formContent}>
            <TextField
              onChange={(value) => setStep1(value.value)}
              label="Шаг 1: Политика"
              value={step1}
              type="text"
              placeholder="https://"
              className={classes.fullWidth}
              required
            />
            <TextField
              onChange={(value) => setStep2(value.value)}
              label="Шаг 2: Обучение"
              value={step2}
              type="text"
              placeholder="https://"
              className={classes.fullWidth}
              required
            />
            <TextField
              onChange={(value) => setStep3(value.value)}
              label="Шаг 3: Процессы"
              value={step3}
              type="text"
              placeholder="https://"
              className={classes.fullWidth}
              required
            />
          </div>
          <div className={classes.formFooter}>
            <Button view={'ghost'} size={'m'} label={'Отмена'} onClick={() => setStepsModalIsOpen(false)} />
            <Button
              view={'primary'}
              size={'m'}
              label={'Сохранить'}
              disabled={!(step1 && step2 && step3)}
              onClick={saveSteps}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={confluenceModalIsOpen}
        className={classes.reportsModal}
        hasOverlay
        onClickOutside={() => setConfluenceModalIsOpen(false)}
        onEsc={() => setConfluenceModalIsOpen(false)}
      >
        <div className={classes.addSubscribers}>
          <div className={classes.formTitle}>Confluence</div>
          <div className={classes.formContent}>
            <TextField
              onChange={(value) => setConfluence(value.value)}
              label="Ссылка на Conflueunce"
              value={confluence}
              type="text"
              placeholder="https://"
              className={classes.fullWidth}
              required
            />
          </div>
          <div className={classes.formFooter}>
            <Button view={'ghost'} size={'m'} label={'Отмена'} onClick={() => setConfluenceModalIsOpen(false)} />
            <Button view={'primary'} size={'m'} label={'Сохранить'} disabled={!confluence} onClick={saveConfluence} />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={backlogModalIsOpen}
        className={classes.reportsModal}
        hasOverlay
        onClickOutside={() => setBacklogModalIsOpen(false)}
        onEsc={() => setBacklogModalIsOpen(false)}
      >
        <div className={classes.addSubscribers}>
          <div className={classes.formTitle}>Файл бэклога</div>
          <div className={classes.formContent}>
            <div className={classes.formItemTitle}>Файл</div>
            <DragNDropField onDropFiles={(files) => setBackLog(files[0])} className={classes.fullWidth}>
              {({ openFileDialog }) => (
                <>
                  <IconPhoto size={'l'} view={'ghost'} className={classes.photo} />
                  <Text>
                    Перетащите изображение или{' '}
                    <a className={classes.link} onClick={openFileDialog}>
                      загрузите
                    </a>
                  </Text>
                  <Text view="ghost" font="mono">
                    Поддерживаемые форматы: DOC, XLS, PDF
                  </Text>
                </>
              )}
            </DragNDropField>
            {backlog && (
              <div className={classNames([classes.attachment, classes.fullWidth])}>
                <Attachment
                  key={backlog.name}
                  fileName={backlog.name}
                  fileExtension={backlog.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                  fileDescription={backlog.type}
                />
                <IconTrash view={'ghost'} onClick={() => setBackLog(null)} />
              </div>
            )}

            {backlogUrl && (
              <>
                <div className={classes.formItemTitle}>Текущий файл</div>
                <div
                  className={classNames([classes.attachment, classes.fullWidth])}
                  onClick={() => window.open(backlogUrl, '_blank')}
                >
                  <Attachment
                    key={backlogUrl}
                    fileName={backlogUrl}
                    fileExtension={backlogUrl.match(/\.(?!.*\.)(\w*)/)?.[1]}
                  />
                </div>
              </>
            )}
          </div>
          <div className={`${classes.formFooter} ${classes.withDelete}`}>
            <div>
              {backlogUrl && (
                <Button
                  view={'primary'}
                  size={'m'}
                  iconRight={IconTrash}
                  label={'Удалить'}
                  className={classes.remove}
                  onClick={() => removeBackLog()}
                />
              )}
            </div>
            <div>
              <Button view={'ghost'} size={'m'} label={'Отмена'} onClick={() => setBacklogModalIsOpen(false)} />
              <Button view={'primary'} size={'m'} label={'Сохранить'} disabled={!backlog} onClick={saveBacklog} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminMethodologyPageViewSelector;
