import React from 'react';

import './constaFix.css';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { useGetBlogItems } from '../../../../../../hooks/useGetBlogItems';
import {
  selectorViewSelectorActiveItem,
  selectorViewSelectorActiveSortingId,
  selectorViewSelectorBlogItems,
  setAdminBlogActiveSortingId,
} from '../../../../../../store/adminBlogSlice';
import { SORTING_ITEMS } from './SORTING_ITEMS';
import { SORTING_ITEMS_DIGEST } from './SORTING_ITEMS_DIGEST';
import classes from './SortingSelector.module.css';
import { cn } from '@bem-react/classname';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import { useGetDigestItems } from '../../../../../../hooks/useGetDigestItems';
import { SORTING_ITEMS_SUGGESTION } from './SORTING_ITEMS_SUGGESTION';
import { useGetSuggestionItems } from '../../../../../../hooks/useGetSuggestionItems';
import { SORTING_ITEMS_SUBSCRIPTION } from './SORTING_ITEMS_SUBSCRIPTION';
import { useGetSubscriptionItems } from '../../../../../../hooks/useGetSubscriptionItems';

export type SortingItemType = {
  id: number;
  label: string;
  ico: React.ReactNode;
  icoItem: React.ReactNode;
  sortParams: string;
};

const cnComboboxExampleRenderItem = cn('ComboboxExampleRenderItem');

const SortingSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const getBlogItems = useGetBlogItems();
  const getDigest = useGetDigestItems();
  const getSuggestionItems = useGetSuggestionItems();
  const getSubscriptionItems = useGetSubscriptionItems();

  const activeSortingId = useAppSelector(selectorViewSelectorActiveSortingId);
  const view = useAppSelector(selectorViewSelectorActiveItem);
  let activeSorting;
  if (view?.slug === 'news') {
    activeSorting = SORTING_ITEMS.find((item) => item.id === activeSortingId);
  } else if (view?.slug === 'digest') {
    activeSorting = SORTING_ITEMS_DIGEST.find((item) => item.id === activeSortingId);
  } else if (view?.slug === 'suggestion') {
    activeSorting = SORTING_ITEMS_SUGGESTION.find((item) => item.id === activeSortingId);
  } else if (view?.slug === 'subscription') {
    activeSorting = SORTING_ITEMS_SUBSCRIPTION.find((item) => item.id === activeSortingId);
  }

  const pagination = useAppSelector(selectorViewSelectorBlogItems)?.meta;

  const changeHandler = (activeSortingItem: SortingItemType | null) => {
    if (activeSortingItem) {
      dispatch(setAdminBlogActiveSortingId(activeSortingItem.id));
      if (view?.slug === 'news') {
        getBlogItems({
          sortingItem: activeSortingItem,
          queryPagination: `page[size]=${pagination?.per_page ? pagination?.per_page : 25}&page[number]=1`,
        });
      } else if (view?.slug === 'digest') {
        getDigest({
          sortingItem: activeSortingItem,
          queryPagination: `page[size]=${pagination?.per_page ? pagination?.per_page : 25}&page[number]=1`,
        });
      } else if (view?.slug === 'suggestion') {
        getSuggestionItems({
          sortingItem: activeSortingItem,
          queryPagination: `page[size]=${pagination?.per_page ? pagination?.per_page : 25}&page[number]=1`,
        });
      } else if (view?.slug === 'subscription') {
        getSubscriptionItems({
          sortingItem: activeSortingItem,
          queryPagination: `page[size]=${pagination?.per_page ? pagination?.per_page : 25}&page[number]=1`,
        });
      }
    }
  };

  return (
    <div className={classes.container}>
      <Combobox
        placeholder="Выберите вариант"
        items={
          view?.slug === 'news'
            ? SORTING_ITEMS
            : view?.slug === 'digest'
            ? SORTING_ITEMS_DIGEST
            : view?.slug === 'suggestion'
            ? SORTING_ITEMS_SUGGESTION
            : SORTING_ITEMS_SUBSCRIPTION
        }
        value={activeSorting}
        onChange={(item) => changeHandler(item)}
        size={'s'}
        renderItem={({ item, active, hovered, onClick, onMouseEnter, ref }) => (
          <div
            className={cnComboboxExampleRenderItem('Item', { active, hovered })}
            aria-selected={active}
            aria-hidden="true"
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            ref={ref}
            style={{ display: 'flex', paddingLeft: '8px', fontSize: '14px', margin: '6px 0' }}
          >
            {item.icoItem} {item.label}
          </div>
        )}
        renderValue={({ item }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.ico} {item.label}
          </div>
        )}
        className={`${classes.combobox} without-close`}
      />
    </div>
  );
};

export default SortingSelector;
