import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import LeftSideBar, { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { ReportsDictionaryItemType, ReportsDictionaryType } from '../../../types/types';
import { formatReportsFilterToString, getFilterFromUrl } from '../../../utils/formatReportsFilterToString';
import SearchContainer from '../../SearchContainer/SearchContainer';
import ReportsForm from '../ReportsForm/ReportsForm';
import FilterCombobox from './filterCombobox/FilterCombobox';
import FilterRadio from './FilterRadio/FilterRadio';
import classes from './ReportsFilter.module.css';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { Modal } from '@consta/uikit/Modal';
import { Tag } from '@consta/uikit/Tag';
import classNames from 'classnames';
import { useAppSelector } from '../../../hooks/hooks';
import { selectorIsAdminReports } from '../../../store/appSlice';

type FilterType = {
  blocks: ReportsDictionaryItemType[];
  systems: ReportsDictionaryItemType[];
  dataServices: ReportsDictionaryItemType[];
  hasAccess: ReportsDictionaryItemType[];
  searchString: string;
};

const ReportsFilter: React.FC<{
  setQueryString: (query: string) => void;
  refetch: () => void;
  dictionaries: ReportsDictionaryType | any;
}> = ({ setQueryString, refetch, dictionaries }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdminReports = useAppSelector(selectorIsAdminReports);
  const [filter, setFilter] = useState<FilterType | any>({
    blocks: [],
    systems: [],
    dataServices: [],
    hasAccess: [],
    searchString: '',
  });

  useEffect(() => {
    setFilter(getFilterFromUrl(searchParams, dictionaries));
  }, [searchParams, dictionaries]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [tags, setTags] = useState<{ value: string; code: string }[]>([]);
  const refButton = useRef<HTMLButtonElement>(null);
  const refSidebar = useRef<LeftSideBarHandleType>(null);
  const openSidebarClick = () => {
    refSidebar.current?.openSidebar();
  };

  const getFilterTags = useCallback(() => {
    const tags: { value: string; code: string }[] = [];
    Object.keys(filter).forEach((el) => {
      if (el === 'searchString' && filter[el]) {
        tags.push({ code: 'searchString', value: filter[el] });
      } else {
        if (filter[el]) {
          filter[el].forEach((item: ReportsDictionaryItemType) => {
            tags.push({ code: el, value: item.name });
          });
        }
      }
    });
    setTags(tags);
  }, [filter]);

  const setFilterValue = useCallback(
    (value: ReportsDictionaryItemType[] | string | null | [], name: string) => {
      filter[name] = value;
      // setFilter(filter);
      getFilterTags();
      setQueryString(formatReportsFilterToString(filter));
      const params = formatReportsFilterToString(filter);
      setSearchParams(params);
    },
    [filter, setQueryString, getFilterTags, setSearchParams]
  );

  useEffect(() => {
    getFilterTags();
  }, [filter, getFilterTags]);

  const onCancel = (value: string, code: string) => {
    if (code === 'searchString') {
      setFilterValue('', 'searchString');
    } else {
      setFilterValue(
        filter[code].filter((item: ReportsDictionaryItemType) => item.name !== value),
        code
      );
    }
  };
  const cancelAll = () => {
    setFilterValue('', 'searchString');
    setFilterValue([], 'blocks');
    setFilterValue([], 'dataServices');
    setFilterValue([], 'systems');
    setFilterValue([], 'hasAccess');
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterRow}>
        <div>
          {isAdminReports && (
            <>
              <Button size="m" view="primary" iconRight={IconAdd} label="Добавить" onClick={() => setIsOpen(true)} />
              <Modal
                isOpen={isOpen}
                className={classes.reportsModal}
                hasOverlay
                // onClickOutside={() => setIsOpen(false)}
                onEsc={() => setIsOpen(false)}
              >
                <ReportsForm onCancel={() => setIsOpen(false)} dictionaries={dictionaries} refetch={() => refetch()} />
              </Modal>
            </>
          )}
          <SearchContainer
            value={searchString}
            size="m"
            placeHolder={'Введите содержание'}
            setFilterValue={() => setFilterValue('', 'searchString')}
            onSearch={(value) => {
              setSearchString(value);
              setFilterValue(value, 'searchString');
            }}
          />
        </div>
        <Button
          size="m"
          view="ghost"
          iconLeft={IconFunnel}
          label="Фильтры"
          ref={refButton}
          onClick={openSidebarClick}
        />
      </div>
      <div className={classes.tagsRow}>
        {tags?.length > 0 &&
          tags.map((el: any, ind) => (
            <Tag
              key={ind}
              size={'s'}
              mode="cancel"
              onCancel={() => {
                onCancel(el.value, el.code);
              }}
              label={el.value}
              className={classes.tag}
            />
          ))}
        {tags?.length > 0 && (
          <Tag
            size={'s'}
            mode="cancel"
            onCancel={() => {
              cancelAll();
            }}
            label={'Сбросить все'}
            className={classNames([classes.tag, classes.tagFlat])}
          />
        )}
      </div>
      <LeftSideBar ref={refSidebar} refButton={refButton}>
        <div className={classes.sidebarFilter}>
          <div className={classes.sidebarTitle}>
            <div>Фильтры</div>
            <Button view="ghost" size="s" label="Сбросить фильтры" onClick={() => cancelAll()} />
          </div>
          <FilterRadio
            items={dictionaries.blocks}
            title={'Наличие доступа'}
            currentFilter={filter?.hasAccess}
            setFilterValue={setFilterValue}
            code={'hasAccess'}
          />
          {dictionaries.blocks && (
            <FilterCombobox
              items={dictionaries.blocks}
              title={'Блок'}
              currentFilter={filter?.blocks}
              setFilterValue={setFilterValue}
              code={'blocks'}
            />
          )}
          {dictionaries.systems && (
            <FilterCombobox
              items={dictionaries.systems}
              title={'Система'}
              currentFilter={filter?.systems}
              setFilterValue={setFilterValue}
              code={'systems'}
            />
          )}
          {dictionaries.dataServices && (
            <FilterCombobox
              items={dictionaries.dataServices}
              title={'Дата-сервисы'}
              currentFilter={filter?.dataServices}
              setFilterValue={setFilterValue}
              code={'dataServices'}
            />
          )}
        </div>
      </LeftSideBar>
    </div>
  );
};

export default ReportsFilter;
