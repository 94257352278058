import React, { useCallback, useEffect, useState } from 'react';

import Chichikina_AN from '../../../img/avatars/Chichikina_AN.jpg';
import Delakova_EA from '../../../img/avatars/Delakova_EA.jpg';
import connectionIco from '../../../img/blogPage/connectionIco.svg';
import classes from './Contacts.module.css';
import { Text } from '@consta/uikit/Text';
import { Avatar } from '@consta/uikit/Avatar';
import { Modal } from '@consta/uikit/Modal';
import ContactsForm from '../../../Components/ContactsForm/ContactsForm';
import { getContactsByContentBlockSlug, selectorContactsByContentBlockSlug } from '../../../store/contactsSlice';
import { IconEdit } from '@consta/uikit/IconEdit';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { selectorIsAdminContacts } from '../../../store/appSlice';

const Contacts: React.FC = () => {
  const isAdminContacts = useAppSelector(selectorIsAdminContacts);
  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const onWriteToEmailUPD = () => {
    window.open('mailto:upd@gazprom-neft.ru?subject=Вопрос по разделу Блог', '_blank', 'noreferrer');
  };

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectorContactsByContentBlockSlug);
  const getUsers = useCallback(() => {
    dispatch(getContactsByContentBlockSlug('blog'));
  }, [dispatch]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div className={classes.container}>
      <Text className={classes.title}>
        {`Контакты`}
        {isAdminContacts && (
          <IconEdit size={'m'} view={'primary'} className={classes.iconEdit} onClick={() => setIsModalShow(true)} />
        )}
      </Text>
      <div className={classes.row}>
        <div className={classes.leftColumn}>
          <Text className={classes.team}>{`Команда коммуникаций`}</Text>
          <Text className={classes.post}>{`По вопросам пишите нам на почту`}</Text>
          <Text className={classes.email} onClick={onWriteToEmailUPD}>{`UPD@gazprom-neft.ru`}</Text>
          <div className={classes.rowResources}>
            <img src={connectionIco} alt="connectionIco" />
            <Text className={classes.resources}>{`Ресурсы`}</Text>
          </div>
          <Text className={classes.mobile}>{`Мобильная лента`}</Text>
        </div>
        <Modal
          isOpen={isModalShow}
          className={classes.contactsModal}
          hasOverlay
          onClickOutside={() => setIsModalShow(false)}
          onEsc={() => setIsModalShow(false)}
        >
          <ContactsForm
            contacts={users}
            close={() => setIsModalShow(false)}
            afterDispatch={() => dispatch(getContactsByContentBlockSlug('blog'))}
          />
        </Modal>
        {users &&
          users.contacts &&
          users.contacts.length &&
          users.contacts.map((user: any, ind) => (
            <div className={classes.card} key={ind}>
              <Avatar
                className={classes.imgAvatar}
                url={user.person?.icon ? 'data:image/png;base64,' + user.person?.icon : ''}
                name={user.person?.last_name + ' ' + user.person?.first_name + ' ' + user.person?.middle_name}
              />
              <Text className={classes.name}>
                {user.person?.last_name + ' ' + user.person?.first_name + ' ' + (user.person?.middle_name || '')}
              </Text>
              <Text className={classes.position}>{user.position}</Text>
              <Text
                className={classes.email}
                onClick={() => window.open(`mailto:${user.person?.email}`, '_blank', 'noreferrer')}
              >
                {user.person?.email}
              </Text>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Contacts;
