import { PageRoutesEnum } from '../../appMenuAndRouting/appRoutes';
import { MenuItemType } from '../../shared-front/types/types';

export const getMenuWithPermission = (
  menuItems: MenuItemType[],
  isAdminBlog: boolean,
  isAdminMetric: boolean,
  isAdminMethodology: boolean
) => {
  const tmp = [];
  if (isAdminBlog) {
    tmp.push({ label: 'Блог', href: PageRoutesEnum.Admin_Blog });
  }
  if (isAdminMetric) {
    tmp.push({ label: 'Метрики', href: PageRoutesEnum.Admin_Metrics });
  }

  if (isAdminMethodology) {
    tmp.push({ label: 'Методология', href: PageRoutesEnum.Admin_Methodology });
  }
  if (isAdminMetric || isAdminBlog) {
    return [
      ...menuItems,
      {
        label: 'Администрирование',
        href: PageRoutesEnum.Admin,
        children: tmp,
      },
    ];
  }
  return menuItems;
};
