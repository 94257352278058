import React, { useCallback, useEffect, useState } from 'react';

import classes from './ProcessFilterFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { getBlogTagsDictionary, selectorBlogTagsDictionary } from '../../../../../../store/blogSlice';
import { useGetBlogItems } from '../../../../../../hooks/useGetBlogItems';
import { useGetProcesses } from '../../../../../../hooks/useGetProcesses';
import UserSelect from '../../../../../../Components/ContactsForm/UserSelect/UserSelect';
import { setAdminMethodologyFilter } from '../../../../../../store/adminMethodologySlice';

type Item = {
  label: string;
  id: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const ProcessFilterFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const getProcesses = useGetProcesses();
  const [center, setCenter] = useState<any | null>();
  const [owner, setOwner] = useState<any | null>();

  const setFilter = useCallback(() => {
    const tmp: string[] = [];
    if (owner) {
      tmp.push(`filter[uuid_process_owner]=${owner.id}`);
    }
    if (center) {
      tmp.push(`filter[uuid_competence_center]=${center.id}`);
    }
    dispatch(setAdminMethodologyFilter(tmp.join('&')));
    getProcesses({ filterValue: tmp.join('&') });
  }, [owner, center]);

  const clearFilter = useCallback(() => {
    setOwner(undefined);
    setCenter(undefined);
  }, [setOwner, setCenter]);

  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Фильтры</div>
        <Button view={'ghost'} label={'Сбросить фильтры'} size={'s'} onClick={clearFilter} />
      </div>

      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Владелец процесса{' '}
          <span className={classes.clear} onClick={() => setOwner(undefined)}>
            Сбросить
          </span>
        </div>

        <UserSelect
          initValue={owner}
          onChangeInit={(value) => {
            setOwner(value);
          }}
        />
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Центр компетенция по стандартизации{' '}
          <span className={classes.clear} onClick={() => setCenter(undefined)}>
            Сбросить
          </span>
        </div>

        <UserSelect
          initValue={center}
          onChangeInit={(value) => {
            setCenter(value);
          }}
        />
      </div>

      <div className={classes.bottomButtons}>
        <div></div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button size="m" view="primary" form={'default'} label={'Применить'} onClick={setFilter} />
        </div>
      </div>
    </div>
  );
};

export default ProcessFilterFormComponent;
