import React from 'react';

import { SortingItemType } from './SortingSelector';
import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconSortUp } from '@consta/uikit/IconSortUp';

export const SORTING_ITEMS_SUBSCRIPTION: SortingItemType[] = [
  {
    id: 1,
    label: 'По алфавиту А-Я',
    ico: <IconSortDown view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortDown size={'s'} style={{ marginRight: '10px' }} />,
    sortParams: 'sort=fio',
  },
  {
    id: 2,
    label: 'По алфавиту Я-А',
    ico: <IconSortUp view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortUp size={'s'} style={{ marginRight: '10px' }} />,
    sortParams: 'sort=-fio',
  },
];
