import React from 'react';

import teamJson from '../../../../../templateContent/command/team.json';
import { UserType } from '../../../../../types/types';
import { getMaxUsersVerticalLength } from '../../../../../utils/functions';
import TeamCardsVertical from '../../TeamCardsVertical/TeamCardsVertical';
import classes from './SectionContent.module.css';

type PropsType = {
  users: UserType[][];
  openSidebarClick: () => void;
  openConfluenceSidebar: () => void;
  domain: { label: string; id: string }[];
  division: { label: string; id: string };
  colors: string[];
};

const SectionContent: React.FC<PropsType> = ({
  users,
  openSidebarClick,
  openConfluenceSidebar,
  domain,
  division,
  colors,
}) => {
  const verticalColors = teamJson.team.map((vertical) => vertical.verticalColor);
  const usersLength = getMaxUsersVerticalLength(users);

  return (
    <div className={classes.container}>
      {users.map((verticalUsers, ind) => (
        <div key={ind} className={classes.verticalContainer}>
          <TeamCardsVertical
            usersLength={usersLength}
            users={verticalUsers}
            verticalColor={colors[ind]}
            domain={domain[ind]}
            division={division}
            openSidebarClick={openSidebarClick}
            openConfluenceSidebar={openConfluenceSidebar}
          />
        </div>
      ))}
    </div>
  );
};

export default SectionContent;
