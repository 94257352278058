import React from 'react';

import { BlogNewsDetailType } from '../../../../api/blogServerResponse';
import classes from './BlogContentNewsMain.module.css';
import LeftColumn from './LeftColumn/LeftColumn';
import RightColumn from './RightColumn/RightColumn';

interface IBlogContentNewsMainProps {
  news?: BlogNewsDetailType;
  updateNews: () => void;
}

const BlogContentNewsMain: React.FC<IBlogContentNewsMainProps> = ({ news, updateNews }) => {
  return (
    <div className={classes.container}>
      <LeftColumn updateNews={updateNews} news={news} />
      <RightColumn news={news} />
    </div>
  );
};

export default BlogContentNewsMain;
