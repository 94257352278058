import React, { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS,
  selectorViewSelectorActiveItem,
  selectorViewSelectorInArchive,
  setAdminBlogInArchive,
  setViewSelectorActiveItem,
} from '../../../../../store/adminBlogSlice';
import { ViewSelectorItemType } from '../../../../../types/types';
import classes from './AdminBlogPageViewSelector.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Switch } from '@consta/uikit/Switch';
import { Text } from '@consta/uikit/Text';
import { useGetBlogItems } from '../../../../../hooks/useGetBlogItems';
import { useGetDigestItems } from '../../../../../hooks/useGetDigestItems';
import { useGetSuggestionItems } from '../../../../../hooks/useGetSuggestionItems';
interface IAdminBlogPageViewSelectorProps {}

const AdminBlogPageViewSelector: React.FC<IAdminBlogPageViewSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectorViewSelectorActiveItem);
  const [inArchive, setInArchive] = useState<boolean>(false);
  const inArchiveStore = useAppSelector(selectorViewSelectorInArchive);
  const onChangeViewSelector = ({ value }: { value: ViewSelectorItemType }) => {
    dispatch(setViewSelectorActiveItem(value));
  };

  const getBlogItems = useGetBlogItems();
  const getDigestItems = useGetDigestItems();
  const getSuggestionItems = useGetSuggestionItems();

  const handleChange = useCallback(
    (checked: boolean) => {
      setInArchive(checked);
      dispatch(setAdminBlogInArchive(checked));

      if (view?.slug === 'news') {
        getBlogItems({ inArchive: checked });
      } else if (view?.slug === 'digest') {
        getDigestItems({ inArchive: checked });
      } else if (view?.slug === 'suggestion') {
        getSuggestionItems({ inArchive: checked });
      }
    },
    [view]
  );
  return (
    <div className={classes.container}>
      <ChoiceGroup
        size={'s'}
        value={view}
        onChange={onChangeViewSelector}
        items={ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS}
        getItemLabel={(item: ViewSelectorItemType) => item.name}
        multiple={false}
        name={'adminBlogPageViewSelector'}
      />

      {view?.slug !== 'subscription' && (
        <div className={classes.switch}>
          {<Text className={`${classes.title} ${inArchiveStore ? classes.active : ''}`}>{'В архиве'}</Text>}
          <Switch view="ghost" checked={inArchive} onChange={({ checked }) => handleChange(checked)} />
        </div>
      )}
    </div>
  );
};

export default AdminBlogPageViewSelector;
