import React from 'react';

import ProductPage from '../../Components/ProductPage/ProductPage';
import backgroundImage from '../../img/pageBanners/dwh.png';
import templateContent from '../../templateContent/dataPlatformProducts.json';
import { ProductType } from '../../types/types';

const product: ProductType = templateContent.products[0];
const slug: string = templateContent.products[0].slug;
const DataStoragePage: React.FC = () => {
  return <ProductPage product={product} slug={slug} backgroundImage={backgroundImage} />;
};

export default DataStoragePage;
