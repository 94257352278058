import React, { useCallback, useState } from 'react';

import { BlogItemType } from '../../../../../../types/types';
import classes from './AdminDigestActions.module.css';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconStorage } from '@consta/uikit/IconStorage';
import { IconTrash } from '@consta/uikit/IconTrash';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  archiveDigestThunk,
  deleteDigestThunk,
  getAdminBlogDigestThunk,
  postDigestThunk,
  selectorDigestStatuses,
  selectorViewSelectorInArchive,
  updateDigestThunk,
} from '../../../../../../store/adminBlogSlice';
import BlogModalDelete from '../../../BlogModalDelete/BlogModalDelete';
import { pushSnackBarItemReports } from '../../../../../../store/appSlice';
import { IconRevert } from '@consta/uikit/IconRevert';
import { useGetDigestItems } from '../../../../../../hooks/useGetDigestItems';
import { Modal } from '@consta/uikit/Modal';
import { TextField } from '@consta/uikit/TextField';
import { DragNDropField } from '@consta/uikit/DragNDropField';
import { IconPhoto } from '@consta/uikit/IconPhoto';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { Attachment } from '@consta/uikit/Attach';

interface IAdminBlogTableActionsProps {
  item: any;
}

const AdminDigestTableActions: React.FC<IAdminBlogTableActionsProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const getDigest = useGetDigestItems();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [digestModalIsOpen, setDigestModalIsOpen] = useState<boolean>(false);
  const inArchive = useAppSelector(selectorViewSelectorInArchive);
  const [digestName, setDigestName] = useState<string | null>(item.name);
  const [cover, setCoverValue] = useState<File | null>();
  const [file, setFileValue] = useState<File | null>();
  const digestStatuses = useAppSelector(selectorDigestStatuses);
  const onDelete = (uuid: string) => {
    dispatch(deleteDigestThunk(item._uuid)).then(() => {
      setIsOpenDeleteModal(false);
      dispatch(getAdminBlogDigestThunk()).then();
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Дайджест удален!`,
          status: 'success',
          autoClose: 5,
        })
      );
    });
  };

  const addDigest = useCallback(
    (status: string) => {
      const tmp: any = digestStatuses.find((el: any) => el.slug === status);
      if (tmp) {
        const digestData = {
          name: digestName,
          file: file || item.file_params.uuid,
          cover: cover || item.cover_params.uuid,
          uuid_dict_status: tmp.uuid,
          _method: 'PUT',
        };
        const data = {
          data: digestData,
          uuid: item._uuid,
        };
        dispatch(updateDigestThunk(data)).then(() => {
          dispatch(getAdminBlogDigestThunk());
          setDigestModalIsOpen(false);
          dispatch(
            pushSnackBarItemReports({
              key: Math.random(),
              message: `Дайджест обновлен!`,
              status: 'success',
              autoClose: 5,
            })
          );
        });
      }
    },
    [digestStatuses, file, cover, digestName, item]
  );

  const onArchive = () => {
    dispatch(archiveDigestThunk(item._uuid)).then((data) => {
      getDigest({});
    });
  };

  return (
    <div className={classes.container}>
      <Button size="xs" view="ghost" iconLeft={IconEdit} onlyIcon onClick={() => setDigestModalIsOpen(true)} />
      {!inArchive && <Button size="xs" view="ghost" iconLeft={IconStorage} onlyIcon onClick={onArchive} />}
      {inArchive && <Button size="xs" view="ghost" iconLeft={IconRevert} onlyIcon onClick={onArchive} />}
      <Button size="xs" view="ghost" iconLeft={IconTrash} onlyIcon onClick={() => setIsOpenDeleteModal(true)} />
      <BlogModalDelete
        isModalOpen={isOpenDeleteModal}
        setIsModalOpen={setIsOpenDeleteModal}
        modalDeleteTitle={'Вы действительно хотите удалить дайджест?'}
        onConfirmDelete={() => onDelete(item._uuid)}
      />
      <Modal
        isOpen={digestModalIsOpen}
        className={classes.reportsModal}
        hasOverlay
        onClickOutside={() => setDigestModalIsOpen(false)}
        onEsc={() => setDigestModalIsOpen(false)}
      >
        <div className={classes.addSubscribers}>
          <div className={classes.formTitle}>Добавление дайджеста</div>
          <div className={classes.formContent}>
            <TextField
              onChange={(value) => setDigestName(value.value)}
              label="Название"
              value={digestName}
              type="text"
              placeholder="Введите название"
              className={classes.fullWidth}
            />

            <div className={classes.formItemTitle}>Обложка</div>
            <DragNDropField onDropFiles={(files) => setCoverValue(files[0])} className={classes.fullWidth}>
              {({ openFileDialog }) => (
                <>
                  <IconPhoto size={'l'} view={'ghost'} className={classes.photo} />
                  <Text>
                    Перетащите изображение или{' '}
                    <a className={classes.link} onClick={openFileDialog}>
                      загрузите
                    </a>
                  </Text>
                  <Text view="ghost" font="mono">
                    Поддерживаемые форматы: PNG, TIFF, JPG
                  </Text>
                </>
              )}
            </DragNDropField>
            {cover && (
              <div className={classNames([classes.attachment, classes.fullWidth])}>
                <Attachment
                  key={cover.name}
                  fileName={cover.name}
                  fileExtension={cover.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                  fileDescription={cover.type}
                />
                <IconTrash view={'ghost'} onClick={() => setCoverValue(null)} />
              </div>
            )}
            {!cover && item?.cover && (
              <div className={classNames([classes.attachment, classes.fullWidth])}>
                <img src={item?.cover} alt={'Обложка'} onClick={() => window.open(item.cover)} />
              </div>
            )}
            <div className={classes.formItemTitle}>Файл</div>
            <DragNDropField onDropFiles={(files) => setFileValue(files[0])} className={classes.fullWidth}>
              {({ openFileDialog }) => (
                <>
                  <IconPhoto size={'l'} view={'ghost'} className={classes.photo} />
                  <Text>
                    Перетащите изображение или{' '}
                    <a className={classes.link} onClick={openFileDialog}>
                      загрузите
                    </a>
                  </Text>
                  <Text view="ghost" font="mono">
                    Поддерживаемые форматы: DOC, XLS, PDF
                  </Text>
                </>
              )}
            </DragNDropField>
            {file && (
              <div className={classNames([classes.attachment, classes.fullWidth])}>
                <Attachment
                  key={file.name}
                  fileName={file.name}
                  fileExtension={file.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                  fileDescription={file.type}
                />
                <IconTrash view={'ghost'} onClick={() => setCoverValue(null)} />
              </div>
            )}
            {!file && item.file && (
              <div className={classNames([classes.attachment, classes.fullWidth])}>
                <a href={item.file} target="_blank">
                  Файл
                </a>
              </div>
            )}
          </div>
          <div className={classes.formFooter}>
            <Button view={'ghost'} size={'m'} label={'Сохранить'} onClick={() => addDigest('draft')} />
            <Button view={'primary'} size={'m'} label={'Опубликовать'} onClick={() => addDigest('done')} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminDigestTableActions;
