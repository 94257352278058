import { ComboboxItemType, UserType } from '../types/types';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    // behavior: 'smooth',
  });
};

export const getGroupsTitles = (verticalUsers: UserType[][]) => {
  const groupTitles: string[] = [];
  verticalUsers.forEach((users) => {
    users.forEach((user) => {
      if (user.sectionTitle && !groupTitles.includes(user.sectionTitle)) {
        groupTitles.push(user.sectionTitle);
      }
    });
  });

  return groupTitles;
};

const getUsersByGroupTitle = (groupsTitle: string, verticalUsers: UserType[][]) => {
  const groupedUsers: UserType[][] = [];
  verticalUsers.forEach((users) => {
    const verticalUsers: UserType[] = [];
    users.forEach((user) => {
      if (user.sectionTitle === groupsTitle) {
        verticalUsers.push(user);
      }
    });
    groupedUsers.push(verticalUsers);
  });
  return groupedUsers;
};

export const getGroupsUsers = (groupsTitles: string[], verticalUsers: UserType[][]) => {
  const groupsUsers: UserType[][][] = [];
  groupsTitles.forEach((title) => {
    groupsUsers.push(getUsersByGroupTitle(title, verticalUsers));
  });
  return groupsUsers;
};

export const getMaxUsersVerticalLength = (users: UserType[][]) => {
  let maxLength = 0;
  users.forEach((verticalUsers) => {
    if (verticalUsers.length > maxLength) {
      maxLength = verticalUsers.length;
    }
  });
  return maxLength;
};

export const getAvatarUrl = (avatarFileName: string | undefined) => {
  try {
    if (avatarFileName) {
      return require(`../img/avatars/${avatarFileName}`);
    }
  } catch (e: any) {}
  return undefined;
};

export const getComboboxItems = (values: string[]): ComboboxItemType[] => {
  return values.map((value, ind) => ({ id: ind, label: value }));
};

export const getTableFilters = (values: string[], fieldName: string) => {
  return values.map((value) => ({
    id: `${value}`,
    name: `${value}`,
    filterer: (curVal: string) => curVal === value,
    field: fieldName as any,
  }));
};

export const getShortName = (fullName: string) => {
  const nameParts = fullName.split(' ');
  let shortName = nameParts[0];
  if (nameParts[1]) {
    shortName += ` ${nameParts[1].slice(0, 1)}.`;
  }
  if (nameParts[2]) {
    shortName += ` ${nameParts[2].slice(0, 1)}.`;
  }
  return shortName;
};
