import React from 'react';

import { ProductType } from '../../types/types';
import SectionProductComponents from '../SectionProductComponents/SectionProductComponents';
import SectionProductHero from '../SectionProductHero/SectionProductHero';
import SectionProductInfoLinks from '../SectionProductInfoLinks/SectionProductInfoLinks';
import SectionProductTeam from '../SectionProductTeam/SectionProductTeam';
import classes from './ProductPage.module.css';

type PropsType = {
  product: ProductType;
  backgroundImage: string;
  slug: string;
};

const ProductPage: React.FC<PropsType> = ({ product, backgroundImage, slug }) => {
  const hasInfoLinks = product.infoLinks && product.infoLinks.length > 0;
  const hasTeam = product.team && product.team.length > 0;
  const hasComponents = product.components && product.components.length > 0;

  return (
    <div className={classes.container}>
      <SectionProductHero
        productInfo={product}
        backgroundImage={backgroundImage}
        parentName={'Дата-Платформа'}
        parentLink={'/data-platform'}
      />
      {hasComponents && <SectionProductComponents components={product.components || []} />}
      {hasTeam && <SectionProductTeam slug={slug} team={product.team} />}
      {hasInfoLinks && <SectionProductInfoLinks infoLinks={product.infoLinks || []} />}
    </div>
  );
};

export default ProductPage;
