import React, { useCallback, useEffect, useState } from 'react';

import { BlockContactsType, TeamGroupType } from '../../../types/types';
import PersonalInfoCard from '../../PersonalInfoCard/PersonalInfoCard';
import classes from './ProductTeamList.module.css';
import { Button } from '@consta/uikit/Button';
import {
  getContactsByContentBlockSlug,
  getContactsPGByContentBlockSlug,
  getContactsWGByContentBlockSlug,
  selectorContactsByContentBlockSlug,
  selectorContactsPGByContentBlockSlug,
  selectorContactsWGByContentBlockSlug,
} from '../../../store/contactsSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { selectorIsAdminContacts } from '../../../store/appSlice';
import { IconEdit } from '@consta/uikit/IconEdit';
import { Modal } from '@consta/uikit/Modal';
import ContactsForm from '../../ContactsForm/ContactsForm';

type PropsType = {
  team?: TeamGroupType[];
  slug: string;
};

const ProductTeamList: React.FC<PropsType> = ({ team, slug }) => {
  const dispatch = useAppDispatch();
  const [isModalProductShow, setIsModalProductShow] = useState<boolean>(false);
  const [isModalWorkShow, setIsModalWorkShow] = useState<boolean>(false);
  const isAdminContacts = useAppSelector(selectorIsAdminContacts);
  const [collapsed, setCollapse] = useState(false);
  const productGroup = useAppSelector(selectorContactsPGByContentBlockSlug);
  const workGroup = useAppSelector(selectorContactsWGByContentBlockSlug);
  let pg = '';
  let wg = '';
  if (slug === 'data_warehouse') {
    pg = 'dwh_product_team';
    wg = 'dwh_business_team';
  } else if (slug === 'quality') {
    pg = 'dq_product_team';
    wg = 'dq_business_team';
  } else if (slug === 'dict') {
    pg = 'nsi_product_team';
    wg = 'nsi_business_team';
  } else if (slug === 'catalog') {
    pg = 'catalog_product_team';
    wg = 'catalog_business_team';
  } else if (slug === 'bi') {
    pg = 'bi_product_team';
    wg = 'bi_business_team';
  } else if (slug === 'lab') {
    pg = 'laboratory_product_team';
    wg = 'laboratory_business_team';
  }
  const getUsers = useCallback(async () => {
    dispatch(getContactsPGByContentBlockSlug(pg));
    dispatch(getContactsWGByContentBlockSlug(wg));
  }, [dispatch]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);
  return (
    <div className={classes.container}>
      <div className={classes.teamGroupContainer}>
        <div className={classes.groupTitle}>
          Продуктовая группа{' '}
          {isAdminContacts && (
            <IconEdit
              size={'m'}
              view={'primary'}
              className={classes.iconEdit}
              onClick={() => setIsModalProductShow(true)}
            />
          )}
        </div>
        <Modal
          isOpen={isModalProductShow}
          className={classes.contactsModal}
          hasOverlay
          onClickOutside={() => setIsModalProductShow(false)}
          onEsc={() => setIsModalProductShow(false)}
        >
          <ContactsForm
            contacts={productGroup}
            close={() => setIsModalProductShow(false)}
            afterDispatch={() => {
              dispatch(getContactsPGByContentBlockSlug(pg));
            }}
          />
        </Modal>
        <div className={classes.groupMembersContainer}>
          {productGroup &&
            productGroup.contacts &&
            productGroup.contacts.length > 0 &&
            productGroup.contacts.map((user: any, ind) => (
              <PersonalInfoCard
                key={ind}
                info={{
                  avatarURL: user.person.icon,
                  name: user.person?.last_name + ' ' + user.person?.first_name + '' + user.person?.middle_name,
                  position: user.position,
                  email: user.person.email,
                }}
                teamCard
                collapsed={
                  (!collapsed && ind < 4) ||
                  collapsed ||
                  (productGroup && productGroup.contacts && productGroup.contacts.length < 5)
                }
              />
            ))}

          {productGroup?.contacts && productGroup?.contacts.length > 4 && (
            <Button
              size="m"
              view="ghost"
              label={collapsed ? 'Скрыть' : 'Показать еще'}
              form="default"
              className={classes.button}
              onClick={() => setCollapse(!collapsed)}
            />
          )}
        </div>
      </div>
      <div className={classes.teamGroupContainer}>
        <div className={classes.groupTitle}>
          Рабочая группа{' '}
          {isAdminContacts && (
            <IconEdit
              size={'m'}
              view={'primary'}
              className={classes.iconEdit}
              onClick={() => setIsModalWorkShow(true)}
            />
          )}
        </div>
        <Modal
          isOpen={isModalWorkShow}
          className={classes.contactsModal}
          hasOverlay
          onClickOutside={() => setIsModalWorkShow(false)}
          onEsc={() => setIsModalWorkShow(false)}
        >
          <ContactsForm
            contacts={workGroup}
            close={() => setIsModalWorkShow(false)}
            afterDispatch={() => {
              dispatch(getContactsWGByContentBlockSlug(wg));
            }}
          />
        </Modal>
        <div className={classes.groupMembersContainer}>
          {workGroup &&
            workGroup.contacts &&
            workGroup.contacts.length > 0 &&
            workGroup.contacts.map((user: any, ind) => (
              <PersonalInfoCard
                key={ind}
                info={{
                  avatarURL: user.person.icon,
                  name: user.person?.last_name + ' ' + user.person?.first_name + '' + user.person?.middle_name,
                  position: user.position,
                  email: user.person.email,
                }}
                teamCard
                collapsed={
                  (!collapsed && ind < 4) ||
                  collapsed ||
                  (workGroup && workGroup.contacts && workGroup.contacts.length < 5)
                }
              />
            ))}

          {workGroup?.contacts && workGroup?.contacts.length > 4 && (
            <Button
              size="m"
              view="ghost"
              label={collapsed ? 'Скрыть' : 'Показать еще'}
              form="default"
              className={classes.button}
              onClick={() => setCollapse(!collapsed)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTeamList;
